import * as _app2 from "firebase/compat/app";

var _app = "default" in _app2 ? _app2.default : _app2;

import * as _auth2 from "firebase/compat/auth";

var _auth = "default" in _auth2 ? _auth2.default : _auth2;

import * as _dialogPolyfill2 from "dialog-polyfill";

var _dialogPolyfill = "default" in _dialogPolyfill2 ? _dialogPolyfill2.default : _dialogPolyfill2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

(function () {
  var firebase = _app;
  _auth;

  if (typeof firebase.default !== "undefined") {
    firebase = firebase.default;
  }

  (function () {
    var supportCustomEvent = window.CustomEvent;

    if (!supportCustomEvent || typeof supportCustomEvent === "object") {
      supportCustomEvent = function CustomEvent(event, x) {
        x = x || {};
        var ev = document.createEvent("CustomEvent");
        ev.initCustomEvent(event, !!x.bubbles, !!x.cancelable, x.detail || null);
        return ev;
      };

      supportCustomEvent.prototype = window.Event.prototype;
    }

    function createsStackingContext(el) {
      while (el && el !== document.body) {
        var s = window.getComputedStyle(el);

        var invalid = function (k, ok) {
          return !(s[k] === undefined || s[k] === ok);
        };

        if (s.opacity < 1 || invalid("zIndex", "auto") || invalid("transform", "none") || invalid("mixBlendMode", "normal") || invalid("filter", "none") || invalid("perspective", "none") || s["isolation"] === "isolate" || s.position === "fixed" || s.webkitOverflowScrolling === "touch") return true;
        el = el.parentElement;
      }

      return false;
    }

    function findNearestDialog(el) {
      while (el) {
        if (el.localName === "dialog") return el;
        el = el.parentElement;
      }

      return null;
    }

    function safeBlur(el) {
      if (el && el.blur && el !== document.body) el.blur();
    }

    function inNodeList(nodeList, node) {
      for (var i = 0; i < nodeList.length; ++i) if (nodeList[i] === node) return true;

      return false;
    }

    function isFormMethodDialog(el) {
      if (!el || !el.hasAttribute("method")) return false;
      return el.getAttribute("method").toLowerCase() === "dialog";
    }

    function dialogPolyfillInfo(dialog) {
      (this || _global).dialog_ = dialog;
      (this || _global).replacedStyleTop_ = false;
      (this || _global).openAsModal_ = false;
      if (!dialog.hasAttribute("role")) dialog.setAttribute("role", "dialog");
      dialog.show = (this || _global).show.bind(this || _global);
      dialog.showModal = (this || _global).showModal.bind(this || _global);
      dialog.close = (this || _global).close.bind(this || _global);
      if (!("returnValue" in dialog)) dialog.returnValue = "";

      if ("MutationObserver" in window) {
        var mo = new MutationObserver((this || _global).maybeHideModal.bind(this || _global));
        mo.observe(dialog, {
          attributes: true,
          attributeFilter: ["open"]
        });
      } else {
        var removed = false;

        var cb = function () {
          removed ? this.downgradeModal() : this.maybeHideModal();
          removed = false;
        }.bind(this || _global);

        var timeout;

        var delayModel = function (ev) {
          if (ev.target !== dialog) return;
          var cand = "DOMNodeRemoved";
          removed |= ev.type.substr(0, cand.length) === cand;
          window.clearTimeout(timeout);
          timeout = window.setTimeout(cb, 0);
        };

        ["DOMAttrModified", "DOMNodeRemoved", "DOMNodeRemovedFromDocument"].forEach(function (name) {
          dialog.addEventListener(name, delayModel);
        });
      }

      Object.defineProperty(dialog, "open", {
        set: (this || _global).setOpen.bind(this || _global),
        get: dialog.hasAttribute.bind(dialog, "open")
      });
      (this || _global).backdrop_ = document.createElement("div");
      (this || _global).backdrop_.className = "backdrop";

      (this || _global).backdrop_.addEventListener("click", (this || _global).backdropClick_.bind(this || _global));
    }

    dialogPolyfillInfo.prototype = {
      get dialog() {
        return (this || _global).dialog_;
      },

      maybeHideModal: function () {
        if ((this || _global).dialog_.hasAttribute("open") && document.body.contains((this || _global).dialog_)) return;
        this.downgradeModal();
      },
      downgradeModal: function () {
        if (!(this || _global).openAsModal_) return;
        (this || _global).openAsModal_ = false;
        (this || _global).dialog_.style.zIndex = "";

        if ((this || _global).replacedStyleTop_) {
          (this || _global).dialog_.style.top = "";
          (this || _global).replacedStyleTop_ = false;
        }

        (this || _global).backdrop_.parentNode && (this || _global).backdrop_.parentNode.removeChild((this || _global).backdrop_);
        dialogPolyfill.dm.removeDialog(this || _global);
      },
      setOpen: function (value) {
        if (value) (this || _global).dialog_.hasAttribute("open") || (this || _global).dialog_.setAttribute("open", "");else {
          (this || _global).dialog_.removeAttribute("open");

          this.maybeHideModal();
        }
      },
      backdropClick_: function (e) {
        if (!(this || _global).dialog_.hasAttribute("tabindex")) {
          var fake = document.createElement("div");

          (this || _global).dialog_.insertBefore(fake, (this || _global).dialog_.firstChild);

          fake.tabIndex = -1;
          fake.focus();

          (this || _global).dialog_.removeChild(fake);
        } else (this || _global).dialog_.focus();

        var redirectedEvent = document.createEvent("MouseEvents");
        redirectedEvent.initMouseEvent(e.type, e.bubbles, e.cancelable, window, e.detail, e.screenX, e.screenY, e.clientX, e.clientY, e.ctrlKey, e.altKey, e.shiftKey, e.metaKey, e.button, e.relatedTarget);

        (this || _global).dialog_.dispatchEvent(redirectedEvent);

        e.stopPropagation();
      },
      focus_: function () {
        var target = (this || _global).dialog_.querySelector("[autofocus]:not([disabled])");

        if (!target && (this || _global).dialog_.tabIndex >= 0) target = (this || _global).dialog_;

        if (!target) {
          var opts = ["button", "input", "keygen", "select", "textarea"];
          var query = opts.map(function (el) {
            return el + ":not([disabled])";
          });
          query.push("[tabindex]:not([disabled]):not([tabindex=\"\"])");
          target = (this || _global).dialog_.querySelector(query.join(", "));
        }

        safeBlur(document.activeElement);
        target && target.focus();
      },
      updateZIndex: function (dialogZ, backdropZ) {
        if (dialogZ < backdropZ) throw new Error("dialogZ should never be < backdropZ");
        (this || _global).dialog_.style.zIndex = dialogZ;
        (this || _global).backdrop_.style.zIndex = backdropZ;
      },
      show: function () {
        if (!(this || _global).dialog_.open) {
          this.setOpen(true);
          this.focus_();
        }
      },
      showModal: function () {
        if ((this || _global).dialog_.hasAttribute("open")) throw new Error("Failed to execute 'showModal' on dialog: The element is already open, and therefore cannot be opened modally.");
        if (!document.body.contains((this || _global).dialog_)) throw new Error("Failed to execute 'showModal' on dialog: The element is not in a Document.");
        if (!dialogPolyfill.dm.pushDialog(this || _global)) throw new Error("Failed to execute 'showModal' on dialog: There are too many open modal dialogs.");
        if (createsStackingContext((this || _global).dialog_.parentElement)) console.warn("A dialog is being shown inside a stacking context. " + "This may cause it to be unusable. For more information, see this link: " + "https://github.com/GoogleChrome/dialog-polyfill/#stacking-context");
        this.setOpen(true);
        (this || _global).openAsModal_ = true;

        if (dialogPolyfill.needsCentering((this || _global).dialog_)) {
          dialogPolyfill.reposition((this || _global).dialog_);
          (this || _global).replacedStyleTop_ = true;
        } else (this || _global).replacedStyleTop_ = false;

        (this || _global).dialog_.parentNode.insertBefore((this || _global).backdrop_, (this || _global).dialog_.nextSibling);

        this.focus_();
      },
      close: function (opt_returnValue) {
        if (!(this || _global).dialog_.hasAttribute("open")) throw new Error("Failed to execute 'close' on dialog: The element does not have an 'open' attribute, and therefore cannot be closed.");
        this.setOpen(false);
        if (opt_returnValue !== undefined) (this || _global).dialog_.returnValue = opt_returnValue;
        var closeEvent = new supportCustomEvent("close", {
          bubbles: false,
          cancelable: false
        });

        (this || _global).dialog_.dispatchEvent(closeEvent);
      }
    };
    var dialogPolyfill = {};

    dialogPolyfill.reposition = function (element) {
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      var topValue = scrollTop + (window.innerHeight - element.offsetHeight) / 2;
      element.style.top = Math.max(scrollTop, topValue) + "px";
    };

    dialogPolyfill.isInlinePositionSetByStylesheet = function (element) {
      for (var i = 0; i < document.styleSheets.length; ++i) {
        var styleSheet = document.styleSheets[i];
        var cssRules = null;

        try {
          cssRules = styleSheet.cssRules;
        } catch (e) {}

        if (!cssRules) continue;

        for (var j = 0; j < cssRules.length; ++j) {
          var rule = cssRules[j];
          var selectedNodes = null;

          try {
            selectedNodes = document.querySelectorAll(rule.selectorText);
          } catch (e$0) {}

          if (!selectedNodes || !inNodeList(selectedNodes, element)) continue;
          var cssTop = rule.style.getPropertyValue("top");
          var cssBottom = rule.style.getPropertyValue("bottom");
          if (cssTop && cssTop !== "auto" || cssBottom && cssBottom !== "auto") return true;
        }
      }

      return false;
    };

    dialogPolyfill.needsCentering = function (dialog) {
      var computedStyle = window.getComputedStyle(dialog);
      if (computedStyle.position !== "absolute") return false;
      if (dialog.style.top !== "auto" && dialog.style.top !== "" || dialog.style.bottom !== "auto" && dialog.style.bottom !== "") return false;
      return !dialogPolyfill.isInlinePositionSetByStylesheet(dialog);
    };

    dialogPolyfill.forceRegisterDialog = function (element) {
      if (window.HTMLDialogElement || element.showModal) console.warn("This browser already supports <dialog>, the polyfill " + "may not work correctly", element);
      if (element.localName !== "dialog") throw new Error("Failed to register dialog: The element is not a dialog.");
      new dialogPolyfillInfo(element);
    };

    dialogPolyfill.registerDialog = function (element) {
      if (!element.showModal) dialogPolyfill.forceRegisterDialog(element);
    };

    dialogPolyfill.DialogManager = function () {
      (this || _global).pendingDialogStack = [];

      var checkDOM = (this || _global).checkDOM_.bind(this || _global);

      (this || _global).overlay = document.createElement("div");
      (this || _global).overlay.className = "_dialog_overlay";

      (this || _global).overlay.addEventListener("click", function (e) {
        (this || _global).forwardTab_ = undefined;
        e.stopPropagation();
        checkDOM([]);
      }.bind(this || _global));

      (this || _global).handleKey_ = (this || _global).handleKey_.bind(this || _global);
      (this || _global).handleFocus_ = (this || _global).handleFocus_.bind(this || _global);
      (this || _global).zIndexLow_ = 100000;
      (this || _global).zIndexHigh_ = 100000 + 150;
      (this || _global).forwardTab_ = undefined;
      if ("MutationObserver" in window) (this || _global).mo_ = new MutationObserver(function (records) {
        var removed = [];
        records.forEach(function (rec) {
          for (var i = 0, c; c = rec.removedNodes[i]; ++i) {
            if (!(c instanceof Element)) continue;else if (c.localName === "dialog") removed.push(c);
            removed = removed.concat(c.querySelectorAll("dialog"));
          }
        });
        removed.length && checkDOM(removed);
      });
    };

    dialogPolyfill.DialogManager.prototype.blockDocument = function () {
      document.documentElement.addEventListener("focus", (this || _global).handleFocus_, true);
      document.addEventListener("keydown", (this || _global).handleKey_);
      (this || _global).mo_ && (this || _global).mo_.observe(document, {
        childList: true,
        subtree: true
      });
    };

    dialogPolyfill.DialogManager.prototype.unblockDocument = function () {
      document.documentElement.removeEventListener("focus", (this || _global).handleFocus_, true);
      document.removeEventListener("keydown", (this || _global).handleKey_);
      (this || _global).mo_ && (this || _global).mo_.disconnect();
    };

    dialogPolyfill.DialogManager.prototype.updateStacking = function () {
      var zIndex = (this || _global).zIndexHigh_;

      for (var i = 0, dpi; dpi = (this || _global).pendingDialogStack[i]; ++i) {
        dpi.updateZIndex(--zIndex, --zIndex);
        if (i === 0) (this || _global).overlay.style.zIndex = --zIndex;
      }

      var last = (this || _global).pendingDialogStack[0];

      if (last) {
        var p = last.dialog.parentNode || document.body;
        p.appendChild((this || _global).overlay);
      } else if ((this || _global).overlay.parentNode) (this || _global).overlay.parentNode.removeChild((this || _global).overlay);
    };

    dialogPolyfill.DialogManager.prototype.containedByTopDialog_ = function (candidate) {
      while (candidate = findNearestDialog(candidate)) {
        for (var i = 0, dpi; dpi = (this || _global).pendingDialogStack[i]; ++i) if (dpi.dialog === candidate) return i === 0;

        candidate = candidate.parentElement;
      }

      return false;
    };

    dialogPolyfill.DialogManager.prototype.handleFocus_ = function (event) {
      if (this.containedByTopDialog_(event.target)) return;
      event.preventDefault();
      event.stopPropagation();
      safeBlur(event.target);
      if ((this || _global).forwardTab_ === undefined) return;
      var dpi = (this || _global).pendingDialogStack[0];
      var dialog = dpi.dialog;
      var position = dialog.compareDocumentPosition(event.target);
      if (position & Node.DOCUMENT_POSITION_PRECEDING) {
        if ((this || _global).forwardTab_) dpi.focus_();else document.documentElement.focus();
      } else ;
      return false;
    };

    dialogPolyfill.DialogManager.prototype.handleKey_ = function (event) {
      (this || _global).forwardTab_ = undefined;

      if (event.keyCode === 27) {
        event.preventDefault();
        event.stopPropagation();
        var cancelEvent = new supportCustomEvent("cancel", {
          bubbles: false,
          cancelable: true
        });
        var dpi = (this || _global).pendingDialogStack[0];
        if (dpi && dpi.dialog.dispatchEvent(cancelEvent)) dpi.dialog.close();
      } else if (event.keyCode === 9) (this || _global).forwardTab_ = !event.shiftKey;
    };

    dialogPolyfill.DialogManager.prototype.checkDOM_ = function (removed) {
      var clone = (this || _global).pendingDialogStack.slice();

      clone.forEach(function (dpi) {
        if (removed.indexOf(dpi.dialog) !== -1) dpi.downgradeModal();else dpi.maybeHideModal();
      });
    };

    dialogPolyfill.DialogManager.prototype.pushDialog = function (dpi) {
      var allowed = ((this || _global).zIndexHigh_ - (this || _global).zIndexLow_) / 2 - 1;
      if ((this || _global).pendingDialogStack.length >= allowed) return false;
      if ((this || _global).pendingDialogStack.unshift(dpi) === 1) this.blockDocument();
      this.updateStacking();
      return true;
    };

    dialogPolyfill.DialogManager.prototype.removeDialog = function (dpi) {
      var index = (this || _global).pendingDialogStack.indexOf(dpi);

      if (index === -1) return;

      (this || _global).pendingDialogStack.splice(index, 1);

      if ((this || _global).pendingDialogStack.length === 0) this.unblockDocument();
      this.updateStacking();
    };

    dialogPolyfill.dm = new dialogPolyfill.DialogManager();
    dialogPolyfill.formSubmitter = null;
    dialogPolyfill.useValue = null;

    if (window.HTMLDialogElement === undefined) {
      var testForm = document.createElement("form");
      testForm.setAttribute("method", "dialog");

      if (testForm.method !== "dialog") {
        var methodDescriptor = Object.getOwnPropertyDescriptor(HTMLFormElement.prototype, "method");

        if (methodDescriptor) {
          var realGet = methodDescriptor.get;

          methodDescriptor.get = function () {
            if (isFormMethodDialog(this || _global)) return "dialog";
            return realGet.call(this || _global);
          };

          var realSet = methodDescriptor.set;

          methodDescriptor.set = function (v) {
            if (typeof v === "string" && v.toLowerCase() === "dialog") return this.setAttribute("method", v);
            return realSet.call(this || _global, v);
          };

          Object.defineProperty(HTMLFormElement.prototype, "method", methodDescriptor);
        }
      }

      document.addEventListener("click", function (ev) {
        dialogPolyfill.formSubmitter = null;
        dialogPolyfill.useValue = null;
        if (ev.defaultPrevented) return;
        var target = ev.target;
        if (!target || !isFormMethodDialog(target.form)) return;
        var valid = target.type === "submit" && ["button", "input"].indexOf(target.localName) > -1;

        if (!valid) {
          if (!(target.localName === "input" && target.type === "image")) return;
          dialogPolyfill.useValue = ev.offsetX + "," + ev.offsetY;
        }

        var dialog = findNearestDialog(target);
        if (!dialog) return;
        dialogPolyfill.formSubmitter = target;
      }, false);
      var nativeFormSubmit = HTMLFormElement.prototype.submit;

      var replacementFormSubmit = function () {
        if (!isFormMethodDialog(this || _global)) return nativeFormSubmit.call(this || _global);
        var dialog = findNearestDialog(this || _global);
        dialog && dialog.close();
      };

      HTMLFormElement.prototype.submit = replacementFormSubmit;
      document.addEventListener("submit", function (ev) {
        var form = ev.target;
        if (!isFormMethodDialog(form)) return;
        ev.preventDefault();
        var dialog = findNearestDialog(form);
        if (!dialog) return;
        var s = dialogPolyfill.formSubmitter;
        if (s && s.form === form) dialog.close(dialogPolyfill.useValue || s.value);else dialog.close();
        dialogPolyfill.formSubmitter = null;
      }, true);
    }

    dialogPolyfill["forceRegisterDialog"] = dialogPolyfill.forceRegisterDialog;
    dialogPolyfill["registerDialog"] = dialogPolyfill.registerDialog;
    exports = dialogPolyfill;
  })();
  /*
  Copyright 2015 Google Inc. All Rights Reserved.
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at
  http://www.apache.org/licenses/LICENSE-2.0
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
  */


  var componentHandler = {
    upgradeDom: function (optJsClass, optCssClass) {},
    upgradeElement: function (element, optJsClass) {},
    upgradeElements: function (elements) {},
    upgradeAllRegistered: function () {},
    registerUpgradedCallback: function (jsClass, callback) {},
    register: function (config) {},
    downgradeElements: function (nodes) {}
  };

  componentHandler = function () {
    var registeredComponents_ = [];
    var createdComponents_ = [];
    var componentConfigProperty_ = "mdlComponentConfigInternal_";

    function findRegisteredClass_(name, optReplace) {
      for (var i = 0; i < registeredComponents_.length; i++) if (registeredComponents_[i].className === name) {
        if (typeof optReplace !== "undefined") registeredComponents_[i] = optReplace;
        return registeredComponents_[i];
      }

      return false;
    }

    function getUpgradedListOfElement_(element) {
      var dataUpgraded = element.getAttribute("data-upgraded");
      return dataUpgraded === null ? [""] : dataUpgraded.split(",");
    }

    function isElementUpgraded_(element, jsClass) {
      var upgradedList = getUpgradedListOfElement_(element);
      return upgradedList.indexOf(jsClass) !== -1;
    }

    function createEvent_(eventType, bubbles, cancelable) {
      if ("CustomEvent" in window && typeof window.CustomEvent === "function") return new CustomEvent(eventType, {
        bubbles: bubbles,
        cancelable: cancelable
      });else {
        var ev = document.createEvent("Events");
        ev.initEvent(eventType, bubbles, cancelable);
        return ev;
      }
    }

    function upgradeDomInternal(optJsClass, optCssClass) {
      if (typeof optJsClass === "undefined" && typeof optCssClass === "undefined") for (var i = 0; i < registeredComponents_.length; i++) upgradeDomInternal(registeredComponents_[i].className, registeredComponents_[i].cssClass);else {
        var jsClass = optJsClass;

        if (typeof optCssClass === "undefined") {
          var registeredClass = findRegisteredClass_(jsClass);
          if (registeredClass) optCssClass = registeredClass.cssClass;
        }

        var elements = document.querySelectorAll("." + optCssClass);

        for (var n = 0; n < elements.length; n++) upgradeElementInternal(elements[n], jsClass);
      }
    }

    function upgradeElementInternal(element, optJsClass) {
      if (!(typeof element === "object" && element instanceof Element)) throw new Error("Invalid argument provided to upgrade MDL element.");
      var upgradingEv = createEvent_("mdl-componentupgrading", true, true);
      element.dispatchEvent(upgradingEv);
      if (upgradingEv.defaultPrevented) return;
      var upgradedList = getUpgradedListOfElement_(element);
      var classesToUpgrade = [];

      if (!optJsClass) {
        var classList = element.classList;
        registeredComponents_.forEach(function (component) {
          if (classList.contains(component.cssClass) && classesToUpgrade.indexOf(component) === -1 && !isElementUpgraded_(element, component.className)) classesToUpgrade.push(component);
        });
      } else if (!isElementUpgraded_(element, optJsClass)) classesToUpgrade.push(findRegisteredClass_(optJsClass));

      for (var i = 0, n = classesToUpgrade.length, registeredClass; i < n; i++) {
        registeredClass = classesToUpgrade[i];

        if (registeredClass) {
          upgradedList.push(registeredClass.className);
          element.setAttribute("data-upgraded", upgradedList.join(","));
          var instance = new registeredClass.classConstructor(element);
          instance[componentConfigProperty_] = registeredClass;
          createdComponents_.push(instance);

          for (var j = 0, m = registeredClass.callbacks.length; j < m; j++) registeredClass.callbacks[j](element);

          if (registeredClass.widget) element[registeredClass.className] = instance;
        } else throw new Error("Unable to find a registered component for the given class.");

        var upgradedEv = createEvent_("mdl-componentupgraded", true, false);
        element.dispatchEvent(upgradedEv);
      }
    }

    function upgradeElementsInternal(elements) {
      if (!Array.isArray(elements)) if (elements instanceof Element) elements = [elements];else elements = Array.prototype.slice.call(elements);

      for (var i = 0, n = elements.length, element; i < n; i++) {
        element = elements[i];

        if (element instanceof HTMLElement) {
          upgradeElementInternal(element);
          if (element.children.length > 0) upgradeElementsInternal(element.children);
        }
      }
    }

    function registerInternal(config) {
      var widgetMissing = typeof config.widget === "undefined" && typeof config["widget"] === "undefined";
      var widget = true;
      if (!widgetMissing) widget = config.widget || config["widget"];
      var newConfig = {
        classConstructor: config.constructor || config["constructor"],
        className: config.classAsString || config["classAsString"],
        cssClass: config.cssClass || config["cssClass"],
        widget: widget,
        callbacks: []
      };
      registeredComponents_.forEach(function (item) {
        if (item.cssClass === newConfig.cssClass) throw new Error("The provided cssClass has already been registered: " + item.cssClass);
        if (item.className === newConfig.className) throw new Error("The provided className has already been registered");
      });
      if (config.constructor.prototype.hasOwnProperty(componentConfigProperty_)) throw new Error("MDL component classes must not have " + componentConfigProperty_ + " defined as a property.");
      var found = findRegisteredClass_(config.classAsString, newConfig);
      if (!found) registeredComponents_.push(newConfig);
    }

    function registerUpgradedCallbackInternal(jsClass, callback) {
      var regClass = findRegisteredClass_(jsClass);
      if (regClass) regClass.callbacks.push(callback);
    }

    function upgradeAllRegisteredInternal() {
      for (var n = 0; n < registeredComponents_.length; n++) upgradeDomInternal(registeredComponents_[n].className);
    }

    function deconstructComponentInternal(component) {
      if (component) {
        var componentIndex = createdComponents_.indexOf(component);
        createdComponents_.splice(componentIndex, 1);
        var upgrades = component.element_.getAttribute("data-upgraded").split(",");
        var componentPlace = upgrades.indexOf(component[componentConfigProperty_].classAsString);
        upgrades.splice(componentPlace, 1);
        component.element_.setAttribute("data-upgraded", upgrades.join(","));
        var ev = createEvent_("mdl-componentdowngraded", true, false);
        component.element_.dispatchEvent(ev);
      }
    }

    function downgradeNodesInternal(nodes) {
      var downgradeNode = function (node) {
        createdComponents_.filter(function (item) {
          return item.element_ === node;
        }).forEach(deconstructComponentInternal);
      };

      if (nodes instanceof Array || nodes instanceof NodeList) for (var n = 0; n < nodes.length; n++) downgradeNode(nodes[n]);else if (nodes instanceof Node) downgradeNode(nodes);else throw new Error("Invalid argument provided to downgrade MDL nodes.");
    }

    return {
      upgradeDom: upgradeDomInternal,
      upgradeElement: upgradeElementInternal,
      upgradeElements: upgradeElementsInternal,
      upgradeAllRegistered: upgradeAllRegisteredInternal,
      registerUpgradedCallback: registerUpgradedCallbackInternal,
      register: registerInternal,
      downgradeElements: downgradeNodesInternal
    };
  }();

  componentHandler.ComponentConfigPublic;
  componentHandler.ComponentConfig;
  componentHandler.Component;
  componentHandler["upgradeDom"] = componentHandler.upgradeDom;
  componentHandler["upgradeElement"] = componentHandler.upgradeElement;
  componentHandler["upgradeElements"] = componentHandler.upgradeElements;
  componentHandler["upgradeAllRegistered"] = componentHandler.upgradeAllRegistered;
  componentHandler["registerUpgradedCallback"] = componentHandler.registerUpgradedCallback;
  componentHandler["register"] = componentHandler.register;
  componentHandler["downgradeElements"] = componentHandler.downgradeElements;
  window.componentHandler = componentHandler;
  window["componentHandler"] = componentHandler;
  window.addEventListener("load", function () {
    if ("classList" in document.createElement("div") && "querySelector" in document && "addEventListener" in window && Array.prototype.forEach) {
      document.documentElement.classList.add("mdl-js");
      componentHandler.upgradeAllRegistered();
    } else {
      componentHandler.upgradeElement = function () {};

      componentHandler.register = function () {};
    }
  });

  (function () {
    var MaterialButton = function MaterialButton(element) {
      (this || _global).element_ = element;
      this.init();
    };

    window["MaterialButton"] = MaterialButton;
    MaterialButton.prototype.Constant_ = {};
    MaterialButton.prototype.CssClasses_ = {
      RIPPLE_EFFECT: "mdl-js-ripple-effect",
      RIPPLE_CONTAINER: "mdl-button__ripple-container",
      RIPPLE: "mdl-ripple"
    };

    MaterialButton.prototype.blurHandler_ = function (event) {
      if (event) (this || _global).element_.blur();
    };

    MaterialButton.prototype.disable = function () {
      (this || _global).element_.disabled = true;
    };

    MaterialButton.prototype["disable"] = MaterialButton.prototype.disable;

    MaterialButton.prototype.enable = function () {
      (this || _global).element_.disabled = false;
    };

    MaterialButton.prototype["enable"] = MaterialButton.prototype.enable;

    MaterialButton.prototype.init = function () {
      if ((this || _global).element_) {
        if ((this || _global).element_.classList.contains((this || _global).CssClasses_.RIPPLE_EFFECT)) {
          var rippleContainer = document.createElement("span");
          rippleContainer.classList.add((this || _global).CssClasses_.RIPPLE_CONTAINER);
          (this || _global).rippleElement_ = document.createElement("span");

          (this || _global).rippleElement_.classList.add((this || _global).CssClasses_.RIPPLE);

          rippleContainer.appendChild((this || _global).rippleElement_);
          (this || _global).boundRippleBlurHandler = (this || _global).blurHandler_.bind(this || _global);

          (this || _global).rippleElement_.addEventListener("mouseup", (this || _global).boundRippleBlurHandler);

          (this || _global).element_.appendChild(rippleContainer);
        }

        (this || _global).boundButtonBlurHandler = (this || _global).blurHandler_.bind(this || _global);

        (this || _global).element_.addEventListener("mouseup", (this || _global).boundButtonBlurHandler);

        (this || _global).element_.addEventListener("mouseleave", (this || _global).boundButtonBlurHandler);
      }
    };

    componentHandler.register({
      constructor: MaterialButton,
      classAsString: "MaterialButton",
      cssClass: "mdl-js-button",
      widget: true
    });
  })();

  (function () {
    var MaterialProgress = function MaterialProgress(element) {
      (this || _global).element_ = element;
      this.init();
    };

    window["MaterialProgress"] = MaterialProgress;
    MaterialProgress.prototype.Constant_ = {};
    MaterialProgress.prototype.CssClasses_ = {
      INDETERMINATE_CLASS: "mdl-progress__indeterminate"
    };

    MaterialProgress.prototype.setProgress = function (p) {
      if ((this || _global).element_.classList.contains((this || _global).CssClasses_.INDETERMINATE_CLASS)) return;
      (this || _global).progressbar_.style.width = p + "%";
    };

    MaterialProgress.prototype["setProgress"] = MaterialProgress.prototype.setProgress;

    MaterialProgress.prototype.setBuffer = function (p) {
      (this || _global).bufferbar_.style.width = p + "%";
      (this || _global).auxbar_.style.width = 100 - p + "%";
    };

    MaterialProgress.prototype["setBuffer"] = MaterialProgress.prototype.setBuffer;

    MaterialProgress.prototype.init = function () {
      if ((this || _global).element_) {
        var el = document.createElement("div");
        el.className = "progressbar bar bar1";

        (this || _global).element_.appendChild(el);

        (this || _global).progressbar_ = el;
        el = document.createElement("div");
        el.className = "bufferbar bar bar2";

        (this || _global).element_.appendChild(el);

        (this || _global).bufferbar_ = el;
        el = document.createElement("div");
        el.className = "auxbar bar bar3";

        (this || _global).element_.appendChild(el);

        (this || _global).auxbar_ = el;
        (this || _global).progressbar_.style.width = "0%";
        (this || _global).bufferbar_.style.width = "100%";
        (this || _global).auxbar_.style.width = "0%";

        (this || _global).element_.classList.add("is-upgraded");
      }
    };

    componentHandler.register({
      constructor: MaterialProgress,
      classAsString: "MaterialProgress",
      cssClass: "mdl-js-progress",
      widget: true
    });
  })();

  (function () {
    var MaterialSpinner = function MaterialSpinner(element) {
      (this || _global).element_ = element;
      this.init();
    };

    window["MaterialSpinner"] = MaterialSpinner;
    MaterialSpinner.prototype.Constant_ = {
      MDL_SPINNER_LAYER_COUNT: 4
    };
    MaterialSpinner.prototype.CssClasses_ = {
      MDL_SPINNER_LAYER: "mdl-spinner__layer",
      MDL_SPINNER_CIRCLE_CLIPPER: "mdl-spinner__circle-clipper",
      MDL_SPINNER_CIRCLE: "mdl-spinner__circle",
      MDL_SPINNER_GAP_PATCH: "mdl-spinner__gap-patch",
      MDL_SPINNER_LEFT: "mdl-spinner__left",
      MDL_SPINNER_RIGHT: "mdl-spinner__right"
    };

    MaterialSpinner.prototype.createLayer = function (index) {
      var layer = document.createElement("div");
      layer.classList.add((this || _global).CssClasses_.MDL_SPINNER_LAYER);
      layer.classList.add((this || _global).CssClasses_.MDL_SPINNER_LAYER + "-" + index);
      var leftClipper = document.createElement("div");
      leftClipper.classList.add((this || _global).CssClasses_.MDL_SPINNER_CIRCLE_CLIPPER);
      leftClipper.classList.add((this || _global).CssClasses_.MDL_SPINNER_LEFT);
      var gapPatch = document.createElement("div");
      gapPatch.classList.add((this || _global).CssClasses_.MDL_SPINNER_GAP_PATCH);
      var rightClipper = document.createElement("div");
      rightClipper.classList.add((this || _global).CssClasses_.MDL_SPINNER_CIRCLE_CLIPPER);
      rightClipper.classList.add((this || _global).CssClasses_.MDL_SPINNER_RIGHT);
      var circleOwners = [leftClipper, gapPatch, rightClipper];

      for (var i = 0; i < circleOwners.length; i++) {
        var circle = document.createElement("div");
        circle.classList.add((this || _global).CssClasses_.MDL_SPINNER_CIRCLE);
        circleOwners[i].appendChild(circle);
      }

      layer.appendChild(leftClipper);
      layer.appendChild(gapPatch);
      layer.appendChild(rightClipper);

      (this || _global).element_.appendChild(layer);
    };

    MaterialSpinner.prototype["createLayer"] = MaterialSpinner.prototype.createLayer;

    MaterialSpinner.prototype.stop = function () {
      (this || _global).element_.classList.remove("is-active");
    };

    MaterialSpinner.prototype["stop"] = MaterialSpinner.prototype.stop;

    MaterialSpinner.prototype.start = function () {
      (this || _global).element_.classList.add("is-active");
    };

    MaterialSpinner.prototype["start"] = MaterialSpinner.prototype.start;

    MaterialSpinner.prototype.init = function () {
      if ((this || _global).element_) {
        for (var i = 1; i <= (this || _global).Constant_.MDL_SPINNER_LAYER_COUNT; i++) this.createLayer(i);

        (this || _global).element_.classList.add("is-upgraded");
      }
    };

    componentHandler.register({
      constructor: MaterialSpinner,
      classAsString: "MaterialSpinner",
      cssClass: "mdl-js-spinner",
      widget: true
    });
  })();

  (function () {
    var MaterialTextfield = function MaterialTextfield(element) {
      (this || _global).element_ = element;
      (this || _global).maxRows = (this || _global).Constant_.NO_MAX_ROWS;
      this.init();
    };

    window["MaterialTextfield"] = MaterialTextfield;
    MaterialTextfield.prototype.Constant_ = {
      NO_MAX_ROWS: -1,
      MAX_ROWS_ATTRIBUTE: "maxrows"
    };
    MaterialTextfield.prototype.CssClasses_ = {
      LABEL: "mdl-textfield__label",
      INPUT: "mdl-textfield__input",
      IS_DIRTY: "is-dirty",
      IS_FOCUSED: "is-focused",
      IS_DISABLED: "is-disabled",
      IS_INVALID: "is-invalid",
      IS_UPGRADED: "is-upgraded",
      HAS_PLACEHOLDER: "has-placeholder"
    };

    MaterialTextfield.prototype.onKeyDown_ = function (event) {
      var currentRowCount = event.target.value.split("\n").length;
      if (event.keyCode === 13) if (currentRowCount >= (this || _global).maxRows) event.preventDefault();
    };

    MaterialTextfield.prototype.onFocus_ = function (event) {
      (this || _global).element_.classList.add((this || _global).CssClasses_.IS_FOCUSED);
    };

    MaterialTextfield.prototype.onBlur_ = function (event) {
      (this || _global).element_.classList.remove((this || _global).CssClasses_.IS_FOCUSED);
    };

    MaterialTextfield.prototype.onReset_ = function (event) {
      this.updateClasses_();
    };

    MaterialTextfield.prototype.updateClasses_ = function () {
      this.checkDisabled();
      this.checkValidity();
      this.checkDirty();
      this.checkFocus();
    };

    MaterialTextfield.prototype.checkDisabled = function () {
      if ((this || _global).input_.disabled) (this || _global).element_.classList.add((this || _global).CssClasses_.IS_DISABLED);else (this || _global).element_.classList.remove((this || _global).CssClasses_.IS_DISABLED);
    };

    MaterialTextfield.prototype["checkDisabled"] = MaterialTextfield.prototype.checkDisabled;

    MaterialTextfield.prototype.checkFocus = function () {
      if (Boolean((this || _global).element_.querySelector(":focus"))) (this || _global).element_.classList.add((this || _global).CssClasses_.IS_FOCUSED);else (this || _global).element_.classList.remove((this || _global).CssClasses_.IS_FOCUSED);
    };

    MaterialTextfield.prototype["checkFocus"] = MaterialTextfield.prototype.checkFocus;

    MaterialTextfield.prototype.checkValidity = function () {
      if ((this || _global).input_.validity) if ((this || _global).input_.validity.valid) (this || _global).element_.classList.remove((this || _global).CssClasses_.IS_INVALID);else (this || _global).element_.classList.add((this || _global).CssClasses_.IS_INVALID);
    };

    MaterialTextfield.prototype["checkValidity"] = MaterialTextfield.prototype.checkValidity;

    MaterialTextfield.prototype.checkDirty = function () {
      if ((this || _global).input_.value && (this || _global).input_.value.length > 0) (this || _global).element_.classList.add((this || _global).CssClasses_.IS_DIRTY);else (this || _global).element_.classList.remove((this || _global).CssClasses_.IS_DIRTY);
    };

    MaterialTextfield.prototype["checkDirty"] = MaterialTextfield.prototype.checkDirty;

    MaterialTextfield.prototype.disable = function () {
      (this || _global).input_.disabled = true;
      this.updateClasses_();
    };

    MaterialTextfield.prototype["disable"] = MaterialTextfield.prototype.disable;

    MaterialTextfield.prototype.enable = function () {
      (this || _global).input_.disabled = false;
      this.updateClasses_();
    };

    MaterialTextfield.prototype["enable"] = MaterialTextfield.prototype.enable;

    MaterialTextfield.prototype.change = function (value) {
      (this || _global).input_.value = value || "";
      this.updateClasses_();
    };

    MaterialTextfield.prototype["change"] = MaterialTextfield.prototype.change;

    MaterialTextfield.prototype.init = function () {
      if ((this || _global).element_) {
        (this || _global).label_ = (this || _global).element_.querySelector("." + (this || _global).CssClasses_.LABEL);
        (this || _global).input_ = (this || _global).element_.querySelector("." + (this || _global).CssClasses_.INPUT);

        if ((this || _global).input_) {
          if ((this || _global).input_.hasAttribute((this || _global).Constant_.MAX_ROWS_ATTRIBUTE)) {
            (this || _global).maxRows = parseInt((this || _global).input_.getAttribute((this || _global).Constant_.MAX_ROWS_ATTRIBUTE), 10);
            if (isNaN((this || _global).maxRows)) (this || _global).maxRows = (this || _global).Constant_.NO_MAX_ROWS;
          }

          if ((this || _global).input_.hasAttribute("placeholder")) (this || _global).element_.classList.add((this || _global).CssClasses_.HAS_PLACEHOLDER);
          (this || _global).boundUpdateClassesHandler = (this || _global).updateClasses_.bind(this || _global);
          (this || _global).boundFocusHandler = (this || _global).onFocus_.bind(this || _global);
          (this || _global).boundBlurHandler = (this || _global).onBlur_.bind(this || _global);
          (this || _global).boundResetHandler = (this || _global).onReset_.bind(this || _global);

          (this || _global).input_.addEventListener("input", (this || _global).boundUpdateClassesHandler);

          (this || _global).input_.addEventListener("focus", (this || _global).boundFocusHandler);

          (this || _global).input_.addEventListener("blur", (this || _global).boundBlurHandler);

          (this || _global).input_.addEventListener("reset", (this || _global).boundResetHandler);

          if ((this || _global).maxRows !== (this || _global).Constant_.NO_MAX_ROWS) {
            (this || _global).boundKeyDownHandler = (this || _global).onKeyDown_.bind(this || _global);

            (this || _global).input_.addEventListener("keydown", (this || _global).boundKeyDownHandler);
          }

          var invalid = (this || _global).element_.classList.contains((this || _global).CssClasses_.IS_INVALID);

          this.updateClasses_();

          (this || _global).element_.classList.add((this || _global).CssClasses_.IS_UPGRADED);

          if (invalid) (this || _global).element_.classList.add((this || _global).CssClasses_.IS_INVALID);

          if ((this || _global).input_.hasAttribute("autofocus")) {
            (this || _global).element_.focus();

            this.checkFocus();
          }
        }
      }
    };

    componentHandler.register({
      constructor: MaterialTextfield,
      classAsString: "MaterialTextfield",
      cssClass: "mdl-js-textfield",
      widget: true
    });
  })();

  (function () {
    var l,
        aa = "function" == typeof Object.create ? Object.create : function (a) {
      function b() {}

      b.prototype = a;
      return new b();
    },
        ba;
    if ("function" == typeof Object.setPrototypeOf) ba = Object.setPrototypeOf;else {
      var ca;

      a: {
        var da = {
          xb: !0
        },
            ea = {};

        try {
          ea.__proto__ = da;
          ca = ea.xb;
          break a;
        } catch (a) {}

        ca = !1;
      }

      ba = ca ? function (a, b) {
        a.__proto__ = b;
        if (a.__proto__ !== b) throw new TypeError(a + " is not extensible");
        return a;
      } : null;
    }
    var fa = ba;

    function m(a, b) {
      a.prototype = aa(b.prototype);
      a.prototype.constructor = a;
      if (fa) fa(a, b);else for (var c in b) if ("prototype" != c) if (Object.defineProperties) {
        var d = Object.getOwnPropertyDescriptor(b, c);
        d && Object.defineProperty(a, c, d);
      } else a[c] = b[c];
      a.K = b.prototype;
    }

    var ha = "function" == typeof Object.defineProperties ? Object.defineProperty : function (a, b, c) {
      a != Array.prototype && a != Object.prototype && (a[b] = c.value);
    },
        ia = "undefined" != typeof window && window === (this || _global) ? this || _global : "undefined" != typeof _global && null != _global ? _global : this || _global;

    function ja(a, b) {
      if (b) {
        var c = ia;
        a = a.split(".");

        for (var d = 0; d < a.length - 1; d++) {
          var e = a[d];
          e in c || (c[e] = {});
          c = c[e];
        }

        a = a[a.length - 1];
        d = c[a];
        b = b(d);
        b != d && null != b && ha(c, a, {
          configurable: !0,
          writable: !0,
          value: b
        });
      }
    }

    ja("Object.is", function (a) {
      return a ? a : function (b, c) {
        return b === c ? 0 !== b || 1 / b === 1 / c : b !== b && c !== c;
      };
    });
    ja("Array.prototype.includes", function (a) {
      return a ? a : function (b, c) {
        var d = this || _global;
        d instanceof String && (d = String(d));
        var e = d.length;
        c = c || 0;

        for (0 > c && (c = Math.max(c + e, 0)); c < e; c++) {
          var f = d[c];
          if (f === b || Object.is(f, b)) return !0;
        }

        return !1;
      };
    });
    var n = this || _global;

    function ka(a) {
      return void 0 !== a;
    }

    function q(a) {
      return "string" == typeof a;
    }

    var la = /^[\w+/_-]+[=]{0,2}$/,
        ma = null;

    function na() {}

    function oa(a) {
      a.W = void 0;

      a.Xa = function () {
        return a.W ? a.W : a.W = new a();
      };
    }

    function pa(a) {
      var b = typeof a;
      if ("object" == b) {
        if (a) {
          if (a instanceof Array) return "array";
          if (a instanceof Object) return b;
          var c = Object.prototype.toString.call(a);
          if ("[object Window]" == c) return "object";
          if ("[object Array]" == c || "number" == typeof a.length && "undefined" != typeof a.splice && "undefined" != typeof a.propertyIsEnumerable && !a.propertyIsEnumerable("splice")) return "array";
          if ("[object Function]" == c || "undefined" != typeof a.call && "undefined" != typeof a.propertyIsEnumerable && !a.propertyIsEnumerable("call")) return "function";
        } else return "null";
      } else if ("function" == b && "undefined" == typeof a.call) return "object";
      return b;
    }

    function qa(a) {
      return "array" == pa(a);
    }

    function ra(a) {
      var b = pa(a);
      return "array" == b || "object" == b && "number" == typeof a.length;
    }

    function sa(a) {
      return "function" == pa(a);
    }

    function ta(a) {
      var b = typeof a;
      return "object" == b && null != a || "function" == b;
    }

    var ua = "closure_uid_" + (1000000000 * Math.random() >>> 0),
        va = 0;

    function wa(a, b, c) {
      return a.call.apply(a.bind, arguments);
    }

    function xa(a, b, c) {
      if (!a) throw Error();

      if (2 < arguments.length) {
        var d = Array.prototype.slice.call(arguments, 2);
        return function () {
          var e = Array.prototype.slice.call(arguments);
          Array.prototype.unshift.apply(e, d);
          return a.apply(b, e);
        };
      }

      return function () {
        return a.apply(b, arguments);
      };
    }

    function t(a, b, c) {
      Function.prototype.bind && -1 != Function.prototype.bind.toString().indexOf("native code") ? t = wa : t = xa;
      return t.apply(null, arguments);
    }

    function za(a, b) {
      var c = Array.prototype.slice.call(arguments, 1);
      return function () {
        var d = c.slice();
        d.push.apply(d, arguments);
        return a.apply(this || _global, d);
      };
    }

    function u(a, b) {
      for (var c in b) a[c] = b[c];
    }

    var Aa = Date.now || function () {
      return +new Date();
    };

    function v(a, b) {
      a = a.split(".");
      var c = n;
      a[0] in c || "undefined" == typeof c.execScript || c.execScript("var " + a[0]);

      for (var d; a.length && (d = a.shift());) !a.length && ka(b) ? c[d] = b : c[d] && c[d] !== Object.prototype[d] ? c = c[d] : c = c[d] = {};
    }

    function w(a, b) {
      function c() {}

      c.prototype = b.prototype;
      a.K = b.prototype;
      a.prototype = new c();
      a.prototype.constructor = a;

      a.vc = function (d, e, f) {
        for (var g = Array(arguments.length - 2), h = 2; h < arguments.length; h++) g[h - 2] = arguments[h];

        return b.prototype[e].apply(d, g);
      };
    }

    function Ba(a) {
      if (Error.captureStackTrace) Error.captureStackTrace(this || _global, Ba);else {
        var b = Error().stack;
        b && ((this || _global).stack = b);
      }
      a && ((this || _global).message = String(a));
    }

    w(Ba, Error);
    Ba.prototype.name = "CustomError";
    var Da;

    function Ea(a, b) {
      a = a.split("%s");

      for (var c = "", d = a.length - 1, e = 0; e < d; e++) c += a[e] + (e < b.length ? b[e] : "%s");

      Ba.call(this || _global, c + a[d]);
    }

    w(Ea, Ba);
    Ea.prototype.name = "AssertionError";

    function Fa(a, b) {
      throw new Ea("Failure" + (a ? ": " + a : ""), Array.prototype.slice.call(arguments, 1));
    }

    var Ga = Array.prototype.indexOf ? function (a, b) {
      return Array.prototype.indexOf.call(a, b, void 0);
    } : function (a, b) {
      if (q(a)) return q(b) && 1 == b.length ? a.indexOf(b, 0) : -1;

      for (var c = 0; c < a.length; c++) if (c in a && a[c] === b) return c;

      return -1;
    },
        Ha = Array.prototype.forEach ? function (a, b, c) {
      Array.prototype.forEach.call(a, b, c);
    } : function (a, b, c) {
      for (var d = a.length, e = q(a) ? a.split("") : a, f = 0; f < d; f++) f in e && b.call(c, e[f], f, a);
    };

    function Ia(a, b) {
      for (var c = q(a) ? a.split("") : a, d = a.length - 1; 0 <= d; --d) d in c && b.call(void 0, c[d], d, a);
    }

    var Ja = Array.prototype.filter ? function (a, b) {
      return Array.prototype.filter.call(a, b, void 0);
    } : function (a, b) {
      for (var c = a.length, d = [], e = 0, f = q(a) ? a.split("") : a, g = 0; g < c; g++) if (g in f) {
        var h = f[g];
        b.call(void 0, h, g, a) && (d[e++] = h);
      }

      return d;
    },
        Ka = Array.prototype.map ? function (a, b) {
      return Array.prototype.map.call(a, b, void 0);
    } : function (a, b) {
      for (var c = a.length, d = Array(c), e = q(a) ? a.split("") : a, f = 0; f < c; f++) f in e && (d[f] = b.call(void 0, e[f], f, a));

      return d;
    },
        La = Array.prototype.some ? function (a, b) {
      return Array.prototype.some.call(a, b, void 0);
    } : function (a, b) {
      for (var c = a.length, d = q(a) ? a.split("") : a, e = 0; e < c; e++) if (e in d && b.call(void 0, d[e], e, a)) return !0;

      return !1;
    };

    function Ma(a, b) {
      return 0 <= Ga(a, b);
    }

    function Na(a, b) {
      b = Ga(a, b);
      var c;
      (c = 0 <= b) && Oa(a, b);
      return c;
    }

    function Oa(a, b) {
      return 1 == Array.prototype.splice.call(a, b, 1).length;
    }

    function Pa(a, b) {
      a: {
        for (var c = a.length, d = q(a) ? a.split("") : a, e = 0; e < c; e++) if (e in d && b.call(void 0, d[e], e, a)) {
          b = e;
          break a;
        }

        b = -1;
      }

      0 <= b && Oa(a, b);
    }

    function Qa(a, b) {
      var c = 0;
      Ia(a, function (d, e) {
        b.call(void 0, d, e, a) && Oa(a, e) && c++;
      });
    }

    function Ra(a) {
      return Array.prototype.concat.apply([], arguments);
    }

    function Sa(a) {
      var b = a.length;

      if (0 < b) {
        for (var c = Array(b), d = 0; d < b; d++) c[d] = a[d];

        return c;
      }

      return [];
    }

    function Ta(a, b, c, d) {
      return Array.prototype.splice.apply(a, Ua(arguments, 1));
    }

    function Ua(a, b, c) {
      return 2 >= arguments.length ? Array.prototype.slice.call(a, b) : Array.prototype.slice.call(a, b, c);
    }

    var Va = String.prototype.trim ? function (a) {
      return a.trim();
    } : function (a) {
      return /^[\s\xa0]*([\s\S]*?)[\s\xa0]*$/.exec(a)[1];
    },
        Wa = /&/g,
        Xa = /</g,
        Ya = />/g,
        Za = /"/g,
        $a = /'/g,
        ab = /\x00/g,
        bb = /[\x00&<>"']/;

    function cb(a, b) {
      return a < b ? -1 : a > b ? 1 : 0;
    }

    function db(a) {
      bb.test(a) && (-1 != a.indexOf("&") && (a = a.replace(Wa, "&amp;")), -1 != a.indexOf("<") && (a = a.replace(Xa, "&lt;")), -1 != a.indexOf(">") && (a = a.replace(Ya, "&gt;")), -1 != a.indexOf("\"") && (a = a.replace(Za, "&quot;")), -1 != a.indexOf("'") && (a = a.replace($a, "&#39;")), -1 != a.indexOf("\0") && (a = a.replace(ab, "&#0;")));
      return a;
    }

    function eb(a, b, c) {
      for (var d in a) b.call(c, a[d], d, a);
    }

    function fb(a) {
      var b = {},
          c;

      for (c in a) b[c] = a[c];

      return b;
    }

    var gb = "constructor hasOwnProperty isPrototypeOf propertyIsEnumerable toLocaleString toString valueOf".split(" ");

    function hb(a, b) {
      for (var c, d, e = 1; e < arguments.length; e++) {
        d = arguments[e];

        for (c in d) a[c] = d[c];

        for (var f = 0; f < gb.length; f++) c = gb[f], Object.prototype.hasOwnProperty.call(d, c) && (a[c] = d[c]);
      }
    }

    var ib = "StopIteration" in n ? n.StopIteration : {
      message: "StopIteration",
      stack: ""
    };

    function jb() {}

    jb.prototype.next = function () {
      throw ib;
    };

    jb.prototype.ha = function () {
      return this || _global;
    };

    function kb(a) {
      if (a instanceof jb) return a;
      if ("function" == typeof a.ha) return a.ha(!1);

      if (ra(a)) {
        var b = 0,
            c = new jb();

        c.next = function () {
          for (;;) {
            if (b >= a.length) throw ib;
            if (b in a) return a[b++];
            b++;
          }
        };

        return c;
      }

      throw Error("Not implemented");
    }

    function lb(a, b) {
      if (ra(a)) try {
        Ha(a, b, void 0);
      } catch (c) {
        if (c !== ib) throw c;
      } else {
        a = kb(a);

        try {
          for (;;) b.call(void 0, a.next(), void 0, a);
        } catch (c$1) {
          if (c$1 !== ib) throw c$1;
        }
      }
    }

    function mb(a) {
      if (ra(a)) return Sa(a);
      a = kb(a);
      var b = [];
      lb(a, function (c) {
        b.push(c);
      });
      return b;
    }

    function nb(a, b) {
      (this || _global).g = {};
      (this || _global).a = [];
      (this || _global).j = (this || _global).h = 0;
      var c = arguments.length;

      if (1 < c) {
        if (c % 2) throw Error("Uneven number of arguments");

        for (var d = 0; d < c; d += 2) this.set(arguments[d], arguments[d + 1]);
      } else if (a) if (a instanceof nb) for (c = a.ja(), d = 0; d < c.length; d++) this.set(c[d], a.get(c[d]));else for (d in a) this.set(d, a[d]);
    }

    l = nb.prototype;

    l.la = function () {
      ob(this || _global);

      for (var a = [], b = 0; b < (this || _global).a.length; b++) a.push((this || _global).g[(this || _global).a[b]]);

      return a;
    };

    l.ja = function () {
      ob(this || _global);
      return (this || _global).a.concat();
    };

    l.clear = function () {
      (this || _global).g = {};
      (this || _global).j = (this || _global).h = (this || _global).a.length = 0;
    };

    function ob(a) {
      if (a.h != a.a.length) {
        for (var b = 0, c = 0; b < a.a.length;) {
          var d = a.a[b];
          pb(a.g, d) && (a.a[c++] = d);
          b++;
        }

        a.a.length = c;
      }

      if (a.h != a.a.length) {
        var e = {};

        for (c = b = 0; b < a.a.length;) d = a.a[b], pb(e, d) || (a.a[c++] = d, e[d] = 1), b++;

        a.a.length = c;
      }
    }

    l.get = function (a, b) {
      return pb((this || _global).g, a) ? (this || _global).g[a] : b;
    };

    l.set = function (a, b) {
      pb((this || _global).g, a) || ((this || _global).h++, (this || _global).a.push(a), (this || _global).j++);
      (this || _global).g[a] = b;
    };

    l.forEach = function (a, b) {
      for (var c = this.ja(), d = 0; d < c.length; d++) {
        var e = c[d],
            f = this.get(e);
        a.call(b, f, e, this || _global);
      }
    };

    l.ha = function (a) {
      ob(this || _global);
      var b = 0,
          c = (this || _global).j,
          d = this || _global,
          e = new jb();

      e.next = function () {
        if (c != d.j) throw Error("The map has changed since the iterator was created");
        if (b >= d.a.length) throw ib;
        var f = d.a[b++];
        return a ? f : d.g[f];
      };

      return e;
    };

    function pb(a, b) {
      return Object.prototype.hasOwnProperty.call(a, b);
    }

    var qb = /^(?:([^:/?#.]+):)?(?:\/\/(?:([^/?#]*)@)?([^/#?]*?)(?::([0-9]+))?(?=[/#?]|$))?([^?#]+)?(?:\?([^#]*))?(?:#([\s\S]*))?$/;

    function rb(a, b) {
      if (a) {
        a = a.split("&");

        for (var c = 0; c < a.length; c++) {
          var d = a[c].indexOf("="),
              e = null;

          if (0 <= d) {
            var f = a[c].substring(0, d);
            e = a[c].substring(d + 1);
          } else f = a[c];

          b(f, e ? decodeURIComponent(e.replace(/\+/g, " ")) : "");
        }
      }
    }

    function sb(a, b, c, d) {
      for (var e = c.length; 0 <= (b = a.indexOf(c, b)) && b < d;) {
        var f = a.charCodeAt(b - 1);
        if (38 == f || 63 == f) if (f = a.charCodeAt(b + e), !f || 61 == f || 38 == f || 35 == f) return b;
        b += e + 1;
      }

      return -1;
    }

    var tb = /#|$/;

    function ub(a, b) {
      var c = a.search(tb),
          d = sb(a, 0, b, c);
      if (0 > d) return null;
      var e = a.indexOf("&", d);
      if (0 > e || e > c) e = c;
      d += b.length + 1;
      return decodeURIComponent(a.substr(d, e - d).replace(/\+/g, " "));
    }

    var vb = /[?&]($|#)/;

    function wb(a, b) {
      (this || _global).h = (this || _global).A = (this || _global).j = "";
      (this || _global).C = null;
      (this || _global).s = (this || _global).g = "";
      (this || _global).i = !1;
      var c;
      a instanceof wb ? ((this || _global).i = ka(b) ? b : a.i, xb(this || _global, a.j), (this || _global).A = a.A, (this || _global).h = a.h, yb(this || _global, a.C), (this || _global).g = a.g, zb(this || _global, Ab(a.a)), (this || _global).s = a.s) : a && (c = String(a).match(qb)) ? ((this || _global).i = !!b, xb(this || _global, c[1] || "", !0), (this || _global).A = Bb(c[2] || ""), (this || _global).h = Bb(c[3] || "", !0), yb(this || _global, c[4]), (this || _global).g = Bb(c[5] || "", !0), zb(this || _global, c[6] || "", !0), (this || _global).s = Bb(c[7] || "")) : ((this || _global).i = !!b, (this || _global).a = new Cb(null, (this || _global).i));
    }

    wb.prototype.toString = function () {
      var a = [],
          b = (this || _global).j;
      b && a.push(Db(b, Eb, !0), ":");
      var c = (this || _global).h;
      if (c || "file" == b) a.push("//"), (b = (this || _global).A) && a.push(Db(b, Eb, !0), "@"), a.push(encodeURIComponent(String(c)).replace(/%25([0-9a-fA-F]{2})/g, "%$1")), c = (this || _global).C, null != c && a.push(":", String(c));
      if (c = (this || _global).g) (this || _global).h && "/" != c.charAt(0) && a.push("/"), a.push(Db(c, "/" == c.charAt(0) ? Fb : Gb, !0));
      (c = (this || _global).a.toString()) && a.push("?", c);
      (c = (this || _global).s) && a.push("#", Db(c, Hb));
      return a.join("");
    };

    function xb(a, b, c) {
      a.j = c ? Bb(b, !0) : b;
      a.j && (a.j = a.j.replace(/:$/, ""));
    }

    function yb(a, b) {
      if (b) {
        b = Number(b);
        if (isNaN(b) || 0 > b) throw Error("Bad port number " + b);
        a.C = b;
      } else a.C = null;
    }

    function zb(a, b, c) {
      b instanceof Cb ? (a.a = b, Ib(a.a, a.i)) : (c || (b = Db(b, Jb)), a.a = new Cb(b, a.i));
    }

    function Kb(a) {
      return a instanceof wb ? new wb(a) : new wb(a, void 0);
    }

    function Bb(a, b) {
      return a ? b ? decodeURI(a.replace(/%25/g, "%2525")) : decodeURIComponent(a) : "";
    }

    function Db(a, b, c) {
      return q(a) ? (a = encodeURI(a).replace(b, Lb), c && (a = a.replace(/%25([0-9a-fA-F]{2})/g, "%$1")), a) : null;
    }

    function Lb(a) {
      a = a.charCodeAt(0);
      return "%" + (a >> 4 & 15).toString(16) + (a & 15).toString(16);
    }

    var Eb = /[#\/\?@]/g,
        Gb = /[#\?:]/g,
        Fb = /[#\?]/g,
        Jb = /[#\?@]/g,
        Hb = /#/g;

    function Cb(a, b) {
      (this || _global).g = (this || _global).a = null;
      (this || _global).h = a || null;
      (this || _global).j = !!b;
    }

    function Mb(a) {
      a.a || (a.a = new nb(), a.g = 0, a.h && rb(a.h, function (b, c) {
        a.add(decodeURIComponent(b.replace(/\+/g, " ")), c);
      }));
    }

    l = Cb.prototype;

    l.add = function (a, b) {
      Mb(this || _global);
      (this || _global).h = null;
      a = Nb(this || _global, a);

      var c = (this || _global).a.get(a);

      c || (this || _global).a.set(a, c = []);
      c.push(b);
      (this || _global).g += 1;
      return this || _global;
    };

    function Ob(a, b) {
      Mb(a);
      b = Nb(a, b);
      pb(a.a.g, b) && (a.h = null, a.g -= a.a.get(b).length, a = a.a, pb(a.g, b) && (delete a.g[b], a.h--, a.j++, a.a.length > 2 * a.h && ob(a)));
    }

    l.clear = function () {
      (this || _global).a = (this || _global).h = null;
      (this || _global).g = 0;
    };

    function Pb(a, b) {
      Mb(a);
      b = Nb(a, b);
      return pb(a.a.g, b);
    }

    l.forEach = function (a, b) {
      Mb(this || _global);

      (this || _global).a.forEach(function (c, d) {
        Ha(c, function (e) {
          a.call(b, e, d, this || _global);
        }, this || _global);
      }, this || _global);
    };

    l.ja = function () {
      Mb(this || _global);

      for (var a = (this || _global).a.la(), b = (this || _global).a.ja(), c = [], d = 0; d < b.length; d++) for (var e = a[d], f = 0; f < e.length; f++) c.push(b[d]);

      return c;
    };

    l.la = function (a) {
      Mb(this || _global);
      var b = [];
      if (q(a)) Pb(this || _global, a) && (b = Ra(b, (this || _global).a.get(Nb(this || _global, a))));else {
        a = (this || _global).a.la();

        for (var c = 0; c < a.length; c++) b = Ra(b, a[c]);
      }
      return b;
    };

    l.set = function (a, b) {
      Mb(this || _global);
      (this || _global).h = null;
      a = Nb(this || _global, a);
      Pb(this || _global, a) && ((this || _global).g -= (this || _global).a.get(a).length);

      (this || _global).a.set(a, [b]);

      (this || _global).g += 1;
      return this || _global;
    };

    l.get = function (a, b) {
      if (!a) return b;
      a = this.la(a);
      return 0 < a.length ? String(a[0]) : b;
    };

    l.toString = function () {
      if ((this || _global).h) return (this || _global).h;
      if (!(this || _global).a) return "";

      for (var a = [], b = (this || _global).a.ja(), c = 0; c < b.length; c++) {
        var d = b[c],
            e = encodeURIComponent(String(d));
        d = this.la(d);

        for (var f = 0; f < d.length; f++) {
          var g = e;
          "" !== d[f] && (g += "=" + encodeURIComponent(String(d[f])));
          a.push(g);
        }
      }

      return (this || _global).h = a.join("&");
    };

    function Ab(a) {
      var b = new Cb();
      b.h = a.h;
      a.a && (b.a = new nb(a.a), b.g = a.g);
      return b;
    }

    function Nb(a, b) {
      b = String(b);
      a.j && (b = b.toLowerCase());
      return b;
    }

    function Ib(a, b) {
      b && !a.j && (Mb(a), a.h = null, a.a.forEach(function (c, d) {
        var e = d.toLowerCase();
        d != e && (Ob(this || _global, d), Ob(this || _global, e), 0 < c.length && ((this || _global).h = null, (this || _global).a.set(Nb(this || _global, e), Sa(c)), (this || _global).g += c.length));
      }, a));
      a.j = b;
    }

    function Qb(a) {
      (this || _global).a = Kb(a);
    }

    function Rb(a, b) {
      b ? a.a.a.set(x.Sa, b) : Ob(a.a.a, x.Sa);
    }

    function Sb(a, b) {
      null !== b ? a.a.a.set(x.Qa, b ? "1" : "0") : Ob(a.a.a, x.Qa);
    }

    function Tb(a) {
      return a.a.a.get(x.Pa) || null;
    }

    function Ub(a, b) {
      b ? a.a.a.set(x.PROVIDER_ID, b) : Ob(a.a.a, x.PROVIDER_ID);
    }

    Qb.prototype.toString = function () {
      return (this || _global).a.toString();
    };

    var x = {
      Pa: "ui_auid",
      lc: "apiKey",
      Qa: "ui_sd",
      ub: "mode",
      $a: "oobCode",
      PROVIDER_ID: "ui_pid",
      Sa: "ui_sid",
      vb: "tenantId"
    };
    var Vb;

    a: {
      var Wb = n.navigator;

      if (Wb) {
        var Xb = Wb.userAgent;

        if (Xb) {
          Vb = Xb;
          break a;
        }
      }

      Vb = "";
    }

    function y(a) {
      return -1 != Vb.indexOf(a);
    }

    function Yb() {
      return (y("Chrome") || y("CriOS")) && !y("Edge");
    }

    function Zb(a) {
      Zb[" "](a);
      return a;
    }

    Zb[" "] = na;

    function $b(a, b) {
      var c = ac;
      return Object.prototype.hasOwnProperty.call(c, a) ? c[a] : c[a] = b(a);
    }

    var bc = y("Opera"),
        z = y("Trident") || y("MSIE"),
        cc = y("Edge"),
        dc = cc || z,
        ec = y("Gecko") && !(-1 != Vb.toLowerCase().indexOf("webkit") && !y("Edge")) && !(y("Trident") || y("MSIE")) && !y("Edge"),
        fc = -1 != Vb.toLowerCase().indexOf("webkit") && !y("Edge"),
        gc = fc && y("Mobile"),
        hc = y("Macintosh");

    function ic() {
      var a = n.document;
      return a ? a.documentMode : void 0;
    }

    var jc;

    a: {
      var kc = "",
          lc = function () {
        var a = Vb;
        if (ec) return /rv:([^\);]+)(\)|;)/.exec(a);
        if (cc) return /Edge\/([\d\.]+)/.exec(a);
        if (z) return /\b(?:MSIE|rv)[: ]([^\);]+)(\)|;)/.exec(a);
        if (fc) return /WebKit\/(\S+)/.exec(a);
        if (bc) return /(?:Version)[ \/]?(\S+)/.exec(a);
      }();

      lc && (kc = lc ? lc[1] : "");

      if (z) {
        var mc = ic();

        if (null != mc && mc > parseFloat(kc)) {
          jc = String(mc);
          break a;
        }
      }

      jc = kc;
    }

    var ac = {};

    function nc(a) {
      return $b(a, function () {
        for (var b = 0, c = Va(String(jc)).split("."), d = Va(String(a)).split("."), e = Math.max(c.length, d.length), f = 0; 0 == b && f < e; f++) {
          var g = c[f] || "",
              h = d[f] || "";

          do {
            g = /(\d*)(\D*)(.*)/.exec(g) || ["", "", "", ""];
            h = /(\d*)(\D*)(.*)/.exec(h) || ["", "", "", ""];
            if (0 == g[0].length && 0 == h[0].length) break;
            b = cb(0 == g[1].length ? 0 : parseInt(g[1], 10), 0 == h[1].length ? 0 : parseInt(h[1], 10)) || cb(0 == g[2].length, 0 == h[2].length) || cb(g[2], h[2]);
            g = g[3];
            h = h[3];
          } while (0 == b);
        }

        return 0 <= b;
      });
    }

    var oc;
    var pc = n.document;
    oc = pc && z ? ic() || ("CSS1Compat" == pc.compatMode ? parseInt(jc, 10) : 5) : void 0;

    function qc(a, b) {
      (this || _global).a = a === rc && b || "";
      (this || _global).g = sc;
    }

    qc.prototype.ma = !0;

    qc.prototype.ka = function () {
      return (this || _global).a;
    };

    qc.prototype.toString = function () {
      return "Const{" + (this || _global).a + "}";
    };

    var sc = {},
        rc = {};

    function tc() {
      (this || _global).a = "";
      (this || _global).h = uc;
    }

    tc.prototype.ma = !0;

    tc.prototype.ka = function () {
      return (this || _global).a.toString();
    };

    tc.prototype.g = function () {
      return 1;
    };

    tc.prototype.toString = function () {
      return "TrustedResourceUrl{" + (this || _global).a + "}";
    };

    function vc(a) {
      if (a instanceof tc && a.constructor === tc && a.h === uc) return a.a;
      Fa("expected object of type TrustedResourceUrl, got '" + a + "' of type " + pa(a));
      return "type_error:TrustedResourceUrl";
    }

    function wc() {
      var a = xc;
      a instanceof qc && a.constructor === qc && a.g === sc ? a = a.a : (Fa("expected object of type Const, got '" + a + "'"), a = "type_error:Const");
      var b = new tc();
      b.a = a;
      return b;
    }

    var uc = {};

    function yc() {
      (this || _global).a = "";
      (this || _global).h = zc;
    }

    yc.prototype.ma = !0;

    yc.prototype.ka = function () {
      return (this || _global).a.toString();
    };

    yc.prototype.g = function () {
      return 1;
    };

    yc.prototype.toString = function () {
      return "SafeUrl{" + (this || _global).a + "}";
    };

    function Ac(a) {
      if (a instanceof yc && a.constructor === yc && a.h === zc) return a.a;
      Fa("expected object of type SafeUrl, got '" + a + "' of type " + pa(a));
      return "type_error:SafeUrl";
    }

    var Bc = /^(?:(?:https?|mailto|ftp):|[^:/?#]*(?:[/?#]|$))/i;

    function Cc(a) {
      if (a instanceof yc) return a;
      a = "object" == typeof a && a.ma ? a.ka() : String(a);
      Bc.test(a) || (a = "about:invalid#zClosurez");
      return Dc(a);
    }

    function Ec(a) {
      if (a instanceof yc) return a;
      a = "object" == typeof a && a.ma ? a.ka() : String(a);
      Bc.test(a) || (a = "about:invalid#zClosurez");
      return Dc(a);
    }

    var zc = {};

    function Dc(a) {
      var b = new yc();
      b.a = a;
      return b;
    }

    Dc("about:blank");

    function Fc() {
      (this || _global).a = "";
      (this || _global).g = Gc;
    }

    Fc.prototype.ma = !0;
    var Gc = {};

    Fc.prototype.ka = function () {
      return (this || _global).a;
    };

    Fc.prototype.toString = function () {
      return "SafeStyle{" + (this || _global).a + "}";
    };

    function Hc() {
      (this || _global).a = "";
      (this || _global).j = Ic;
      (this || _global).h = null;
    }

    Hc.prototype.g = function () {
      return (this || _global).h;
    };

    Hc.prototype.ma = !0;

    Hc.prototype.ka = function () {
      return (this || _global).a.toString();
    };

    Hc.prototype.toString = function () {
      return "SafeHtml{" + (this || _global).a + "}";
    };

    function Jc(a) {
      if (a instanceof Hc && a.constructor === Hc && a.j === Ic) return a.a;
      Fa("expected object of type SafeHtml, got '" + a + "' of type " + pa(a));
      return "type_error:SafeHtml";
    }

    var Ic = {};

    function Kc(a, b) {
      var c = new Hc();
      c.a = a;
      c.h = b;
      return c;
    }

    Kc("<!DOCTYPE html>", 0);
    var Lc = Kc("", 0);
    Kc("<br>", 0);

    var Mc = function (a) {
      var b = !1,
          c;
      return function () {
        b || (c = a(), b = !0);
        return c;
      };
    }(function () {
      if ("undefined" === typeof document) return !1;
      var a = document.createElement("div"),
          b = document.createElement("div");
      b.appendChild(document.createElement("div"));
      a.appendChild(b);
      if (!a.firstChild) return !1;
      b = a.firstChild.firstChild;
      a.innerHTML = Jc(Lc);
      return !b.parentElement;
    });

    function Nc(a, b) {
      a.src = vc(b);
      if (null === ma) b: {
        b = n.document;

        if ((b = b.querySelector && b.querySelector("script[nonce]")) && (b = b.nonce || b.getAttribute("nonce")) && la.test(b)) {
          ma = b;
          break b;
        }

        ma = "";
      }
      b = ma;
      b && a.setAttribute("nonce", b);
    }

    function Oc(a, b) {
      b = b instanceof yc ? b : Ec(b);
      a.assign(Ac(b));
    }

    function Pc(a, b) {
      (this || _global).a = ka(a) ? a : 0;
      (this || _global).g = ka(b) ? b : 0;
    }

    Pc.prototype.toString = function () {
      return "(" + (this || _global).a + ", " + (this || _global).g + ")";
    };

    Pc.prototype.ceil = function () {
      (this || _global).a = Math.ceil((this || _global).a);
      (this || _global).g = Math.ceil((this || _global).g);
      return this || _global;
    };

    Pc.prototype.floor = function () {
      (this || _global).a = Math.floor((this || _global).a);
      (this || _global).g = Math.floor((this || _global).g);
      return this || _global;
    };

    Pc.prototype.round = function () {
      (this || _global).a = Math.round((this || _global).a);
      (this || _global).g = Math.round((this || _global).g);
      return this || _global;
    };

    function Qc(a, b) {
      (this || _global).width = a;
      (this || _global).height = b;
    }

    l = Qc.prototype;

    l.toString = function () {
      return "(" + (this || _global).width + " x " + (this || _global).height + ")";
    };

    l.aspectRatio = function () {
      return (this || _global).width / (this || _global).height;
    };

    l.ceil = function () {
      (this || _global).width = Math.ceil((this || _global).width);
      (this || _global).height = Math.ceil((this || _global).height);
      return this || _global;
    };

    l.floor = function () {
      (this || _global).width = Math.floor((this || _global).width);
      (this || _global).height = Math.floor((this || _global).height);
      return this || _global;
    };

    l.round = function () {
      (this || _global).width = Math.round((this || _global).width);
      (this || _global).height = Math.round((this || _global).height);
      return this || _global;
    };

    function Rc(a) {
      return a ? new Sc(Tc(a)) : Da || (Da = new Sc());
    }

    function Uc(a, b) {
      var c = b || document;
      return c.querySelectorAll && c.querySelector ? c.querySelectorAll("." + a) : Vc(document, a, b);
    }

    function Wc(a, b) {
      var c = b || document;
      if (c.getElementsByClassName) a = c.getElementsByClassName(a)[0];else {
        c = document;
        var d = b || c;
        a = d.querySelectorAll && d.querySelector && a ? d.querySelector(a ? "." + a : "") : Vc(c, a, b)[0] || null;
      }
      return a || null;
    }

    function Vc(a, b, c) {
      var d;
      a = c || a;
      if (a.querySelectorAll && a.querySelector && b) return a.querySelectorAll(b ? "." + b : "");

      if (b && a.getElementsByClassName) {
        var e = a.getElementsByClassName(b);
        return e;
      }

      e = a.getElementsByTagName("*");

      if (b) {
        var f = {};

        for (c = d = 0; a = e[c]; c++) {
          var g = a.className;
          "function" == typeof g.split && Ma(g.split(/\s+/), b) && (f[d++] = a);
        }

        f.length = d;
        return f;
      }

      return e;
    }

    function Xc(a, b) {
      eb(b, function (c, d) {
        c && "object" == typeof c && c.ma && (c = c.ka());
        "style" == d ? a.style.cssText = c : "class" == d ? a.className = c : "for" == d ? a.htmlFor = c : Yc.hasOwnProperty(d) ? a.setAttribute(Yc[d], c) : 0 == d.lastIndexOf("aria-", 0) || 0 == d.lastIndexOf("data-", 0) ? a.setAttribute(d, c) : a[d] = c;
      });
    }

    var Yc = {
      cellpadding: "cellPadding",
      cellspacing: "cellSpacing",
      colspan: "colSpan",
      frameborder: "frameBorder",
      height: "height",
      maxlength: "maxLength",
      nonce: "nonce",
      role: "role",
      rowspan: "rowSpan",
      type: "type",
      usemap: "useMap",
      valign: "vAlign",
      width: "width"
    };

    function Zc(a) {
      return a.scrollingElement ? a.scrollingElement : fc || "CSS1Compat" != a.compatMode ? a.body || a.documentElement : a.documentElement;
    }

    function $c(a) {
      a && a.parentNode && a.parentNode.removeChild(a);
    }

    function Tc(a) {
      return 9 == a.nodeType ? a : a.ownerDocument || a.document;
    }

    function ad(a, b) {
      if ("textContent" in a) a.textContent = b;else if (3 == a.nodeType) a.data = String(b);else if (a.firstChild && 3 == a.firstChild.nodeType) {
        for (; a.lastChild != a.firstChild;) a.removeChild(a.lastChild);

        a.firstChild.data = String(b);
      } else {
        for (var c; c = a.firstChild;) a.removeChild(c);

        a.appendChild(Tc(a).createTextNode(String(b)));
      }
    }

    function bd(a, b) {
      return b ? cd(a, function (c) {
        return !b || q(c.className) && Ma(c.className.split(/\s+/), b);
      }) : null;
    }

    function cd(a, b) {
      for (var c = 0; a;) {
        if (b(a)) return a;
        a = a.parentNode;
        c++;
      }

      return null;
    }

    function Sc(a) {
      (this || _global).a = a || n.document || document;
    }

    Sc.prototype.N = function () {
      return q(void 0) ? (this || _global).a.getElementById(void 0) : void 0;
    };

    var dd = {
      Fc: !0
    },
        ed = {
      Hc: !0
    },
        fd = {
      Ec: !0
    },
        gd = {
      Gc: !0
    };

    function hd() {
      throw Error("Do not instantiate directly");
    }

    hd.prototype.va = null;

    hd.prototype.toString = function () {
      return (this || _global).content;
    };

    function id(a, b, c, d) {
      a = a(b || jd, void 0, c);
      d = (d || Rc()).a.createElement("DIV");
      a = kd(a);
      a.match(nd);
      a = Kc(a, null);
      if (Mc()) for (; d.lastChild;) d.removeChild(d.lastChild);
      d.innerHTML = Jc(a);
      1 == d.childNodes.length && (a = d.firstChild, 1 == a.nodeType && (d = a));
      return d;
    }

    function kd(a) {
      if (!ta(a)) return db(String(a));

      if (a instanceof hd) {
        if (a.fa === dd) return a.content;
        if (a.fa === gd) return db(a.content);
      }

      Fa("Soy template output is unsafe for use as HTML: " + a);
      return "zSoyz";
    }

    var nd = /^<(body|caption|col|colgroup|head|html|tr|td|th|tbody|thead|tfoot)>/i,
        jd = {};

    function od(a) {
      if (null != a) switch (a.va) {
        case 1:
          return 1;

        case -1:
          return -1;

        case 0:
          return 0;
      }
      return null;
    }

    function pd() {
      hd.call(this || _global);
    }

    w(pd, hd);
    pd.prototype.fa = dd;

    function A(a) {
      return null != a && a.fa === dd ? a : a instanceof Hc ? B(Jc(a).toString(), a.g()) : B(db(String(String(a))), od(a));
    }

    function qd() {
      hd.call(this || _global);
    }

    w(qd, hd);
    qd.prototype.fa = ed;
    qd.prototype.va = 1;

    function rd(a, b) {
      (this || _global).content = String(a);
      (this || _global).va = null != b ? b : null;
    }

    w(rd, hd);
    rd.prototype.fa = gd;

    function C(a) {
      return new rd(a, void 0);
    }

    var B = function (a) {
      function b(c) {
        (this || _global).content = c;
      }

      b.prototype = a.prototype;
      return function (c, d) {
        c = new b(String(c));
        void 0 !== d && (c.va = d);
        return c;
      };
    }(pd),
        sd = function (a) {
      function b(c) {
        (this || _global).content = c;
      }

      b.prototype = a.prototype;
      return function (c) {
        return new b(String(c));
      };
    }(qd);

    function td(a) {
      function b() {}

      var c = {
        label: D("New password")
      };
      b.prototype = a;
      a = new b();

      for (var d in c) a[d] = c[d];

      return a;
    }

    function D(a) {
      return (a = String(a)) ? new rd(a, void 0) : "";
    }

    var ud = function (a) {
      function b(c) {
        (this || _global).content = c;
      }

      b.prototype = a.prototype;
      return function (c, d) {
        c = String(c);
        if (!c) return "";
        c = new b(c);
        void 0 !== d && (c.va = d);
        return c;
      };
    }(pd);

    function vd(a) {
      return null != a && a.fa === dd ? String(String(a.content).replace(wd, "").replace(xd, "&lt;")).replace(yd, zd) : db(String(a));
    }

    function Ad(a) {
      null != a && a.fa === ed ? a = String(a).replace(Bd, Cd) : a instanceof yc ? a = String(Ac(a).toString()).replace(Bd, Cd) : (a = String(a), Dd.test(a) ? a = a.replace(Bd, Cd) : (Fa("Bad value `%s` for |filterNormalizeUri", [a]), a = "#zSoyz"));
      return a;
    }

    function Ed(a) {
      null != a && a.fa === fd ? a = a.content : null == a ? a = "" : a instanceof Fc ? a instanceof Fc && a.constructor === Fc && a.g === Gc ? a = a.a : (Fa("expected object of type SafeStyle, got '" + a + "' of type " + pa(a)), a = "type_error:SafeStyle") : (a = String(a), Fd.test(a) || (Fa("Bad value `%s` for |filterCssValue", [a]), a = "zSoyz"));
      return a;
    }

    var Gd = {
      "\0": "&#0;",
      "\t": "&#9;",
      "\n": "&#10;",
      "\x0B": "&#11;",
      "\f": "&#12;",
      "\r": "&#13;",
      " ": "&#32;",
      "\"": "&quot;",
      "&": "&amp;",
      "'": "&#39;",
      "-": "&#45;",
      "/": "&#47;",
      "<": "&lt;",
      "=": "&#61;",
      ">": "&gt;",
      "`": "&#96;",
      "\x85": "&#133;",
      "\xA0": "&#160;",
      "\u2028": "&#8232;",
      "\u2029": "&#8233;"
    };

    function zd(a) {
      return Gd[a];
    }

    var Hd = {
      "\0": "%00",
      "\x01": "%01",
      "\x02": "%02",
      "\x03": "%03",
      "\x04": "%04",
      "\x05": "%05",
      "\x06": "%06",
      "\x07": "%07",
      "\b": "%08",
      "\t": "%09",
      "\n": "%0A",
      "\x0B": "%0B",
      "\f": "%0C",
      "\r": "%0D",
      "\x0E": "%0E",
      "\x0F": "%0F",
      "\x10": "%10",
      "\x11": "%11",
      "\x12": "%12",
      "\x13": "%13",
      "\x14": "%14",
      "\x15": "%15",
      "\x16": "%16",
      "\x17": "%17",
      "\x18": "%18",
      "\x19": "%19",
      "\x1A": "%1A",
      "\x1B": "%1B",
      "\x1C": "%1C",
      "\x1D": "%1D",
      "\x1E": "%1E",
      "\x1F": "%1F",
      " ": "%20",
      "\"": "%22",
      "'": "%27",
      "(": "%28",
      ")": "%29",
      "<": "%3C",
      ">": "%3E",
      "\\": "%5C",
      "{": "%7B",
      "}": "%7D",
      "\x7F": "%7F",
      "\x85": "%C2%85",
      "\xA0": "%C2%A0",
      "\u2028": "%E2%80%A8",
      "\u2029": "%E2%80%A9",
      "\uFF01": "%EF%BC%81",
      "\uFF03": "%EF%BC%83",
      "\uFF04": "%EF%BC%84",
      "\uFF06": "%EF%BC%86",
      "\uFF07": "%EF%BC%87",
      "\uFF08": "%EF%BC%88",
      "\uFF09": "%EF%BC%89",
      "\uFF0A": "%EF%BC%8A",
      "\uFF0B": "%EF%BC%8B",
      "\uFF0C": "%EF%BC%8C",
      "\uFF0F": "%EF%BC%8F",
      "\uFF1A": "%EF%BC%9A",
      "\uFF1B": "%EF%BC%9B",
      "\uFF1D": "%EF%BC%9D",
      "\uFF1F": "%EF%BC%9F",
      "\uFF20": "%EF%BC%A0",
      "\uFF3B": "%EF%BC%BB",
      "\uFF3D": "%EF%BC%BD"
    };

    function Cd(a) {
      return Hd[a];
    }

    var yd = /[\x00\x22\x27\x3c\x3e]/g,
        Bd = /[\x00- \x22\x27-\x29\x3c\x3e\\\x7b\x7d\x7f\x85\xa0\u2028\u2029\uff01\uff03\uff04\uff06-\uff0c\uff0f\uff1a\uff1b\uff1d\uff1f\uff20\uff3b\uff3d]/g,
        Fd = /^(?!-*(?:expression|(?:moz-)?binding))(?:[.#]?-?(?:[_a-z0-9-]+)(?:-[_a-z0-9-]+)*-?|-?(?:[0-9]+(?:\.[0-9]*)?|\.[0-9]+)(?:[a-z]{1,2}|%)?|!important|)$/i,
        Dd = /^(?![^#?]*\/(?:\.|%2E){2}(?:[\/?#]|$))(?:(?:https?|mailto):|[^&:\/?#]*(?:[\/?#]|$))/i,
        wd = /<(?:!|\/?([a-zA-Z][a-zA-Z0-9:\-]*))(?:[^>'"]|"[^"]*"|'[^']*')*>/g,
        xd = /</g;

    function Id() {
      return C("Enter a valid phone number");
    }

    function Jd() {
      return C("Unable to send password reset code to specified email");
    }

    function Kd() {
      return C("Something went wrong. Please try again.");
    }

    function Ld() {
      return C("This email already exists without any means of sign-in. Please reset the password to recover.");
    }

    function Md(a) {
      a = a || {};
      var b = "";

      switch (a.code) {
        case "invalid-argument":
          b += "Client specified an invalid argument.";
          break;

        case "invalid-configuration":
          b += "Client specified an invalid project configuration.";
          break;

        case "failed-precondition":
          b += "Request can not be executed in the current system state.";
          break;

        case "out-of-range":
          b += "Client specified an invalid range.";
          break;

        case "unauthenticated":
          b += "Request not authenticated due to missing, invalid, or expired OAuth token.";
          break;

        case "permission-denied":
          b += "Client does not have sufficient permission.";
          break;

        case "not-found":
          b += "Specified resource is not found.";
          break;

        case "aborted":
          b += "Concurrency conflict, such as read-modify-write conflict.";
          break;

        case "already-exists":
          b += "The resource that a client tried to create already exists.";
          break;

        case "resource-exhausted":
          b += "Either out of resource quota or reaching rate limiting.";
          break;

        case "cancelled":
          b += "Request cancelled by the client.";
          break;

        case "data-loss":
          b += "Unrecoverable data loss or data corruption.";
          break;

        case "unknown":
          b += "Unknown server error.";
          break;

        case "internal":
          b += "Internal server error.";
          break;

        case "not-implemented":
          b += "API method not implemented by the server.";
          break;

        case "unavailable":
          b += "Service unavailable.";
          break;

        case "deadline-exceeded":
          b += "Request deadline exceeded.";
          break;

        case "auth/user-disabled":
          b += "The user account has been disabled by an administrator.";
          break;

        case "auth/timeout":
          b += "The operation has timed out.";
          break;

        case "auth/too-many-requests":
          b += "We have blocked all requests from this device due to unusual activity. Try again later.";
          break;

        case "auth/quota-exceeded":
          b += "The quota for this operation has been exceeded. Try again later.";
          break;

        case "auth/network-request-failed":
          b += "A network error has occurred. Try again later.";
          break;

        case "restart-process":
          b += "An issue was encountered when authenticating your request. Please visit the URL that redirected you to this page again to restart the authentication process.";
          break;

        case "no-matching-tenant-for-email":
          b += "No sign-in provider is available for the given email, please try with a different email.";
      }

      return C(b);
    }

    function Nd() {
      return C("Please login again to perform this operation");
    }

    function Od(a, b, c) {
      var d = Error.call(this || _global);
      (this || _global).message = d.message;
      "stack" in d && ((this || _global).stack = d.stack);
      (this || _global).code = Pd + a;

      if (!(a = b)) {
        a = "";

        switch ((this || _global).code) {
          case "firebaseui/merge-conflict":
            a += "The current anonymous user failed to upgrade. The non-anonymous credential is already associated with a different user account.";
            break;

          default:
            a += Kd();
        }

        a = C(a).toString();
      }

      (this || _global).message = a || "";
      (this || _global).credential = c || null;
    }

    m(Od, Error);

    Od.prototype.toJSON = function () {
      return {
        code: (this || _global).code,
        message: (this || _global).message
      };
    };

    var Pd = "firebaseui/";

    function Qd() {
      0 != Rd && (Sd[(this || _global)[ua] || ((this || _global)[ua] = ++va)] = this || _global);
      (this || _global).T = (this || _global).T;
      (this || _global).C = (this || _global).C;
    }

    var Rd = 0,
        Sd = {};
    Qd.prototype.T = !1;

    Qd.prototype.o = function () {
      if (!(this || _global).T && ((this || _global).T = !0, this.m(), 0 != Rd)) {
        var a = (this || _global)[ua] || ((this || _global)[ua] = ++va);
        if (0 != Rd && (this || _global).C && 0 < (this || _global).C.length) throw Error((this || _global) + " did not empty its onDisposeCallbacks queue. This probably means it overrode dispose() or disposeInternal() without calling the superclass' method.");
        delete Sd[a];
      }
    };

    function Td(a, b) {
      a.T ? ka(void 0) ? b.call(void 0) : b() : (a.C || (a.C = []), a.C.push(ka(void 0) ? t(b, void 0) : b));
    }

    Qd.prototype.m = function () {
      if ((this || _global).C) for (; (this || _global).C.length;) (this || _global).C.shift()();
    };

    function Ud(a) {
      a && "function" == typeof a.o && a.o();
    }

    var Vd = Object.freeze || function (a) {
      return a;
    };

    var Wd = !z || 9 <= Number(oc),
        Xd = z && !nc("9"),
        Yd = function () {
      if (!n.addEventListener || !Object.defineProperty) return !1;
      var a = !1,
          b = Object.defineProperty({}, "passive", {
        get: function () {
          a = !0;
        }
      });

      try {
        n.addEventListener("test", na, b), n.removeEventListener("test", na, b);
      } catch (c) {}

      return a;
    }();

    function Zd(a, b) {
      (this || _global).type = a;
      (this || _global).g = (this || _global).target = b;
      (this || _global).h = !1;
      (this || _global).qb = !0;
    }

    Zd.prototype.stopPropagation = function () {
      (this || _global).h = !0;
    };

    Zd.prototype.preventDefault = function () {
      (this || _global).qb = !1;
    };

    function $d(a, b) {
      Zd.call(this || _global, a ? a.type : "");
      (this || _global).relatedTarget = (this || _global).g = (this || _global).target = null;
      (this || _global).button = (this || _global).screenY = (this || _global).screenX = (this || _global).clientY = (this || _global).clientX = 0;
      (this || _global).key = "";
      (this || _global).j = (this || _global).keyCode = 0;
      (this || _global).metaKey = (this || _global).shiftKey = (this || _global).altKey = (this || _global).ctrlKey = !1;
      (this || _global).pointerId = 0;
      (this || _global).pointerType = "";
      (this || _global).a = null;

      if (a) {
        var c = (this || _global).type = a.type,
            d = a.changedTouches && a.changedTouches.length ? a.changedTouches[0] : null;
        (this || _global).target = a.target || a.srcElement;
        (this || _global).g = b;

        if (b = a.relatedTarget) {
          if (ec) {
            a: {
              try {
                Zb(b.nodeName);
                var e = !0;
                break a;
              } catch (f) {}

              e = !1;
            }

            e || (b = null);
          }
        } else "mouseover" == c ? b = a.fromElement : "mouseout" == c && (b = a.toElement);

        (this || _global).relatedTarget = b;
        d ? ((this || _global).clientX = void 0 !== d.clientX ? d.clientX : d.pageX, (this || _global).clientY = void 0 !== d.clientY ? d.clientY : d.pageY, (this || _global).screenX = d.screenX || 0, (this || _global).screenY = d.screenY || 0) : ((this || _global).clientX = void 0 !== a.clientX ? a.clientX : a.pageX, (this || _global).clientY = void 0 !== a.clientY ? a.clientY : a.pageY, (this || _global).screenX = a.screenX || 0, (this || _global).screenY = a.screenY || 0);
        (this || _global).button = a.button;
        (this || _global).keyCode = a.keyCode || 0;
        (this || _global).key = a.key || "";
        (this || _global).j = a.charCode || ("keypress" == c ? a.keyCode : 0);
        (this || _global).ctrlKey = a.ctrlKey;
        (this || _global).altKey = a.altKey;
        (this || _global).shiftKey = a.shiftKey;
        (this || _global).metaKey = a.metaKey;
        (this || _global).pointerId = a.pointerId || 0;
        (this || _global).pointerType = q(a.pointerType) ? a.pointerType : ae[a.pointerType] || "";
        (this || _global).a = a;
        a.defaultPrevented && this.preventDefault();
      }
    }

    w($d, Zd);
    var ae = Vd({
      2: "touch",
      3: "pen",
      4: "mouse"
    });

    $d.prototype.stopPropagation = function () {
      $d.K.stopPropagation.call(this || _global);
      (this || _global).a.stopPropagation ? (this || _global).a.stopPropagation() : (this || _global).a.cancelBubble = !0;
    };

    $d.prototype.preventDefault = function () {
      $d.K.preventDefault.call(this || _global);
      var a = (this || _global).a;
      if (a.preventDefault) a.preventDefault();else if (a.returnValue = !1, Xd) try {
        if (a.ctrlKey || 112 <= a.keyCode && 123 >= a.keyCode) a.keyCode = -1;
      } catch (b) {}
    };

    var be = "closure_listenable_" + (1000000 * Math.random() | 0),
        ce = 0;

    function de(a, b, c, d, e) {
      (this || _global).listener = a;
      (this || _global).proxy = null;
      (this || _global).src = b;
      (this || _global).type = c;
      (this || _global).capture = !!d;
      (this || _global).La = e;
      (this || _global).key = ++ce;
      (this || _global).sa = (this || _global).Ia = !1;
    }

    function ee(a) {
      a.sa = !0;
      a.listener = null;
      a.proxy = null;
      a.src = null;
      a.La = null;
    }

    function fe(a) {
      (this || _global).src = a;
      (this || _global).a = {};
      (this || _global).g = 0;
    }

    fe.prototype.add = function (a, b, c, d, e) {
      var f = a.toString();
      a = (this || _global).a[f];
      a || (a = (this || _global).a[f] = [], (this || _global).g++);
      var g = ge(a, b, d, e);
      -1 < g ? (b = a[g], c || (b.Ia = !1)) : (b = new de(b, (this || _global).src, f, !!d, e), b.Ia = c, a.push(b));
      return b;
    };

    function he(a, b) {
      var c = b.type;
      c in a.a && Na(a.a[c], b) && (ee(b), 0 == a.a[c].length && (delete a.a[c], a.g--));
    }

    function ge(a, b, c, d) {
      for (var e = 0; e < a.length; ++e) {
        var f = a[e];
        if (!f.sa && f.listener == b && f.capture == !!c && f.La == d) return e;
      }

      return -1;
    }

    var ie = "closure_lm_" + (1000000 * Math.random() | 0),
        je = {},
        ke = 0;

    function le(a, b, c, d, e) {
      if (d && d.once) return me(a, b, c, d, e);

      if (qa(b)) {
        for (var f = 0; f < b.length; f++) le(a, b[f], c, d, e);

        return null;
      }

      c = ne(c);
      return a && a[be] ? a.J.add(String(b), c, !1, ta(d) ? !!d.capture : !!d, e) : oe(a, b, c, !1, d, e);
    }

    function oe(a, b, c, d, e, f) {
      if (!b) throw Error("Invalid event type");
      var g = ta(e) ? !!e.capture : !!e,
          h = pe(a);
      h || (a[ie] = h = new fe(a));
      c = h.add(b, c, d, g, f);
      if (c.proxy) return c;
      d = qe();
      c.proxy = d;
      d.src = a;
      d.listener = c;
      if (a.addEventListener) Yd || (e = g), void 0 === e && (e = !1), a.addEventListener(b.toString(), d, e);else if (a.attachEvent) a.attachEvent(re(b.toString()), d);else if (a.addListener && a.removeListener) a.addListener(d);else throw Error("addEventListener and attachEvent are unavailable.");
      ke++;
      return c;
    }

    function qe() {
      var a = se,
          b = Wd ? function (c) {
        return a.call(b.src, b.listener, c);
      } : function (c) {
        c = a.call(b.src, b.listener, c);
        if (!c) return c;
      };
      return b;
    }

    function me(a, b, c, d, e) {
      if (qa(b)) {
        for (var f = 0; f < b.length; f++) me(a, b[f], c, d, e);

        return null;
      }

      c = ne(c);
      return a && a[be] ? a.J.add(String(b), c, !0, ta(d) ? !!d.capture : !!d, e) : oe(a, b, c, !0, d, e);
    }

    function te(a, b, c, d, e) {
      if (qa(b)) for (var f = 0; f < b.length; f++) te(a, b[f], c, d, e);else (d = ta(d) ? !!d.capture : !!d, c = ne(c), a && a[be]) ? (a = a.J, b = String(b).toString(), b in a.a && (f = a.a[b], c = ge(f, c, d, e), -1 < c && (ee(f[c]), Oa(f, c), 0 == f.length && (delete a.a[b], a.g--)))) : a && (a = pe(a)) && (b = a.a[b.toString()], a = -1, b && (a = ge(b, c, d, e)), (c = -1 < a ? b[a] : null) && ue(c));
    }

    function ue(a) {
      if ("number" != typeof a && a && !a.sa) {
        var b = a.src;
        if (b && b[be]) he(b.J, a);else {
          var c = a.type,
              d = a.proxy;
          b.removeEventListener ? b.removeEventListener(c, d, a.capture) : b.detachEvent ? b.detachEvent(re(c), d) : b.addListener && b.removeListener && b.removeListener(d);
          ke--;
          (c = pe(b)) ? (he(c, a), 0 == c.g && (c.src = null, b[ie] = null)) : ee(a);
        }
      }
    }

    function re(a) {
      return a in je ? je[a] : je[a] = "on" + a;
    }

    function ve(a, b, c, d) {
      var e = !0;
      if (a = pe(a)) if (b = a.a[b.toString()]) for (b = b.concat(), a = 0; a < b.length; a++) {
        var f = b[a];
        f && f.capture == c && !f.sa && (f = we(f, d), e = e && !1 !== f);
      }
      return e;
    }

    function we(a, b) {
      var c = a.listener,
          d = a.La || a.src;
      a.Ia && ue(a);
      return c.call(d, b);
    }

    function se(a, b) {
      if (a.sa) return !0;

      if (!Wd) {
        if (!b) a: {
          b = ["window", "event"];

          for (var c = n, d = 0; d < b.length; d++) if (c = c[b[d]], null == c) {
            b = null;
            break a;
          }

          b = c;
        }
        d = b;
        b = new $d(d, this || _global);
        c = !0;

        if (!(0 > d.keyCode || void 0 != d.returnValue)) {
          a: {
            var e = !1;
            if (0 == d.keyCode) try {
              d.keyCode = -1;
              break a;
            } catch (g) {
              e = !0;
            }
            if (e || void 0 == d.returnValue) d.returnValue = !0;
          }

          d = [];

          for (e = b.g; e; e = e.parentNode) d.push(e);

          a = a.type;

          for (e = d.length - 1; !b.h && 0 <= e; e--) {
            b.g = d[e];
            var f = ve(d[e], a, !0, b);
            c = c && f;
          }

          for (e = 0; !b.h && e < d.length; e++) b.g = d[e], f = ve(d[e], a, !1, b), c = c && f;
        }

        return c;
      }

      return we(a, new $d(b, this || _global));
    }

    function pe(a) {
      a = a[ie];
      return a instanceof fe ? a : null;
    }

    var xe = "__closure_events_fn_" + (1000000000 * Math.random() >>> 0);

    function ne(a) {
      if (sa(a)) return a;
      a[xe] || (a[xe] = function (b) {
        return a.handleEvent(b);
      });
      return a[xe];
    }

    function E() {
      Qd.call(this || _global);
      (this || _global).J = new fe(this || _global);
      (this || _global).wb = this || _global;
      (this || _global).Ha = null;
    }

    w(E, Qd);
    E.prototype[be] = !0;

    E.prototype.Za = function (a) {
      (this || _global).Ha = a;
    };

    E.prototype.removeEventListener = function (a, b, c, d) {
      te(this || _global, a, b, c, d);
    };

    function ye(a, b) {
      var c,
          d = a.Ha;
      if (d) for (c = []; d; d = d.Ha) c.push(d);
      a = a.wb;
      d = b.type || b;
      if (q(b)) b = new Zd(b, a);else if (b instanceof Zd) b.target = b.target || a;else {
        var e = b;
        b = new Zd(d, a);
        hb(b, e);
      }
      e = !0;
      if (c) for (var f = c.length - 1; !b.h && 0 <= f; f--) {
        var g = b.g = c[f];
        e = ze(g, d, !0, b) && e;
      }
      b.h || (g = b.g = a, e = ze(g, d, !0, b) && e, b.h || (e = ze(g, d, !1, b) && e));
      if (c) for (f = 0; !b.h && f < c.length; f++) g = b.g = c[f], e = ze(g, d, !1, b) && e;
      return e;
    }

    E.prototype.m = function () {
      E.K.m.call(this || _global);

      if ((this || _global).J) {
        var a = (this || _global).J,
            b = 0,
            c;

        for (c in a.a) {
          for (var d = a.a[c], e = 0; e < d.length; e++) ++b, ee(d[e]);

          delete a.a[c];
          a.g--;
        }
      }

      (this || _global).Ha = null;
    };

    function ze(a, b, c, d) {
      b = a.J.a[String(b)];
      if (!b) return !0;
      b = b.concat();

      for (var e = !0, f = 0; f < b.length; ++f) {
        var g = b[f];

        if (g && !g.sa && g.capture == c) {
          var h = g.listener,
              k = g.La || g.src;
          g.Ia && he(a.J, g);
          e = !1 !== h.call(k, d) && e;
        }
      }

      return e && 0 != d.qb;
    }

    var Ae = {},
        Be = 0;

    function Ce(a, b) {
      if (!a) throw Error("Event target element must be provided!");
      a = De(a);
      if (Ae[a] && Ae[a].length) for (var c = 0; c < Ae[a].length; c++) ye(Ae[a][c], b);
    }

    function Ee(a) {
      var b = De(a.N());
      Ae[b] && Ae[b].length && (Pa(Ae[b], function (c) {
        return c == a;
      }), Ae[b].length || delete Ae[b]);
    }

    function De(a) {
      "undefined" === typeof a.a && (a.a = Be, Be++);
      return a.a;
    }

    function Fe(a) {
      if (!a) throw Error("Event target element must be provided!");
      E.call(this || _global);
      (this || _global).a = a;
    }

    m(Fe, E);

    Fe.prototype.N = function () {
      return (this || _global).a;
    };

    Fe.prototype.register = function () {
      var a = De(this.N());
      Ae[a] ? Ma(Ae[a], this || _global) || Ae[a].push(this || _global) : Ae[a] = [this || _global];
    };

    function Ge(a) {
      if (!a) return !1;

      try {
        return !!a.$goog_Thenable;
      } catch (b) {
        return !1;
      }
    }

    function He(a, b) {
      (this || _global).h = a;
      (this || _global).j = b;
      (this || _global).g = 0;
      (this || _global).a = null;
    }

    He.prototype.get = function () {
      if (0 < (this || _global).g) {
        (this || _global).g--;
        var a = (this || _global).a;
        (this || _global).a = a.next;
        a.next = null;
      } else a = this.h();

      return a;
    };

    function Ie(a, b) {
      a.j(b);
      100 > a.g && (a.g++, b.next = a.a, a.a = b);
    }

    function Je() {
      (this || _global).g = (this || _global).a = null;
    }

    var Le = new He(function () {
      return new Ke();
    }, function (a) {
      a.reset();
    });

    Je.prototype.add = function (a, b) {
      var c = Le.get();
      c.set(a, b);
      (this || _global).g ? (this || _global).g.next = c : (this || _global).a = c;
      (this || _global).g = c;
    };

    function Me() {
      var a = Ne,
          b = null;
      a.a && (b = a.a, a.a = a.a.next, a.a || (a.g = null), b.next = null);
      return b;
    }

    function Ke() {
      (this || _global).next = (this || _global).g = (this || _global).a = null;
    }

    Ke.prototype.set = function (a, b) {
      (this || _global).a = a;
      (this || _global).g = b;
      (this || _global).next = null;
    };

    Ke.prototype.reset = function () {
      (this || _global).next = (this || _global).g = (this || _global).a = null;
    };

    function Oe(a) {
      n.setTimeout(function () {
        throw a;
      }, 0);
    }

    var Pe;

    function Qe() {
      var a = n.MessageChannel;
      "undefined" === typeof a && "undefined" !== typeof window && window.postMessage && window.addEventListener && !y("Presto") && (a = function () {
        var e = document.createElement("IFRAME");
        e.style.display = "none";
        e.src = "";
        document.documentElement.appendChild(e);
        var f = e.contentWindow;
        e = f.document;
        e.open();
        e.write("");
        e.close();
        var g = "callImmediate" + Math.random(),
            h = "file:" == f.location.protocol ? "*" : f.location.protocol + "//" + f.location.host;
        e = t(function (k) {
          if (("*" == h || k.origin == h) && k.data == g) (this || _global).port1.onmessage();
        }, this || _global);
        f.addEventListener("message", e, !1);
        (this || _global).port1 = {};
        (this || _global).port2 = {
          postMessage: function () {
            f.postMessage(g, h);
          }
        };
      });

      if ("undefined" !== typeof a && !y("Trident") && !y("MSIE")) {
        var b = new a(),
            c = {},
            d = c;

        b.port1.onmessage = function () {
          if (ka(c.next)) {
            c = c.next;
            var e = c.gb;
            c.gb = null;
            e();
          }
        };

        return function (e) {
          d.next = {
            gb: e
          };
          d = d.next;
          b.port2.postMessage(0);
        };
      }

      return "undefined" !== typeof document && "onreadystatechange" in document.createElement("SCRIPT") ? function (e) {
        var f = document.createElement("SCRIPT");

        f.onreadystatechange = function () {
          f.onreadystatechange = null;
          f.parentNode.removeChild(f);
          f = null;
          e();
          e = null;
        };

        document.documentElement.appendChild(f);
      } : function (e) {
        n.setTimeout(e, 0);
      };
    }

    function Re(a, b) {
      Se || Te();
      Ue || (Se(), Ue = !0);
      Ne.add(a, b);
    }

    var Se;

    function Te() {
      if (n.Promise && n.Promise.resolve) {
        var a = n.Promise.resolve(void 0);

        Se = function () {
          a.then(Ve);
        };
      } else Se = function () {
        var b = Ve;
        !sa(n.setImmediate) || n.Window && n.Window.prototype && !y("Edge") && n.Window.prototype.setImmediate == n.setImmediate ? (Pe || (Pe = Qe()), Pe(b)) : n.setImmediate(b);
      };
    }

    var Ue = !1,
        Ne = new Je();

    function Ve() {
      for (var a; a = Me();) {
        try {
          a.a.call(a.g);
        } catch (b) {
          Oe(b);
        }

        Ie(Le, a);
      }

      Ue = !1;
    }

    function We(a) {
      (this || _global).a = Xe;
      (this || _global).A = void 0;
      (this || _global).j = (this || _global).g = (this || _global).h = null;
      (this || _global).s = (this || _global).i = !1;
      if (a != na) try {
        var b = this || _global;
        a.call(void 0, function (c) {
          Ye(b, Ze, c);
        }, function (c) {
          if (!(c instanceof $e)) try {
            if (c instanceof Error) throw c;
            throw Error("Promise rejected.");
          } catch (d) {}
          Ye(b, af, c);
        });
      } catch (c) {
        Ye(this || _global, af, c);
      }
    }

    var Xe = 0,
        Ze = 2,
        af = 3;

    function bf() {
      (this || _global).next = (this || _global).j = (this || _global).g = (this || _global).s = (this || _global).a = null;
      (this || _global).h = !1;
    }

    bf.prototype.reset = function () {
      (this || _global).j = (this || _global).g = (this || _global).s = (this || _global).a = null;
      (this || _global).h = !1;
    };

    var cf = new He(function () {
      return new bf();
    }, function (a) {
      a.reset();
    });

    function df(a, b, c) {
      var d = cf.get();
      d.s = a;
      d.g = b;
      d.j = c;
      return d;
    }

    function F(a) {
      if (a instanceof We) return a;
      var b = new We(na);
      Ye(b, Ze, a);
      return b;
    }

    function ef(a) {
      return new We(function (b, c) {
        c(a);
      });
    }

    We.prototype.then = function (a, b, c) {
      return ff(this || _global, sa(a) ? a : null, sa(b) ? b : null, c);
    };

    We.prototype.$goog_Thenable = !0;
    l = We.prototype;

    l.fc = function (a, b) {
      a = df(a, a, b);
      a.h = !0;
      gf(this || _global, a);
      return this || _global;
    };

    l.Ca = function (a, b) {
      return ff(this || _global, null, a, b);
    };

    l.cancel = function (a) {
      (this || _global).a == Xe && Re(function () {
        var b = new $e(a);
        hf(this || _global, b);
      }, this || _global);
    };

    function hf(a, b) {
      if (a.a == Xe) if (a.h) {
        var c = a.h;

        if (c.g) {
          for (var d = 0, e = null, f = null, g = c.g; g && (g.h || (d++, g.a == a && (e = g), !(e && 1 < d))); g = g.next) e || (f = g);

          e && (c.a == Xe && 1 == d ? hf(c, b) : (f ? (d = f, d.next == c.j && (c.j = d), d.next = d.next.next) : jf(c), kf(c, e, af, b)));
        }

        a.h = null;
      } else Ye(a, af, b);
    }

    function gf(a, b) {
      a.g || a.a != Ze && a.a != af || lf(a);
      a.j ? a.j.next = b : a.g = b;
      a.j = b;
    }

    function ff(a, b, c, d) {
      var e = df(null, null, null);
      e.a = new We(function (f, g) {
        e.s = b ? function (h) {
          try {
            var k = b.call(d, h);
            f(k);
          } catch (p) {
            g(p);
          }
        } : f;
        e.g = c ? function (h) {
          try {
            var k = c.call(d, h);
            !ka(k) && h instanceof $e ? g(h) : f(k);
          } catch (p) {
            g(p);
          }
        } : g;
      });
      e.a.h = a;
      gf(a, e);
      return e.a;
    }

    l.hc = function (a) {
      (this || _global).a = Xe;
      Ye(this || _global, Ze, a);
    };

    l.ic = function (a) {
      (this || _global).a = Xe;
      Ye(this || _global, af, a);
    };

    function Ye(a, b, c) {
      if (a.a == Xe) {
        a === c && (b = af, c = new TypeError("Promise cannot resolve to itself"));
        a.a = 1;

        a: {
          var d = c,
              e = a.hc,
              f = a.ic;

          if (d instanceof We) {
            gf(d, df(e || na, f || null, a));
            var g = !0;
          } else if (Ge(d)) d.then(e, f, a), g = !0;else {
            if (ta(d)) try {
              var h = d.then;

              if (sa(h)) {
                mf(d, h, e, f, a);
                g = !0;
                break a;
              }
            } catch (k) {
              f.call(a, k);
              g = !0;
              break a;
            }
            g = !1;
          }
        }

        g || (a.A = c, a.a = b, a.h = null, lf(a), b != af || c instanceof $e || nf(a, c));
      }
    }

    function mf(a, b, c, d, e) {
      function f(k) {
        h || (h = !0, d.call(e, k));
      }

      function g(k) {
        h || (h = !0, c.call(e, k));
      }

      var h = !1;

      try {
        b.call(a, g, f);
      } catch (k) {
        f(k);
      }
    }

    function lf(a) {
      a.i || (a.i = !0, Re(a.Hb, a));
    }

    function jf(a) {
      var b = null;
      a.g && (b = a.g, a.g = b.next, b.next = null);
      a.g || (a.j = null);
      return b;
    }

    l.Hb = function () {
      for (var a; a = jf(this || _global);) kf(this || _global, a, (this || _global).a, (this || _global).A);

      (this || _global).i = !1;
    };

    function kf(a, b, c, d) {
      if (c == af && b.g && !b.h) for (; a && a.s; a = a.h) a.s = !1;
      if (b.a) b.a.h = null, of(b, c, d);else try {
        b.h ? b.s.call(b.j) : of(b, c, d);
      } catch (e) {
        pf.call(null, e);
      }
      Ie(cf, b);
    }

    function of(a, b, c) {
      b == Ze ? a.s.call(a.j, c) : a.g && a.g.call(a.j, c);
    }

    function nf(a, b) {
      a.s = !0;
      Re(function () {
        a.s && pf.call(null, b);
      });
    }

    var pf = Oe;

    function $e(a) {
      Ba.call(this || _global, a);
    }

    w($e, Ba);
    $e.prototype.name = "cancel";

    function qf(a, b, c) {
      b || (b = {});
      c = c || window;
      var d = a instanceof yc ? a : Cc("undefined" != typeof a.href ? a.href : String(a));
      a = b.target || a.target;
      var e = [];

      for (f in b) switch (f) {
        case "width":
        case "height":
        case "top":
        case "left":
          e.push(f + "=" + b[f]);
          break;

        case "target":
        case "noopener":
        case "noreferrer":
          break;

        default:
          e.push(f + "=" + (b[f] ? 1 : 0));
      }

      var f = e.join(",");
      (y("iPhone") && !y("iPod") && !y("iPad") || y("iPad") || y("iPod")) && c.navigator && c.navigator.standalone && a && "_self" != a ? (f = c.document.createElement("A"), d = d instanceof yc ? d : Ec(d), f.href = Ac(d), f.setAttribute("target", a), b.noreferrer && f.setAttribute("rel", "noreferrer"), b = document.createEvent("MouseEvent"), b.initMouseEvent("click", !0, !0, c, 1), f.dispatchEvent(b), c = {}) : b.noreferrer ? (c = c.open("", a, f), b = Ac(d).toString(), c && (dc && -1 != b.indexOf(";") && (b = "'" + b.replace(/'/g, "%27") + "'"), c.opener = null, b = Kc("<meta name=\"referrer\" content=\"no-referrer\"><meta http-equiv=\"refresh\" content=\"0; url=" + db(b) + "\">", null), c.document.write(Jc(b)), c.document.close())) : (c = c.open(Ac(d).toString(), a, f)) && b.noopener && (c.opener = null);
      return c;
    }

    function rf() {
      try {
        return !(!window.opener || !window.opener.location || window.opener.location.hostname !== window.location.hostname || window.opener.location.protocol !== window.location.protocol);
      } catch (a$2) {}

      return !1;
    }

    function sf(a) {
      qf(a, {
        target: window.cordova && window.cordova.InAppBrowser ? "_system" : "_blank"
      }, void 0);
    }

    function tf(a, b) {
      a = ta(a) && 1 == a.nodeType ? a : document.querySelector(String(a));
      if (null == a) throw Error(b || "Cannot find element.");
      return a;
    }

    function uf() {
      return window.location.href;
    }

    function vf() {
      var a = null;
      return new We(function (b) {
        "complete" == n.document.readyState ? b() : (a = function () {
          b();
        }, me(window, "load", a));
      }).Ca(function (b) {
        te(window, "load", a);
        throw b;
      });
    }

    function wf() {
      for (var a = 32, b = []; 0 < a;) b.push("1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(Math.floor(62 * Math.random()))), a--;

      return b.join("");
    }

    function xf(a, b, c) {
      c = void 0 === c ? {} : c;
      return Object.keys(a).filter(function (d) {
        return b.includes(d);
      }).reduce(function (d, e) {
        d[e] = a[e];
        return d;
      }, c);
    }

    function yf(a) {
      var b = zf;
      (this || _global).s = [];
      (this || _global).T = b;
      (this || _global).O = a || null;
      (this || _global).j = (this || _global).a = !1;
      (this || _global).h = void 0;
      (this || _global).J = (this || _global).l = (this || _global).A = !1;
      (this || _global).i = 0;
      (this || _global).g = null;
      (this || _global).C = 0;
    }

    yf.prototype.cancel = function (a) {
      if ((this || _global).a) (this || _global).h instanceof yf && (this || _global).h.cancel();else {
        if ((this || _global).g) {
          var b = (this || _global).g;
          delete (this || _global).g;
          a ? b.cancel(a) : (b.C--, 0 >= b.C && b.cancel());
        }

        (this || _global).T ? (this || _global).T.call((this || _global).O, this || _global) : (this || _global).J = !0;
        (this || _global).a || (a = new Af(this || _global), Bf(this || _global), Cf(this || _global, !1, a));
      }
    };

    yf.prototype.L = function (a, b) {
      (this || _global).A = !1;
      Cf(this || _global, a, b);
    };

    function Cf(a, b, c) {
      a.a = !0;
      a.h = c;
      a.j = !b;
      Df(a);
    }

    function Bf(a) {
      if (a.a) {
        if (!a.J) throw new Ef(a);
        a.J = !1;
      }
    }

    yf.prototype.callback = function (a) {
      Bf(this || _global);
      Cf(this || _global, !0, a);
    };

    function Ff(a, b, c) {
      a.s.push([b, c, void 0]);
      a.a && Df(a);
    }

    yf.prototype.then = function (a, b, c) {
      var d,
          e,
          f = new We(function (g, h) {
        d = g;
        e = h;
      });
      Ff(this || _global, d, function (g) {
        g instanceof Af ? f.cancel() : e(g);
      });
      return f.then(a, b, c);
    };

    yf.prototype.$goog_Thenable = !0;

    function Gf(a) {
      return La(a.s, function (b) {
        return sa(b[1]);
      });
    }

    function Df(a) {
      if (a.i && a.a && Gf(a)) {
        var b = a.i,
            c = Hf[b];
        c && (n.clearTimeout(c.a), delete Hf[b]);
        a.i = 0;
      }

      a.g && (a.g.C--, delete a.g);
      b = a.h;

      for (var d = c = !1; a.s.length && !a.A;) {
        var e = a.s.shift(),
            f = e[0],
            g = e[1];
        e = e[2];
        if (f = a.j ? g : f) try {
          var h = f.call(e || a.O, b);
          ka(h) && (a.j = a.j && (h == b || h instanceof Error), a.h = b = h);
          if (Ge(b) || "function" === typeof n.Promise && b instanceof n.Promise) d = !0, a.A = !0;
        } catch (k) {
          b = k, a.j = !0, Gf(a) || (c = !0);
        }
      }

      a.h = b;
      d && (h = t(a.L, a, !0), d = t(a.L, a, !1), b instanceof yf ? (Ff(b, h, d), b.l = !0) : b.then(h, d));
      c && (b = new If(b), Hf[b.a] = b, a.i = b.a);
    }

    function Ef() {
      Ba.call(this || _global);
    }

    w(Ef, Ba);
    Ef.prototype.message = "Deferred has already fired";
    Ef.prototype.name = "AlreadyCalledError";

    function Af() {
      Ba.call(this || _global);
    }

    w(Af, Ba);
    Af.prototype.message = "Deferred was canceled";
    Af.prototype.name = "CanceledError";

    function If(a) {
      (this || _global).a = n.setTimeout(t((this || _global).h, this || _global), 0);
      (this || _global).g = a;
    }

    If.prototype.h = function () {
      delete Hf[(this || _global).a];
      throw (this || _global).g;
    };

    var Hf = {};

    function Jf(a) {
      var b = {},
          c = b.document || document,
          d = vc(a).toString(),
          e = document.createElement("SCRIPT"),
          f = {
        rb: e,
        sb: void 0
      },
          g = new yf(f),
          h = null,
          k = null != b.timeout ? b.timeout : 5000;
      0 < k && (h = window.setTimeout(function () {
        Kf(e, !0);
        var p = new Lf(Mf, "Timeout reached for loading script " + d);
        Bf(g);
        Cf(g, !1, p);
      }, k), f.sb = h);

      e.onload = e.onreadystatechange = function () {
        e.readyState && "loaded" != e.readyState && "complete" != e.readyState || (Kf(e, b.xc || !1, h), g.callback(null));
      };

      e.onerror = function () {
        Kf(e, !0, h);
        var p = new Lf(Nf, "Error while loading script " + d);
        Bf(g);
        Cf(g, !1, p);
      };

      f = b.attributes || {};
      hb(f, {
        type: "text/javascript",
        charset: "UTF-8"
      });
      Xc(e, f);
      Nc(e, a);
      Of(c).appendChild(e);
      return g;
    }

    function Of(a) {
      var b = (a || document).getElementsByTagName("HEAD");
      return b && 0 != b.length ? b[0] : a.documentElement;
    }

    function zf() {
      if ((this || _global) && (this || _global).rb) {
        var a = (this || _global).rb;
        a && "SCRIPT" == a.tagName && Kf(a, !0, (this || _global).sb);
      }
    }

    function Kf(a, b, c) {
      null != c && n.clearTimeout(c);
      a.onload = na;
      a.onerror = na;
      a.onreadystatechange = na;
      b && window.setTimeout(function () {
        $c(a);
      }, 0);
    }

    var Nf = 0,
        Mf = 1;

    function Lf(a, b) {
      var c = "Jsloader error (code #" + a + ")";
      b && (c += ": " + b);
      Ba.call(this || _global, c);
      (this || _global).code = a;
    }

    w(Lf, Ba);

    function Pf() {
      return n.google && n.google.accounts && n.google.accounts.id || null;
    }

    function Qf(a) {
      (this || _global).a = a || Pf();
      (this || _global).h = !1;
      (this || _global).g = null;
    }

    Qf.prototype.cancel = function () {
      (this || _global).a && (this || _global).h && ((this || _global).g && this.g(null), (this || _global).a.cancel());
    };

    function Rf(a, b, c) {
      if (a.a && b) return function () {
        a.h = !0;
        return new We(function (e) {
          a.g = e;
          a.a.initialize({
            client_id: b,
            callback: e,
            auto_select: !c
          });
          a.a.prompt();
        });
      }();

      if (b) {
        var d = Sf.Xa().load().then(function () {
          a.a = Pf();
          return Rf(a, b, c);
        }).Ca(function () {
          return null;
        });
        return F(d);
      }

      return F(null);
    }

    oa(Qf);
    var xc = new qc(rc, "https://accounts.google.com/gsi/client");

    function Sf() {
      (this || _global).a = null;
    }

    Sf.prototype.load = function () {
      var a = this || _global;
      if ((this || _global).a) return (this || _global).a;
      var b = wc();
      return Pf() ? F() : (this || _global).a = vf().then(function () {
        if (!Pf()) return new We(function (c, d) {
          var e = setTimeout(function () {
            a.a = null;
            d(Error("Network error!"));
          }, 10000);

          n.onGoogleLibraryLoad = function () {
            clearTimeout(e);
            c();
          };

          F(Jf(b)).then(function () {
            Pf() && c();
          }).Ca(function (f) {
            clearTimeout(e);
            a.a = null;
            d(f);
          });
        });
      });
    };

    oa(Sf);

    function Tf(a, b) {
      (this || _global).a = a;

      (this || _global).g = b || function (c) {
        throw c;
      };
    }

    Tf.prototype.confirm = function (a) {
      return F((this || _global).a.confirm(a)).Ca((this || _global).g);
    };

    function Uf(a, b, c) {
      this.reset(a, b, c, void 0, void 0);
    }

    Uf.prototype.a = null;
    var Vf = 0;

    Uf.prototype.reset = function (a, b, c, d, e) {
      "number" == typeof e || Vf++;
      (this || _global).h = d || Aa();
      (this || _global).j = a;
      (this || _global).s = b;
      (this || _global).g = c;
      delete (this || _global).a;
    };

    function Wf(a) {
      (this || _global).s = a;
      (this || _global).a = (this || _global).h = (this || _global).j = (this || _global).g = null;
    }

    function Xf(a, b) {
      (this || _global).name = a;
      (this || _global).value = b;
    }

    Xf.prototype.toString = function () {
      return (this || _global).name;
    };

    var Yf = new Xf("SEVERE", 1000),
        Zf = new Xf("WARNING", 900),
        $f = new Xf("CONFIG", 700);

    function ag(a) {
      if (a.j) return a.j;
      if (a.g) return ag(a.g);
      Fa("Root logger has no level set.");
      return null;
    }

    Wf.prototype.log = function (a, b, c) {
      if (a.value >= ag(this || _global).value) for (sa(b) && (b = b()), a = new Uf(a, String(b), (this || _global).s), c && (a.a = c), c = this || _global; c;) {
        var d = c,
            e = a;
        if (d.a) for (var f = 0; b = d.a[f]; f++) b(e);
        c = c.g;
      }
    };

    var bg = {},
        cg = null;

    function dg() {
      cg || (cg = new Wf(""), bg[""] = cg, cg.j = $f);
    }

    function eg(a) {
      dg();
      var b;

      if (!(b = bg[a])) {
        b = new Wf(a);
        var c = a.lastIndexOf("."),
            d = a.substr(c + 1);
        c = eg(a.substr(0, c));
        c.h || (c.h = {});
        c.h[d] = b;
        b.g = c;
        bg[a] = b;
      }

      return b;
    }

    function fg() {
      (this || _global).a = Aa();
    }

    var gg = null;

    fg.prototype.set = function (a) {
      (this || _global).a = a;
    };

    fg.prototype.reset = function () {
      this.set(Aa());
    };

    fg.prototype.get = function () {
      return (this || _global).a;
    };

    function hg(a) {
      (this || _global).j = a || "";
      gg || (gg = new fg());
      (this || _global).s = gg;
    }

    hg.prototype.a = !0;
    hg.prototype.g = !0;
    hg.prototype.h = !1;

    function ig(a) {
      return 10 > a ? "0" + a : String(a);
    }

    function jg(a, b) {
      a = (a.h - b) / 1000;
      b = a.toFixed(3);
      var c = 0;
      if (1 > a) c = 2;else for (; 100 > a;) c++, a *= 10;

      for (; 0 < c--;) b = " " + b;

      return b;
    }

    function kg(a) {
      hg.call(this || _global, a);
    }

    w(kg, hg);

    function lg(a, b) {
      var c = [];
      c.push(a.j, " ");

      if (a.g) {
        var d = new Date(b.h);
        c.push("[", ig(d.getFullYear() - 2000) + ig(d.getMonth() + 1) + ig(d.getDate()) + " " + ig(d.getHours()) + ":" + ig(d.getMinutes()) + ":" + ig(d.getSeconds()) + "." + ig(Math.floor(d.getMilliseconds() / 10)), "] ");
      }

      c.push("[", jg(b, a.s.get()), "s] ");
      c.push("[", b.g, "] ");
      c.push(b.s);
      a.h && (b = b.a) && c.push("\n", b instanceof Error ? b.message : b.toString());
      a.a && c.push("\n");
      return c.join("");
    }

    function mg() {
      (this || _global).s = t((this || _global).h, this || _global);
      (this || _global).a = new kg();
      (this || _global).a.g = !1;
      (this || _global).a.h = !1;
      (this || _global).g = (this || _global).a.a = !1;
      (this || _global).j = {};
    }

    mg.prototype.h = function (a) {
      function b(f) {
        if (f) {
          if (f.value >= Yf.value) return "error";
          if (f.value >= Zf.value) return "warn";
          if (f.value >= $f.value) return "log";
        }

        return "debug";
      }

      if (!(this || _global).j[a.g]) {
        var c = lg((this || _global).a, a),
            d = ng;

        if (d) {
          var e = b(a.j);
          og(d, e, c, a.a);
        }
      }
    };

    var ng = n.console;

    function og(a, b, c, d) {
      if (a[b]) a[b](c, d || "");else a.log(c, d || "");
    }

    function pg(a, b) {
      var c = qg;
      c && c.log(Yf, a, b);
    }

    var qg;
    qg = eg("firebaseui");
    var rg = new mg();

    if (1 != rg.g) {
      var sg;
      dg();
      sg = cg;
      var tg = rg.s;
      sg.a || (sg.a = []);
      sg.a.push(tg);
      rg.g = !0;
    }

    function ug(a) {
      var b = qg;
      b && b.log(Zf, a, void 0);
    }

    function vg() {
      (this || _global).a = ("undefined" == typeof document ? null : document) || {
        cookie: ""
      };
    }

    l = vg.prototype;

    l.set = function (a, b, c, d, e, f) {
      if (/[;=\s]/.test(a)) throw Error("Invalid cookie name \"" + a + "\"");
      if (/[;\r\n]/.test(b)) throw Error("Invalid cookie value \"" + b + "\"");
      ka(c) || (c = -1);
      e = e ? ";domain=" + e : "";
      d = d ? ";path=" + d : "";
      f = f ? ";secure" : "";
      c = 0 > c ? "" : 0 == c ? ";expires=" + new Date(1970, 1, 1).toUTCString() : ";expires=" + new Date(Aa() + 1000 * c).toUTCString();
      (this || _global).a.cookie = a + "=" + b + e + d + c + f;
    };

    l.get = function (a, b) {
      for (var c = a + "=", d = ((this || _global).a.cookie || "").split(";"), e = 0, f; e < d.length; e++) {
        f = Va(d[e]);
        if (0 == f.lastIndexOf(c, 0)) return f.substr(c.length);
        if (f == a) return "";
      }

      return b;
    };

    l.ja = function () {
      return wg(this || _global).keys;
    };

    l.la = function () {
      return wg(this || _global).values;
    };

    l.clear = function () {
      for (var a = wg(this || _global).keys, b = a.length - 1; 0 <= b; b--) {
        var c = a[b];
        this.get(c);
        this.set(c, "", 0, void 0, void 0);
      }
    };

    function wg(a) {
      a = (a.a.cookie || "").split(";");

      for (var b = [], c = [], d, e, f = 0; f < a.length; f++) e = Va(a[f]), d = e.indexOf("="), -1 == d ? (b.push(""), c.push(e)) : (b.push(e.substring(0, d)), c.push(e.substring(d + 1)));

      return {
        keys: b,
        values: c
      };
    }

    var yg = new vg();

    function zg() {}

    function Ag(a, b, c, d) {
      (this || _global).h = "undefined" !== typeof a && null !== a ? a : -1;
      (this || _global).g = b || null;
      (this || _global).a = c || null;
      (this || _global).j = !!d;
    }

    m(Ag, zg);

    Ag.prototype.set = function (a, b) {
      yg.set(a, b, (this || _global).h, (this || _global).g, (this || _global).a, (this || _global).j);
    };

    Ag.prototype.get = function (a) {
      return yg.get(a) || null;
    };

    Ag.prototype.ra = function (a) {
      var b = (this || _global).g,
          c = (this || _global).a;
      yg.get(a);
      yg.set(a, "", 0, b, c);
    };

    function Bg(a, b) {
      (this || _global).g = a;
      (this || _global).a = b || null;
    }

    function Cg(a) {
      return {
        email: a.g,
        credential: a.a && a.a.toJSON()
      };
    }

    function Dg(a) {
      if (a && a.email) {
        var b = a.credential && firebase.auth.AuthCredential.fromJSON(a.credential);
        return new Bg(a.email, b);
      }

      return null;
    }

    function Eg(a) {
      (this || _global).a = a || null;
    }

    function Fg(a) {
      for (var b = [], c = 0, d = 0; d < a.length; d++) {
        var e = a.charCodeAt(d);
        255 < e && (b[c++] = e & 255, e >>= 8);
        b[c++] = e;
      }

      return b;
    }

    function Gg(a) {
      return Ka(a, function (b) {
        b = b.toString(16);
        return 1 < b.length ? b : "0" + b;
      }).join("");
    }

    function Hg(a) {
      (this || _global).i = a;
      (this || _global).g = (this || _global).i.length / 4;
      (this || _global).j = (this || _global).g + 6;
      (this || _global).h = [[], [], [], []];
      (this || _global).s = [[], [], [], []];
      (this || _global).a = Array(Ig * ((this || _global).j + 1));

      for (a = 0; a < (this || _global).g; a++) (this || _global).a[a] = [(this || _global).i[4 * a], (this || _global).i[4 * a + 1], (this || _global).i[4 * a + 2], (this || _global).i[4 * a + 3]];

      var b = Array(4);

      for (a = (this || _global).g; a < Ig * ((this || _global).j + 1); a++) {
        b[0] = (this || _global).a[a - 1][0];
        b[1] = (this || _global).a[a - 1][1];
        b[2] = (this || _global).a[a - 1][2];
        b[3] = (this || _global).a[a - 1][3];

        if (0 == a % (this || _global).g) {
          var c = b,
              d = c[0];
          c[0] = c[1];
          c[1] = c[2];
          c[2] = c[3];
          c[3] = d;
          Jg(b);
          b[0] ^= Kg[a / (this || _global).g][0];
          b[1] ^= Kg[a / (this || _global).g][1];
          b[2] ^= Kg[a / (this || _global).g][2];
          b[3] ^= Kg[a / (this || _global).g][3];
        } else 6 < (this || _global).g && 4 == a % (this || _global).g && Jg(b);

        (this || _global).a[a] = Array(4);
        (this || _global).a[a][0] = (this || _global).a[a - (this || _global).g][0] ^ b[0];
        (this || _global).a[a][1] = (this || _global).a[a - (this || _global).g][1] ^ b[1];
        (this || _global).a[a][2] = (this || _global).a[a - (this || _global).g][2] ^ b[2];
        (this || _global).a[a][3] = (this || _global).a[a - (this || _global).g][3] ^ b[3];
      }
    }

    Hg.prototype.A = 16;
    var Ig = Hg.prototype.A / 4;

    function Lg(a, b) {
      for (var c, d = 0; d < Ig; d++) for (var e = 0; 4 > e; e++) c = 4 * e + d, c = b[c], a.h[d][e] = c;
    }

    function Mg(a) {
      for (var b = [], c = 0; c < Ig; c++) for (var d = 0; 4 > d; d++) b[4 * d + c] = a.h[c][d];

      return b;
    }

    function Ng(a, b) {
      for (var c = 0; 4 > c; c++) for (var d = 0; 4 > d; d++) a.h[c][d] ^= a.a[4 * b + d][c];
    }

    function Og(a, b) {
      for (var c = 0; 4 > c; c++) for (var d = 0; 4 > d; d++) a.h[c][d] = b[a.h[c][d]];
    }

    function Pg(a) {
      for (var b = 1; 4 > b; b++) for (var c = 0; 4 > c; c++) a.s[b][c] = a.h[b][c];

      for (b = 1; 4 > b; b++) for (c = 0; 4 > c; c++) a.h[b][c] = a.s[b][(c + b) % Ig];
    }

    function Qg(a) {
      for (var b = 1; 4 > b; b++) for (var c = 0; 4 > c; c++) a.s[b][(c + b) % Ig] = a.h[b][c];

      for (b = 1; 4 > b; b++) for (c = 0; 4 > c; c++) a.h[b][c] = a.s[b][c];
    }

    function Jg(a) {
      a[0] = Rg[a[0]];
      a[1] = Rg[a[1]];
      a[2] = Rg[a[2]];
      a[3] = Rg[a[3]];
    }

    var Rg = [99, 124, 119, 123, 242, 107, 111, 197, 48, 1, 103, 43, 254, 215, 171, 118, 202, 130, 201, 125, 250, 89, 71, 240, 173, 212, 162, 175, 156, 164, 114, 192, 183, 253, 147, 38, 54, 63, 247, 204, 52, 165, 229, 241, 113, 216, 49, 21, 4, 199, 35, 195, 24, 150, 5, 154, 7, 18, 128, 226, 235, 39, 178, 117, 9, 131, 44, 26, 27, 110, 90, 160, 82, 59, 214, 179, 41, 227, 47, 132, 83, 209, 0, 237, 32, 252, 177, 91, 106, 203, 190, 57, 74, 76, 88, 207, 208, 239, 170, 251, 67, 77, 51, 133, 69, 249, 2, 127, 80, 60, 159, 168, 81, 163, 64, 143, 146, 157, 56, 245, 188, 182, 218, 33, 16, 255, 243, 210, 205, 12, 19, 236, 95, 151, 68, 23, 196, 167, 126, 61, 100, 93, 25, 115, 96, 129, 79, 220, 34, 42, 144, 136, 70, 238, 184, 20, 222, 94, 11, 219, 224, 50, 58, 10, 73, 6, 36, 92, 194, 211, 172, 98, 145, 149, 228, 121, 231, 200, 55, 109, 141, 213, 78, 169, 108, 86, 244, 234, 101, 122, 174, 8, 186, 120, 37, 46, 28, 166, 180, 198, 232, 221, 116, 31, 75, 189, 139, 138, 112, 62, 181, 102, 72, 3, 246, 14, 97, 53, 87, 185, 134, 193, 29, 158, 225, 248, 152, 17, 105, 217, 142, 148, 155, 30, 135, 233, 206, 85, 40, 223, 140, 161, 137, 13, 191, 230, 66, 104, 65, 153, 45, 15, 176, 84, 187, 22],
        Sg = [82, 9, 106, 213, 48, 54, 165, 56, 191, 64, 163, 158, 129, 243, 215, 251, 124, 227, 57, 130, 155, 47, 255, 135, 52, 142, 67, 68, 196, 222, 233, 203, 84, 123, 148, 50, 166, 194, 35, 61, 238, 76, 149, 11, 66, 250, 195, 78, 8, 46, 161, 102, 40, 217, 36, 178, 118, 91, 162, 73, 109, 139, 209, 37, 114, 248, 246, 100, 134, 104, 152, 22, 212, 164, 92, 204, 93, 101, 182, 146, 108, 112, 72, 80, 253, 237, 185, 218, 94, 21, 70, 87, 167, 141, 157, 132, 144, 216, 171, 0, 140, 188, 211, 10, 247, 228, 88, 5, 184, 179, 69, 6, 208, 44, 30, 143, 202, 63, 15, 2, 193, 175, 189, 3, 1, 19, 138, 107, 58, 145, 17, 65, 79, 103, 220, 234, 151, 242, 207, 206, 240, 180, 230, 115, 150, 172, 116, 34, 231, 173, 53, 133, 226, 249, 55, 232, 28, 117, 223, 110, 71, 241, 26, 113, 29, 41, 197, 137, 111, 183, 98, 14, 170, 24, 190, 27, 252, 86, 62, 75, 198, 210, 121, 32, 154, 219, 192, 254, 120, 205, 90, 244, 31, 221, 168, 51, 136, 7, 199, 49, 177, 18, 16, 89, 39, 128, 236, 95, 96, 81, 127, 169, 25, 181, 74, 13, 45, 229, 122, 159, 147, 201, 156, 239, 160, 224, 59, 77, 174, 42, 245, 176, 200, 235, 187, 60, 131, 83, 153, 97, 23, 43, 4, 126, 186, 119, 214, 38, 225, 105, 20, 99, 85, 33, 12, 125],
        Kg = [[0, 0, 0, 0], [1, 0, 0, 0], [2, 0, 0, 0], [4, 0, 0, 0], [8, 0, 0, 0], [16, 0, 0, 0], [32, 0, 0, 0], [64, 0, 0, 0], [128, 0, 0, 0], [27, 0, 0, 0], [54, 0, 0, 0]],
        Tg = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66, 68, 70, 72, 74, 76, 78, 80, 82, 84, 86, 88, 90, 92, 94, 96, 98, 100, 102, 104, 106, 108, 110, 112, 114, 116, 118, 120, 122, 124, 126, 128, 130, 132, 134, 136, 138, 140, 142, 144, 146, 148, 150, 152, 154, 156, 158, 160, 162, 164, 166, 168, 170, 172, 174, 176, 178, 180, 182, 184, 186, 188, 190, 192, 194, 196, 198, 200, 202, 204, 206, 208, 210, 212, 214, 216, 218, 220, 222, 224, 226, 228, 230, 232, 234, 236, 238, 240, 242, 244, 246, 248, 250, 252, 254, 27, 25, 31, 29, 19, 17, 23, 21, 11, 9, 15, 13, 3, 1, 7, 5, 59, 57, 63, 61, 51, 49, 55, 53, 43, 41, 47, 45, 35, 33, 39, 37, 91, 89, 95, 93, 83, 81, 87, 85, 75, 73, 79, 77, 67, 65, 71, 69, 123, 121, 127, 125, 115, 113, 119, 117, 107, 105, 111, 109, 99, 97, 103, 101, 155, 153, 159, 157, 147, 145, 151, 149, 139, 137, 143, 141, 131, 129, 135, 133, 187, 185, 191, 189, 179, 177, 183, 181, 171, 169, 175, 173, 163, 161, 167, 165, 219, 217, 223, 221, 211, 209, 215, 213, 203, 201, 207, 205, 195, 193, 199, 197, 251, 249, 255, 253, 243, 241, 247, 245, 235, 233, 239, 237, 227, 225, 231, 229],
        Ug = [0, 3, 6, 5, 12, 15, 10, 9, 24, 27, 30, 29, 20, 23, 18, 17, 48, 51, 54, 53, 60, 63, 58, 57, 40, 43, 46, 45, 36, 39, 34, 33, 96, 99, 102, 101, 108, 111, 106, 105, 120, 123, 126, 125, 116, 119, 114, 113, 80, 83, 86, 85, 92, 95, 90, 89, 72, 75, 78, 77, 68, 71, 66, 65, 192, 195, 198, 197, 204, 207, 202, 201, 216, 219, 222, 221, 212, 215, 210, 209, 240, 243, 246, 245, 252, 255, 250, 249, 232, 235, 238, 237, 228, 231, 226, 225, 160, 163, 166, 165, 172, 175, 170, 169, 184, 187, 190, 189, 180, 183, 178, 177, 144, 147, 150, 149, 156, 159, 154, 153, 136, 139, 142, 141, 132, 135, 130, 129, 155, 152, 157, 158, 151, 148, 145, 146, 131, 128, 133, 134, 143, 140, 137, 138, 171, 168, 173, 174, 167, 164, 161, 162, 179, 176, 181, 182, 191, 188, 185, 186, 251, 248, 253, 254, 247, 244, 241, 242, 227, 224, 229, 230, 239, 236, 233, 234, 203, 200, 205, 206, 199, 196, 193, 194, 211, 208, 213, 214, 223, 220, 217, 218, 91, 88, 93, 94, 87, 84, 81, 82, 67, 64, 69, 70, 79, 76, 73, 74, 107, 104, 109, 110, 103, 100, 97, 98, 115, 112, 117, 118, 127, 124, 121, 122, 59, 56, 61, 62, 55, 52, 49, 50, 35, 32, 37, 38, 47, 44, 41, 42, 11, 8, 13, 14, 7, 4, 1, 2, 19, 16, 21, 22, 31, 28, 25, 26],
        Vg = [0, 9, 18, 27, 36, 45, 54, 63, 72, 65, 90, 83, 108, 101, 126, 119, 144, 153, 130, 139, 180, 189, 166, 175, 216, 209, 202, 195, 252, 245, 238, 231, 59, 50, 41, 32, 31, 22, 13, 4, 115, 122, 97, 104, 87, 94, 69, 76, 171, 162, 185, 176, 143, 134, 157, 148, 227, 234, 241, 248, 199, 206, 213, 220, 118, 127, 100, 109, 82, 91, 64, 73, 62, 55, 44, 37, 26, 19, 8, 1, 230, 239, 244, 253, 194, 203, 208, 217, 174, 167, 188, 181, 138, 131, 152, 145, 77, 68, 95, 86, 105, 96, 123, 114, 5, 12, 23, 30, 33, 40, 51, 58, 221, 212, 207, 198, 249, 240, 235, 226, 149, 156, 135, 142, 177, 184, 163, 170, 236, 229, 254, 247, 200, 193, 218, 211, 164, 173, 182, 191, 128, 137, 146, 155, 124, 117, 110, 103, 88, 81, 74, 67, 52, 61, 38, 47, 16, 25, 2, 11, 215, 222, 197, 204, 243, 250, 225, 232, 159, 150, 141, 132, 187, 178, 169, 160, 71, 78, 85, 92, 99, 106, 113, 120, 15, 6, 29, 20, 43, 34, 57, 48, 154, 147, 136, 129, 190, 183, 172, 165, 210, 219, 192, 201, 246, 255, 228, 237, 10, 3, 24, 17, 46, 39, 60, 53, 66, 75, 80, 89, 102, 111, 116, 125, 161, 168, 179, 186, 133, 140, 151, 158, 233, 224, 251, 242, 205, 196, 223, 214, 49, 56, 35, 42, 21, 28, 7, 14, 121, 112, 107, 98, 93, 84, 79, 70],
        Wg = [0, 11, 22, 29, 44, 39, 58, 49, 88, 83, 78, 69, 116, 127, 98, 105, 176, 187, 166, 173, 156, 151, 138, 129, 232, 227, 254, 245, 196, 207, 210, 217, 123, 112, 109, 102, 87, 92, 65, 74, 35, 40, 53, 62, 15, 4, 25, 18, 203, 192, 221, 214, 231, 236, 241, 250, 147, 152, 133, 142, 191, 180, 169, 162, 246, 253, 224, 235, 218, 209, 204, 199, 174, 165, 184, 179, 130, 137, 148, 159, 70, 77, 80, 91, 106, 97, 124, 119, 30, 21, 8, 3, 50, 57, 36, 47, 141, 134, 155, 144, 161, 170, 183, 188, 213, 222, 195, 200, 249, 242, 239, 228, 61, 54, 43, 32, 17, 26, 7, 12, 101, 110, 115, 120, 73, 66, 95, 84, 247, 252, 225, 234, 219, 208, 205, 198, 175, 164, 185, 178, 131, 136, 149, 158, 71, 76, 81, 90, 107, 96, 125, 118, 31, 20, 9, 2, 51, 56, 37, 46, 140, 135, 154, 145, 160, 171, 182, 189, 212, 223, 194, 201, 248, 243, 238, 229, 60, 55, 42, 33, 16, 27, 6, 13, 100, 111, 114, 121, 72, 67, 94, 85, 1, 10, 23, 28, 45, 38, 59, 48, 89, 82, 79, 68, 117, 126, 99, 104, 177, 186, 167, 172, 157, 150, 139, 128, 233, 226, 255, 244, 197, 206, 211, 216, 122, 113, 108, 103, 86, 93, 64, 75, 34, 41, 52, 63, 14, 5, 24, 19, 202, 193, 220, 215, 230, 237, 240, 251, 146, 153, 132, 143, 190, 181, 168, 163],
        Xg = [0, 13, 26, 23, 52, 57, 46, 35, 104, 101, 114, 127, 92, 81, 70, 75, 208, 221, 202, 199, 228, 233, 254, 243, 184, 181, 162, 175, 140, 129, 150, 155, 187, 182, 161, 172, 143, 130, 149, 152, 211, 222, 201, 196, 231, 234, 253, 240, 107, 102, 113, 124, 95, 82, 69, 72, 3, 14, 25, 20, 55, 58, 45, 32, 109, 96, 119, 122, 89, 84, 67, 78, 5, 8, 31, 18, 49, 60, 43, 38, 189, 176, 167, 170, 137, 132, 147, 158, 213, 216, 207, 194, 225, 236, 251, 246, 214, 219, 204, 193, 226, 239, 248, 245, 190, 179, 164, 169, 138, 135, 144, 157, 6, 11, 28, 17, 50, 63, 40, 37, 110, 99, 116, 121, 90, 87, 64, 77, 218, 215, 192, 205, 238, 227, 244, 249, 178, 191, 168, 165, 134, 139, 156, 145, 10, 7, 16, 29, 62, 51, 36, 41, 98, 111, 120, 117, 86, 91, 76, 65, 97, 108, 123, 118, 85, 88, 79, 66, 9, 4, 19, 30, 61, 48, 39, 42, 177, 188, 171, 166, 133, 136, 159, 146, 217, 212, 195, 206, 237, 224, 247, 250, 183, 186, 173, 160, 131, 142, 153, 148, 223, 210, 197, 200, 235, 230, 241, 252, 103, 106, 125, 112, 83, 94, 73, 68, 15, 2, 21, 24, 59, 54, 33, 44, 12, 1, 22, 27, 56, 53, 34, 47, 100, 105, 126, 115, 80, 93, 74, 71, 220, 209, 198, 203, 232, 229, 242, 255, 180, 185, 174, 163, 128, 141, 154, 151],
        Yg = [0, 14, 28, 18, 56, 54, 36, 42, 112, 126, 108, 98, 72, 70, 84, 90, 224, 238, 252, 242, 216, 214, 196, 202, 144, 158, 140, 130, 168, 166, 180, 186, 219, 213, 199, 201, 227, 237, 255, 241, 171, 165, 183, 185, 147, 157, 143, 129, 59, 53, 39, 41, 3, 13, 31, 17, 75, 69, 87, 89, 115, 125, 111, 97, 173, 163, 177, 191, 149, 155, 137, 135, 221, 211, 193, 207, 229, 235, 249, 247, 77, 67, 81, 95, 117, 123, 105, 103, 61, 51, 33, 47, 5, 11, 25, 23, 118, 120, 106, 100, 78, 64, 82, 92, 6, 8, 26, 20, 62, 48, 34, 44, 150, 152, 138, 132, 174, 160, 178, 188, 230, 232, 250, 244, 222, 208, 194, 204, 65, 79, 93, 83, 121, 119, 101, 107, 49, 63, 45, 35, 9, 7, 21, 27, 161, 175, 189, 179, 153, 151, 133, 139, 209, 223, 205, 195, 233, 231, 245, 251, 154, 148, 134, 136, 162, 172, 190, 176, 234, 228, 246, 248, 210, 220, 206, 192, 122, 116, 102, 104, 66, 76, 94, 80, 10, 4, 22, 24, 50, 60, 46, 32, 236, 226, 240, 254, 212, 218, 200, 198, 156, 146, 128, 142, 164, 170, 184, 182, 12, 2, 16, 30, 52, 58, 40, 38, 124, 114, 96, 110, 68, 74, 88, 86, 55, 57, 43, 37, 15, 1, 19, 29, 71, 73, 91, 85, 127, 113, 99, 109, 215, 217, 203, 197, 239, 225, 243, 253, 167, 169, 187, 181, 159, 145, 131, 141];

    function Zg(a, b) {
      a = new Hg($g(a));
      b = Fg(b);

      for (var c = Ta(b, 0, 16), d = "", e; c.length;) {
        e = 16 - c.length;

        for (var f = 0; f < e; f++) c.push(0);

        e = a;
        Lg(e, c);
        Ng(e, 0);

        for (c = 1; c < e.j; ++c) {
          Og(e, Rg);
          Pg(e);
          f = e.h;

          for (var g = e.s[0], h = 0; 4 > h; h++) g[0] = f[0][h], g[1] = f[1][h], g[2] = f[2][h], g[3] = f[3][h], f[0][h] = Tg[g[0]] ^ Ug[g[1]] ^ g[2] ^ g[3], f[1][h] = g[0] ^ Tg[g[1]] ^ Ug[g[2]] ^ g[3], f[2][h] = g[0] ^ g[1] ^ Tg[g[2]] ^ Ug[g[3]], f[3][h] = Ug[g[0]] ^ g[1] ^ g[2] ^ Tg[g[3]];

          Ng(e, c);
        }

        Og(e, Rg);
        Pg(e);
        Ng(e, e.j);
        d += Gg(Mg(e));
        c = Ta(b, 0, 16);
      }

      return d;
    }

    function ah(a, b) {
      a = new Hg($g(a));

      for (var c = [], d = 0; d < b.length; d += 2) c.push(parseInt(b.substring(d, d + 2), 16));

      var e = Ta(c, 0, 16);

      for (b = ""; e.length;) {
        d = a;
        Lg(d, e);
        Ng(d, d.j);

        for (e = 1; e < d.j; ++e) {
          Qg(d);
          Og(d, Sg);
          Ng(d, d.j - e);

          for (var f = d.h, g = d.s[0], h = 0; 4 > h; h++) g[0] = f[0][h], g[1] = f[1][h], g[2] = f[2][h], g[3] = f[3][h], f[0][h] = Yg[g[0]] ^ Wg[g[1]] ^ Xg[g[2]] ^ Vg[g[3]], f[1][h] = Vg[g[0]] ^ Yg[g[1]] ^ Wg[g[2]] ^ Xg[g[3]], f[2][h] = Xg[g[0]] ^ Vg[g[1]] ^ Yg[g[2]] ^ Wg[g[3]], f[3][h] = Wg[g[0]] ^ Xg[g[1]] ^ Vg[g[2]] ^ Yg[g[3]];
        }

        Qg(d);
        Og(d, Sg);
        Ng(d, 0);
        d = Mg(d);
        if (8192 >= d.length) d = String.fromCharCode.apply(null, d);else {
          e = "";

          for (f = 0; f < d.length; f += 8192) e += String.fromCharCode.apply(null, Ua(d, f, f + 8192));

          d = e;
        }
        b += d;
        e = Ta(c, 0, 16);
      }

      return b.replace(/(\x00)+$/, "");
    }

    function $g(a) {
      a = Fg(a.substring(0, 32));

      for (var b = 32 - a.length, c = 0; c < b; c++) a.push(0);

      return a;
    }

    function bh(a) {
      var b = [];
      ch(new dh(), a, b);
      return b.join("");
    }

    function dh() {}

    function ch(a, b, c) {
      if (null == b) c.push("null");else {
        if ("object" == typeof b) {
          if (qa(b)) {
            var d = b;
            b = d.length;
            c.push("[");

            for (var e = "", f = 0; f < b; f++) c.push(e), ch(a, d[f], c), e = ",";

            c.push("]");
            return;
          }

          if (b instanceof String || b instanceof Number || b instanceof Boolean) b = b.valueOf();else {
            c.push("{");
            e = "";

            for (d in b) Object.prototype.hasOwnProperty.call(b, d) && (f = b[d], "function" != typeof f && (c.push(e), eh(d, c), c.push(":"), ch(a, f, c), e = ","));

            c.push("}");
            return;
          }
        }

        switch (typeof b) {
          case "string":
            eh(b, c);
            break;

          case "number":
            c.push(isFinite(b) && !isNaN(b) ? String(b) : "null");
            break;

          case "boolean":
            c.push(String(b));
            break;

          case "function":
            c.push("null");
            break;

          default:
            throw Error("Unknown type: " + typeof b);
        }
      }
    }

    var fh = {
      "\"": "\\\"",
      "\\": "\\\\",
      "/": "\\/",
      "\b": "\\b",
      "\f": "\\f",
      "\n": "\\n",
      "\r": "\\r",
      "\t": "\\t",
      "\x0B": "\\u000b"
    },
        gh = /\uffff/.test("\uFFFF") ? /[\\"\x00-\x1f\x7f-\uffff]/g : /[\\"\x00-\x1f\x7f-\xff]/g;

    function eh(a, b) {
      b.push("\"", a.replace(gh, function (c) {
        var d = fh[c];
        d || (d = "\\u" + (c.charCodeAt(0) | 65536).toString(16).substr(1), fh[c] = d);
        return d;
      }), "\"");
    }

    function hh(a) {
      (this || _global).a = a;
    }

    hh.prototype.set = function (a, b) {
      ka(b) ? (this || _global).a.set(a, bh(b)) : (this || _global).a.ra(a);
    };

    hh.prototype.get = function (a) {
      try {
        var b = (this || _global).a.get(a);
      } catch (c) {
        return;
      }

      if (null !== b) try {
        return JSON.parse(b);
      } catch (c$3) {
        throw "Storage: Invalid value was encountered";
      }
    };

    function ih() {}

    w(ih, zg);

    ih.prototype.clear = function () {
      var a = mb(this.ha(!0)),
          b = this || _global;
      Ha(a, function (c) {
        b.ra(c);
      });
    };

    function jh(a) {
      (this || _global).a = a;
    }

    w(jh, ih);

    function kh(a) {
      if (!a.a) return !1;

      try {
        return a.a.setItem("__sak", "1"), a.a.removeItem("__sak"), !0;
      } catch (b) {
        return !1;
      }
    }

    l = jh.prototype;

    l.set = function (a, b) {
      try {
        (this || _global).a.setItem(a, b);
      } catch (c) {
        if (0 == (this || _global).a.length) throw "Storage mechanism: Storage disabled";
        throw "Storage mechanism: Quota exceeded";
      }
    };

    l.get = function (a) {
      a = (this || _global).a.getItem(a);
      if (!q(a) && null !== a) throw "Storage mechanism: Invalid value was encountered";
      return a;
    };

    l.ra = function (a) {
      (this || _global).a.removeItem(a);
    };

    l.ha = function (a) {
      var b = 0,
          c = (this || _global).a,
          d = new jb();

      d.next = function () {
        if (b >= c.length) throw ib;
        var e = c.key(b++);
        if (a) return e;
        e = c.getItem(e);
        if (!q(e)) throw "Storage mechanism: Invalid value was encountered";
        return e;
      };

      return d;
    };

    l.clear = function () {
      (this || _global).a.clear();
    };

    l.key = function (a) {
      return (this || _global).a.key(a);
    };

    function lh() {
      var a = null;

      try {
        a = window.localStorage || null;
      } catch (b) {}

      (this || _global).a = a;
    }

    w(lh, jh);

    function mh() {
      var a = null;

      try {
        a = window.sessionStorage || null;
      } catch (b) {}

      (this || _global).a = a;
    }

    w(mh, jh);

    function nh(a, b) {
      (this || _global).g = a;
      (this || _global).a = b + "::";
    }

    w(nh, ih);

    nh.prototype.set = function (a, b) {
      (this || _global).g.set((this || _global).a + a, b);
    };

    nh.prototype.get = function (a) {
      return (this || _global).g.get((this || _global).a + a);
    };

    nh.prototype.ra = function (a) {
      (this || _global).g.ra((this || _global).a + a);
    };

    nh.prototype.ha = function (a) {
      var b = (this || _global).g.ha(!0),
          c = this || _global,
          d = new jb();

      d.next = function () {
        for (var e = b.next(); e.substr(0, c.a.length) != c.a;) e = b.next();

        return a ? e.substr(c.a.length) : c.g.get(e);
      };

      return d;
    };

    kh(new lh());
    var oh,
        ph = new mh();
    oh = kh(ph) ? new nh(ph, "firebaseui") : null;
    var qh = new hh(oh),
        rh = {
      name: "pendingEmailCredential",
      storage: qh
    },
        sh = {
      name: "redirectStatus",
      storage: qh
    },
        th = {
      name: "redirectUrl",
      storage: qh
    },
        uh = {
      name: "emailForSignIn",
      storage: new hh(new Ag(3600, "/"))
    },
        vh = {
      name: "pendingEncryptedCredential",
      storage: new hh(new Ag(3600, "/"))
    };

    function wh(a, b) {
      return a.storage.get(b ? a.name + ":" + b : a.name);
    }

    function xh(a, b) {
      a.storage.a.ra(b ? a.name + ":" + b : a.name);
    }

    function yh(a, b, c) {
      a.storage.set(c ? a.name + ":" + c : a.name, b);
    }

    function zh(a) {
      return wh(th, a) || null;
    }

    function Ah(a) {
      a = wh(rh, a) || null;
      return Dg(a);
    }

    function Bh(a) {
      xh(rh, a);
    }

    function Ch(a, b) {
      yh(rh, Cg(a), b);
    }

    function Dh(a) {
      return (a = wh(sh, a) || null) && "undefined" !== typeof a.tenantId ? new Eg(a.tenantId) : null;
    }

    function Eh(a, b) {
      yh(sh, {
        tenantId: a.a
      }, b);
    }

    function Fh(a, b) {
      b = wh(uh, b);
      var c = null;
      if (b) try {
        var d = ah(a, b),
            e = JSON.parse(d);
        c = e && e.email || null;
      } catch (f) {}
      return c;
    }

    function Gh(a, b) {
      b = wh(vh, b);
      var c = null;
      if (b) try {
        var d = ah(a, b);
        c = JSON.parse(d);
      } catch (e) {}
      return Dg(c || null);
    }

    function Hh(a, b, c) {
      yh(vh, Zg(a, JSON.stringify(Cg(b))), c);
    }

    function Ih() {
      (this || _global).W = {};
    }

    function G(a, b, c) {
      if (b.toLowerCase() in a.W) throw Error("Configuration " + b + " has already been defined.");
      a.W[b.toLowerCase()] = c;
    }

    function Jh(a, b, c) {
      if (!(b.toLowerCase() in a.W)) throw Error("Configuration " + b + " is not defined.");
      a.W[b.toLowerCase()] = c;
    }

    Ih.prototype.get = function (a) {
      if (!(a.toLowerCase() in (this || _global).W)) throw Error("Configuration " + a + " is not defined.");
      return (this || _global).W[a.toLowerCase()];
    };

    function Kh(a, b) {
      a = a.get(b);
      if (!a) throw Error("Configuration " + b + " is required.");
      return a;
    }

    function Lh() {
      (this || _global).g = void 0;
      (this || _global).a = {};
    }

    l = Lh.prototype;

    l.set = function (a, b) {
      Mh(this || _global, a, b, !1);
    };

    l.add = function (a, b) {
      Mh(this || _global, a, b, !0);
    };

    function Mh(a, b, c, d) {
      for (var e = 0; e < b.length; e++) {
        var f = b.charAt(e);
        a.a[f] || (a.a[f] = new Lh());
        a = a.a[f];
      }

      if (d && void 0 !== a.g) throw Error("The collection already contains the key \"" + b + "\"");
      a.g = c;
    }

    l.get = function (a) {
      a: {
        for (var b = this || _global, c = 0; c < a.length; c++) if (b = b.a[a.charAt(c)], !b) {
          a = void 0;
          break a;
        }

        a = b;
      }

      return a ? a.g : void 0;
    };

    l.la = function () {
      var a = [];
      Nh(this || _global, a);
      return a;
    };

    function Nh(a, b) {
      void 0 !== a.g && b.push(a.g);

      for (var c in a.a) Nh(a.a[c], b);
    }

    l.ja = function () {
      var a = [];
      Oh(this || _global, "", a);
      return a;
    };

    function Oh(a, b, c) {
      void 0 !== a.g && c.push(b);

      for (var d in a.a) Oh(a.a[d], b + d, c);
    }

    l.clear = function () {
      (this || _global).a = {};
      (this || _global).g = void 0;
    };

    function Ph(a) {
      (this || _global).a = a;
      (this || _global).g = new Lh();

      for (a = 0; a < (this || _global).a.length; a++) {
        var b = (this || _global).g.get("+" + (this || _global).a[a].b);

        b ? b.push((this || _global).a[a]) : (this || _global).g.add("+" + (this || _global).a[a].b, [(this || _global).a[a]]);
      }
    }

    function Qh(a, b) {
      a = a.g;
      var c = {},
          d = 0;
      void 0 !== a.g && (c[d] = a.g);

      for (; d < b.length; d++) {
        var e = b.charAt(d);
        if (!(e in a.a)) break;
        a = a.a[e];
        void 0 !== a.g && (c[d] = a.g);
      }

      for (var f in c) if (c.hasOwnProperty(f)) return c[f];

      return [];
    }

    function Rh(a) {
      for (var b = 0; b < Sh.length; b++) if (Sh[b].c === a) return Sh[b];

      return null;
    }

    function Th(a) {
      a = a.toUpperCase();

      for (var b = [], c = 0; c < Sh.length; c++) Sh[c].f === a && b.push(Sh[c]);

      return b;
    }

    function Uh(a) {
      if (0 < a.length && "+" == a.charAt(0)) {
        a = a.substring(1);

        for (var b = [], c = 0; c < Sh.length; c++) Sh[c].b == a && b.push(Sh[c]);

        a = b;
      } else a = Th(a);

      return a;
    }

    function Vh(a) {
      a.sort(function (b, c) {
        return b.name.localeCompare(c.name, "en");
      });
    }

    var Sh = [{
      name: "Afghanistan",
      c: "93-AF-0",
      b: "93",
      f: "AF"
    }, {
      name: "\xC5land Islands",
      c: "358-AX-0",
      b: "358",
      f: "AX"
    }, {
      name: "Albania",
      c: "355-AL-0",
      b: "355",
      f: "AL"
    }, {
      name: "Algeria",
      c: "213-DZ-0",
      b: "213",
      f: "DZ"
    }, {
      name: "American Samoa",
      c: "1-AS-0",
      b: "1",
      f: "AS"
    }, {
      name: "Andorra",
      c: "376-AD-0",
      b: "376",
      f: "AD"
    }, {
      name: "Angola",
      c: "244-AO-0",
      b: "244",
      f: "AO"
    }, {
      name: "Anguilla",
      c: "1-AI-0",
      b: "1",
      f: "AI"
    }, {
      name: "Antigua and Barbuda",
      c: "1-AG-0",
      b: "1",
      f: "AG"
    }, {
      name: "Argentina",
      c: "54-AR-0",
      b: "54",
      f: "AR"
    }, {
      name: "Armenia",
      c: "374-AM-0",
      b: "374",
      f: "AM"
    }, {
      name: "Aruba",
      c: "297-AW-0",
      b: "297",
      f: "AW"
    }, {
      name: "Ascension Island",
      c: "247-AC-0",
      b: "247",
      f: "AC"
    }, {
      name: "Australia",
      c: "61-AU-0",
      b: "61",
      f: "AU"
    }, {
      name: "Austria",
      c: "43-AT-0",
      b: "43",
      f: "AT"
    }, {
      name: "Azerbaijan",
      c: "994-AZ-0",
      b: "994",
      f: "AZ"
    }, {
      name: "Bahamas",
      c: "1-BS-0",
      b: "1",
      f: "BS"
    }, {
      name: "Bahrain",
      c: "973-BH-0",
      b: "973",
      f: "BH"
    }, {
      name: "Bangladesh",
      c: "880-BD-0",
      b: "880",
      f: "BD"
    }, {
      name: "Barbados",
      c: "1-BB-0",
      b: "1",
      f: "BB"
    }, {
      name: "Belarus",
      c: "375-BY-0",
      b: "375",
      f: "BY"
    }, {
      name: "Belgium",
      c: "32-BE-0",
      b: "32",
      f: "BE"
    }, {
      name: "Belize",
      c: "501-BZ-0",
      b: "501",
      f: "BZ"
    }, {
      name: "Benin",
      c: "229-BJ-0",
      b: "229",
      f: "BJ"
    }, {
      name: "Bermuda",
      c: "1-BM-0",
      b: "1",
      f: "BM"
    }, {
      name: "Bhutan",
      c: "975-BT-0",
      b: "975",
      f: "BT"
    }, {
      name: "Bolivia",
      c: "591-BO-0",
      b: "591",
      f: "BO"
    }, {
      name: "Bosnia and Herzegovina",
      c: "387-BA-0",
      b: "387",
      f: "BA"
    }, {
      name: "Botswana",
      c: "267-BW-0",
      b: "267",
      f: "BW"
    }, {
      name: "Brazil",
      c: "55-BR-0",
      b: "55",
      f: "BR"
    }, {
      name: "British Indian Ocean Territory",
      c: "246-IO-0",
      b: "246",
      f: "IO"
    }, {
      name: "British Virgin Islands",
      c: "1-VG-0",
      b: "1",
      f: "VG"
    }, {
      name: "Brunei",
      c: "673-BN-0",
      b: "673",
      f: "BN"
    }, {
      name: "Bulgaria",
      c: "359-BG-0",
      b: "359",
      f: "BG"
    }, {
      name: "Burkina Faso",
      c: "226-BF-0",
      b: "226",
      f: "BF"
    }, {
      name: "Burundi",
      c: "257-BI-0",
      b: "257",
      f: "BI"
    }, {
      name: "Cambodia",
      c: "855-KH-0",
      b: "855",
      f: "KH"
    }, {
      name: "Cameroon",
      c: "237-CM-0",
      b: "237",
      f: "CM"
    }, {
      name: "Canada",
      c: "1-CA-0",
      b: "1",
      f: "CA"
    }, {
      name: "Cape Verde",
      c: "238-CV-0",
      b: "238",
      f: "CV"
    }, {
      name: "Caribbean Netherlands",
      c: "599-BQ-0",
      b: "599",
      f: "BQ"
    }, {
      name: "Cayman Islands",
      c: "1-KY-0",
      b: "1",
      f: "KY"
    }, {
      name: "Central African Republic",
      c: "236-CF-0",
      b: "236",
      f: "CF"
    }, {
      name: "Chad",
      c: "235-TD-0",
      b: "235",
      f: "TD"
    }, {
      name: "Chile",
      c: "56-CL-0",
      b: "56",
      f: "CL"
    }, {
      name: "China",
      c: "86-CN-0",
      b: "86",
      f: "CN"
    }, {
      name: "Christmas Island",
      c: "61-CX-0",
      b: "61",
      f: "CX"
    }, {
      name: "Cocos [Keeling] Islands",
      c: "61-CC-0",
      b: "61",
      f: "CC"
    }, {
      name: "Colombia",
      c: "57-CO-0",
      b: "57",
      f: "CO"
    }, {
      name: "Comoros",
      c: "269-KM-0",
      b: "269",
      f: "KM"
    }, {
      name: "Democratic Republic Congo",
      c: "243-CD-0",
      b: "243",
      f: "CD"
    }, {
      name: "Republic of Congo",
      c: "242-CG-0",
      b: "242",
      f: "CG"
    }, {
      name: "Cook Islands",
      c: "682-CK-0",
      b: "682",
      f: "CK"
    }, {
      name: "Costa Rica",
      c: "506-CR-0",
      b: "506",
      f: "CR"
    }, {
      name: "C\xF4te d'Ivoire",
      c: "225-CI-0",
      b: "225",
      f: "CI"
    }, {
      name: "Croatia",
      c: "385-HR-0",
      b: "385",
      f: "HR"
    }, {
      name: "Cuba",
      c: "53-CU-0",
      b: "53",
      f: "CU"
    }, {
      name: "Cura\xE7ao",
      c: "599-CW-0",
      b: "599",
      f: "CW"
    }, {
      name: "Cyprus",
      c: "357-CY-0",
      b: "357",
      f: "CY"
    }, {
      name: "Czech Republic",
      c: "420-CZ-0",
      b: "420",
      f: "CZ"
    }, {
      name: "Denmark",
      c: "45-DK-0",
      b: "45",
      f: "DK"
    }, {
      name: "Djibouti",
      c: "253-DJ-0",
      b: "253",
      f: "DJ"
    }, {
      name: "Dominica",
      c: "1-DM-0",
      b: "1",
      f: "DM"
    }, {
      name: "Dominican Republic",
      c: "1-DO-0",
      b: "1",
      f: "DO"
    }, {
      name: "East Timor",
      c: "670-TL-0",
      b: "670",
      f: "TL"
    }, {
      name: "Ecuador",
      c: "593-EC-0",
      b: "593",
      f: "EC"
    }, {
      name: "Egypt",
      c: "20-EG-0",
      b: "20",
      f: "EG"
    }, {
      name: "El Salvador",
      c: "503-SV-0",
      b: "503",
      f: "SV"
    }, {
      name: "Equatorial Guinea",
      c: "240-GQ-0",
      b: "240",
      f: "GQ"
    }, {
      name: "Eritrea",
      c: "291-ER-0",
      b: "291",
      f: "ER"
    }, {
      name: "Estonia",
      c: "372-EE-0",
      b: "372",
      f: "EE"
    }, {
      name: "Ethiopia",
      c: "251-ET-0",
      b: "251",
      f: "ET"
    }, {
      name: "Falkland Islands [Islas Malvinas]",
      c: "500-FK-0",
      b: "500",
      f: "FK"
    }, {
      name: "Faroe Islands",
      c: "298-FO-0",
      b: "298",
      f: "FO"
    }, {
      name: "Fiji",
      c: "679-FJ-0",
      b: "679",
      f: "FJ"
    }, {
      name: "Finland",
      c: "358-FI-0",
      b: "358",
      f: "FI"
    }, {
      name: "France",
      c: "33-FR-0",
      b: "33",
      f: "FR"
    }, {
      name: "French Guiana",
      c: "594-GF-0",
      b: "594",
      f: "GF"
    }, {
      name: "French Polynesia",
      c: "689-PF-0",
      b: "689",
      f: "PF"
    }, {
      name: "Gabon",
      c: "241-GA-0",
      b: "241",
      f: "GA"
    }, {
      name: "Gambia",
      c: "220-GM-0",
      b: "220",
      f: "GM"
    }, {
      name: "Georgia",
      c: "995-GE-0",
      b: "995",
      f: "GE"
    }, {
      name: "Germany",
      c: "49-DE-0",
      b: "49",
      f: "DE"
    }, {
      name: "Ghana",
      c: "233-GH-0",
      b: "233",
      f: "GH"
    }, {
      name: "Gibraltar",
      c: "350-GI-0",
      b: "350",
      f: "GI"
    }, {
      name: "Greece",
      c: "30-GR-0",
      b: "30",
      f: "GR"
    }, {
      name: "Greenland",
      c: "299-GL-0",
      b: "299",
      f: "GL"
    }, {
      name: "Grenada",
      c: "1-GD-0",
      b: "1",
      f: "GD"
    }, {
      name: "Guadeloupe",
      c: "590-GP-0",
      b: "590",
      f: "GP"
    }, {
      name: "Guam",
      c: "1-GU-0",
      b: "1",
      f: "GU"
    }, {
      name: "Guatemala",
      c: "502-GT-0",
      b: "502",
      f: "GT"
    }, {
      name: "Guernsey",
      c: "44-GG-0",
      b: "44",
      f: "GG"
    }, {
      name: "Guinea Conakry",
      c: "224-GN-0",
      b: "224",
      f: "GN"
    }, {
      name: "Guinea-Bissau",
      c: "245-GW-0",
      b: "245",
      f: "GW"
    }, {
      name: "Guyana",
      c: "592-GY-0",
      b: "592",
      f: "GY"
    }, {
      name: "Haiti",
      c: "509-HT-0",
      b: "509",
      f: "HT"
    }, {
      name: "Heard Island and McDonald Islands",
      c: "672-HM-0",
      b: "672",
      f: "HM"
    }, {
      name: "Honduras",
      c: "504-HN-0",
      b: "504",
      f: "HN"
    }, {
      name: "Hong Kong",
      c: "852-HK-0",
      b: "852",
      f: "HK"
    }, {
      name: "Hungary",
      c: "36-HU-0",
      b: "36",
      f: "HU"
    }, {
      name: "Iceland",
      c: "354-IS-0",
      b: "354",
      f: "IS"
    }, {
      name: "India",
      c: "91-IN-0",
      b: "91",
      f: "IN"
    }, {
      name: "Indonesia",
      c: "62-ID-0",
      b: "62",
      f: "ID"
    }, {
      name: "Iran",
      c: "98-IR-0",
      b: "98",
      f: "IR"
    }, {
      name: "Iraq",
      c: "964-IQ-0",
      b: "964",
      f: "IQ"
    }, {
      name: "Ireland",
      c: "353-IE-0",
      b: "353",
      f: "IE"
    }, {
      name: "Isle of Man",
      c: "44-IM-0",
      b: "44",
      f: "IM"
    }, {
      name: "Israel",
      c: "972-IL-0",
      b: "972",
      f: "IL"
    }, {
      name: "Italy",
      c: "39-IT-0",
      b: "39",
      f: "IT"
    }, {
      name: "Jamaica",
      c: "1-JM-0",
      b: "1",
      f: "JM"
    }, {
      name: "Japan",
      c: "81-JP-0",
      b: "81",
      f: "JP"
    }, {
      name: "Jersey",
      c: "44-JE-0",
      b: "44",
      f: "JE"
    }, {
      name: "Jordan",
      c: "962-JO-0",
      b: "962",
      f: "JO"
    }, {
      name: "Kazakhstan",
      c: "7-KZ-0",
      b: "7",
      f: "KZ"
    }, {
      name: "Kenya",
      c: "254-KE-0",
      b: "254",
      f: "KE"
    }, {
      name: "Kiribati",
      c: "686-KI-0",
      b: "686",
      f: "KI"
    }, {
      name: "Kosovo",
      c: "377-XK-0",
      b: "377",
      f: "XK"
    }, {
      name: "Kosovo",
      c: "381-XK-0",
      b: "381",
      f: "XK"
    }, {
      name: "Kosovo",
      c: "386-XK-0",
      b: "386",
      f: "XK"
    }, {
      name: "Kuwait",
      c: "965-KW-0",
      b: "965",
      f: "KW"
    }, {
      name: "Kyrgyzstan",
      c: "996-KG-0",
      b: "996",
      f: "KG"
    }, {
      name: "Laos",
      c: "856-LA-0",
      b: "856",
      f: "LA"
    }, {
      name: "Latvia",
      c: "371-LV-0",
      b: "371",
      f: "LV"
    }, {
      name: "Lebanon",
      c: "961-LB-0",
      b: "961",
      f: "LB"
    }, {
      name: "Lesotho",
      c: "266-LS-0",
      b: "266",
      f: "LS"
    }, {
      name: "Liberia",
      c: "231-LR-0",
      b: "231",
      f: "LR"
    }, {
      name: "Libya",
      c: "218-LY-0",
      b: "218",
      f: "LY"
    }, {
      name: "Liechtenstein",
      c: "423-LI-0",
      b: "423",
      f: "LI"
    }, {
      name: "Lithuania",
      c: "370-LT-0",
      b: "370",
      f: "LT"
    }, {
      name: "Luxembourg",
      c: "352-LU-0",
      b: "352",
      f: "LU"
    }, {
      name: "Macau",
      c: "853-MO-0",
      b: "853",
      f: "MO"
    }, {
      name: "Macedonia",
      c: "389-MK-0",
      b: "389",
      f: "MK"
    }, {
      name: "Madagascar",
      c: "261-MG-0",
      b: "261",
      f: "MG"
    }, {
      name: "Malawi",
      c: "265-MW-0",
      b: "265",
      f: "MW"
    }, {
      name: "Malaysia",
      c: "60-MY-0",
      b: "60",
      f: "MY"
    }, {
      name: "Maldives",
      c: "960-MV-0",
      b: "960",
      f: "MV"
    }, {
      name: "Mali",
      c: "223-ML-0",
      b: "223",
      f: "ML"
    }, {
      name: "Malta",
      c: "356-MT-0",
      b: "356",
      f: "MT"
    }, {
      name: "Marshall Islands",
      c: "692-MH-0",
      b: "692",
      f: "MH"
    }, {
      name: "Martinique",
      c: "596-MQ-0",
      b: "596",
      f: "MQ"
    }, {
      name: "Mauritania",
      c: "222-MR-0",
      b: "222",
      f: "MR"
    }, {
      name: "Mauritius",
      c: "230-MU-0",
      b: "230",
      f: "MU"
    }, {
      name: "Mayotte",
      c: "262-YT-0",
      b: "262",
      f: "YT"
    }, {
      name: "Mexico",
      c: "52-MX-0",
      b: "52",
      f: "MX"
    }, {
      name: "Micronesia",
      c: "691-FM-0",
      b: "691",
      f: "FM"
    }, {
      name: "Moldova",
      c: "373-MD-0",
      b: "373",
      f: "MD"
    }, {
      name: "Monaco",
      c: "377-MC-0",
      b: "377",
      f: "MC"
    }, {
      name: "Mongolia",
      c: "976-MN-0",
      b: "976",
      f: "MN"
    }, {
      name: "Montenegro",
      c: "382-ME-0",
      b: "382",
      f: "ME"
    }, {
      name: "Montserrat",
      c: "1-MS-0",
      b: "1",
      f: "MS"
    }, {
      name: "Morocco",
      c: "212-MA-0",
      b: "212",
      f: "MA"
    }, {
      name: "Mozambique",
      c: "258-MZ-0",
      b: "258",
      f: "MZ"
    }, {
      name: "Myanmar [Burma]",
      c: "95-MM-0",
      b: "95",
      f: "MM"
    }, {
      name: "Namibia",
      c: "264-NA-0",
      b: "264",
      f: "NA"
    }, {
      name: "Nauru",
      c: "674-NR-0",
      b: "674",
      f: "NR"
    }, {
      name: "Nepal",
      c: "977-NP-0",
      b: "977",
      f: "NP"
    }, {
      name: "Netherlands",
      c: "31-NL-0",
      b: "31",
      f: "NL"
    }, {
      name: "New Caledonia",
      c: "687-NC-0",
      b: "687",
      f: "NC"
    }, {
      name: "New Zealand",
      c: "64-NZ-0",
      b: "64",
      f: "NZ"
    }, {
      name: "Nicaragua",
      c: "505-NI-0",
      b: "505",
      f: "NI"
    }, {
      name: "Niger",
      c: "227-NE-0",
      b: "227",
      f: "NE"
    }, {
      name: "Nigeria",
      c: "234-NG-0",
      b: "234",
      f: "NG"
    }, {
      name: "Niue",
      c: "683-NU-0",
      b: "683",
      f: "NU"
    }, {
      name: "Norfolk Island",
      c: "672-NF-0",
      b: "672",
      f: "NF"
    }, {
      name: "North Korea",
      c: "850-KP-0",
      b: "850",
      f: "KP"
    }, {
      name: "Northern Mariana Islands",
      c: "1-MP-0",
      b: "1",
      f: "MP"
    }, {
      name: "Norway",
      c: "47-NO-0",
      b: "47",
      f: "NO"
    }, {
      name: "Oman",
      c: "968-OM-0",
      b: "968",
      f: "OM"
    }, {
      name: "Pakistan",
      c: "92-PK-0",
      b: "92",
      f: "PK"
    }, {
      name: "Palau",
      c: "680-PW-0",
      b: "680",
      f: "PW"
    }, {
      name: "Palestinian Territories",
      c: "970-PS-0",
      b: "970",
      f: "PS"
    }, {
      name: "Panama",
      c: "507-PA-0",
      b: "507",
      f: "PA"
    }, {
      name: "Papua New Guinea",
      c: "675-PG-0",
      b: "675",
      f: "PG"
    }, {
      name: "Paraguay",
      c: "595-PY-0",
      b: "595",
      f: "PY"
    }, {
      name: "Peru",
      c: "51-PE-0",
      b: "51",
      f: "PE"
    }, {
      name: "Philippines",
      c: "63-PH-0",
      b: "63",
      f: "PH"
    }, {
      name: "Poland",
      c: "48-PL-0",
      b: "48",
      f: "PL"
    }, {
      name: "Portugal",
      c: "351-PT-0",
      b: "351",
      f: "PT"
    }, {
      name: "Puerto Rico",
      c: "1-PR-0",
      b: "1",
      f: "PR"
    }, {
      name: "Qatar",
      c: "974-QA-0",
      b: "974",
      f: "QA"
    }, {
      name: "R\xE9union",
      c: "262-RE-0",
      b: "262",
      f: "RE"
    }, {
      name: "Romania",
      c: "40-RO-0",
      b: "40",
      f: "RO"
    }, {
      name: "Russia",
      c: "7-RU-0",
      b: "7",
      f: "RU"
    }, {
      name: "Rwanda",
      c: "250-RW-0",
      b: "250",
      f: "RW"
    }, {
      name: "Saint Barth\xE9lemy",
      c: "590-BL-0",
      b: "590",
      f: "BL"
    }, {
      name: "Saint Helena",
      c: "290-SH-0",
      b: "290",
      f: "SH"
    }, {
      name: "St. Kitts",
      c: "1-KN-0",
      b: "1",
      f: "KN"
    }, {
      name: "St. Lucia",
      c: "1-LC-0",
      b: "1",
      f: "LC"
    }, {
      name: "Saint Martin",
      c: "590-MF-0",
      b: "590",
      f: "MF"
    }, {
      name: "Saint Pierre and Miquelon",
      c: "508-PM-0",
      b: "508",
      f: "PM"
    }, {
      name: "St. Vincent",
      c: "1-VC-0",
      b: "1",
      f: "VC"
    }, {
      name: "Samoa",
      c: "685-WS-0",
      b: "685",
      f: "WS"
    }, {
      name: "San Marino",
      c: "378-SM-0",
      b: "378",
      f: "SM"
    }, {
      name: "S\xE3o Tom\xE9 and Pr\xEDncipe",
      c: "239-ST-0",
      b: "239",
      f: "ST"
    }, {
      name: "Saudi Arabia",
      c: "966-SA-0",
      b: "966",
      f: "SA"
    }, {
      name: "Senegal",
      c: "221-SN-0",
      b: "221",
      f: "SN"
    }, {
      name: "Serbia",
      c: "381-RS-0",
      b: "381",
      f: "RS"
    }, {
      name: "Seychelles",
      c: "248-SC-0",
      b: "248",
      f: "SC"
    }, {
      name: "Sierra Leone",
      c: "232-SL-0",
      b: "232",
      f: "SL"
    }, {
      name: "Singapore",
      c: "65-SG-0",
      b: "65",
      f: "SG"
    }, {
      name: "Sint Maarten",
      c: "1-SX-0",
      b: "1",
      f: "SX"
    }, {
      name: "Slovakia",
      c: "421-SK-0",
      b: "421",
      f: "SK"
    }, {
      name: "Slovenia",
      c: "386-SI-0",
      b: "386",
      f: "SI"
    }, {
      name: "Solomon Islands",
      c: "677-SB-0",
      b: "677",
      f: "SB"
    }, {
      name: "Somalia",
      c: "252-SO-0",
      b: "252",
      f: "SO"
    }, {
      name: "South Africa",
      c: "27-ZA-0",
      b: "27",
      f: "ZA"
    }, {
      name: "South Georgia and the South Sandwich Islands",
      c: "500-GS-0",
      b: "500",
      f: "GS"
    }, {
      name: "South Korea",
      c: "82-KR-0",
      b: "82",
      f: "KR"
    }, {
      name: "South Sudan",
      c: "211-SS-0",
      b: "211",
      f: "SS"
    }, {
      name: "Spain",
      c: "34-ES-0",
      b: "34",
      f: "ES"
    }, {
      name: "Sri Lanka",
      c: "94-LK-0",
      b: "94",
      f: "LK"
    }, {
      name: "Sudan",
      c: "249-SD-0",
      b: "249",
      f: "SD"
    }, {
      name: "Suriname",
      c: "597-SR-0",
      b: "597",
      f: "SR"
    }, {
      name: "Svalbard and Jan Mayen",
      c: "47-SJ-0",
      b: "47",
      f: "SJ"
    }, {
      name: "Swaziland",
      c: "268-SZ-0",
      b: "268",
      f: "SZ"
    }, {
      name: "Sweden",
      c: "46-SE-0",
      b: "46",
      f: "SE"
    }, {
      name: "Switzerland",
      c: "41-CH-0",
      b: "41",
      f: "CH"
    }, {
      name: "Syria",
      c: "963-SY-0",
      b: "963",
      f: "SY"
    }, {
      name: "Taiwan",
      c: "886-TW-0",
      b: "886",
      f: "TW"
    }, {
      name: "Tajikistan",
      c: "992-TJ-0",
      b: "992",
      f: "TJ"
    }, {
      name: "Tanzania",
      c: "255-TZ-0",
      b: "255",
      f: "TZ"
    }, {
      name: "Thailand",
      c: "66-TH-0",
      b: "66",
      f: "TH"
    }, {
      name: "Togo",
      c: "228-TG-0",
      b: "228",
      f: "TG"
    }, {
      name: "Tokelau",
      c: "690-TK-0",
      b: "690",
      f: "TK"
    }, {
      name: "Tonga",
      c: "676-TO-0",
      b: "676",
      f: "TO"
    }, {
      name: "Trinidad/Tobago",
      c: "1-TT-0",
      b: "1",
      f: "TT"
    }, {
      name: "Tunisia",
      c: "216-TN-0",
      b: "216",
      f: "TN"
    }, {
      name: "Turkey",
      c: "90-TR-0",
      b: "90",
      f: "TR"
    }, {
      name: "Turkmenistan",
      c: "993-TM-0",
      b: "993",
      f: "TM"
    }, {
      name: "Turks and Caicos Islands",
      c: "1-TC-0",
      b: "1",
      f: "TC"
    }, {
      name: "Tuvalu",
      c: "688-TV-0",
      b: "688",
      f: "TV"
    }, {
      name: "U.S. Virgin Islands",
      c: "1-VI-0",
      b: "1",
      f: "VI"
    }, {
      name: "Uganda",
      c: "256-UG-0",
      b: "256",
      f: "UG"
    }, {
      name: "Ukraine",
      c: "380-UA-0",
      b: "380",
      f: "UA"
    }, {
      name: "United Arab Emirates",
      c: "971-AE-0",
      b: "971",
      f: "AE"
    }, {
      name: "United Kingdom",
      c: "44-GB-0",
      b: "44",
      f: "GB"
    }, {
      name: "United States",
      c: "1-US-0",
      b: "1",
      f: "US"
    }, {
      name: "Uruguay",
      c: "598-UY-0",
      b: "598",
      f: "UY"
    }, {
      name: "Uzbekistan",
      c: "998-UZ-0",
      b: "998",
      f: "UZ"
    }, {
      name: "Vanuatu",
      c: "678-VU-0",
      b: "678",
      f: "VU"
    }, {
      name: "Vatican City",
      c: "379-VA-0",
      b: "379",
      f: "VA"
    }, {
      name: "Venezuela",
      c: "58-VE-0",
      b: "58",
      f: "VE"
    }, {
      name: "Vietnam",
      c: "84-VN-0",
      b: "84",
      f: "VN"
    }, {
      name: "Wallis and Futuna",
      c: "681-WF-0",
      b: "681",
      f: "WF"
    }, {
      name: "Western Sahara",
      c: "212-EH-0",
      b: "212",
      f: "EH"
    }, {
      name: "Yemen",
      c: "967-YE-0",
      b: "967",
      f: "YE"
    }, {
      name: "Zambia",
      c: "260-ZM-0",
      b: "260",
      f: "ZM"
    }, {
      name: "Zimbabwe",
      c: "263-ZW-0",
      b: "263",
      f: "ZW"
    }];
    Vh(Sh);
    var Wh = new Ph(Sh);

    function Xh(a, b) {
      (this || _global).a = a;
      (this || _global).Aa = b;
    }

    function Yh(a) {
      a = Va(a);
      var b = Qh(Wh, a);
      return 0 < b.length ? new Xh("1" == b[0].b ? "1-US-0" : b[0].c, Va(a.substr(b[0].b.length + 1))) : null;
    }

    function Zh(a) {
      var b = Rh(a.a);
      if (!b) throw Error("Country ID " + a.a + " not found.");
      return "+" + b.b + a.Aa;
    }

    function $h(a, b) {
      for (var c = 0; c < a.length; c++) if (!Ma(ai, a[c]) && (null !== bi && a[c] in bi || Ma(b, a[c]))) return a[c];

      return null;
    }

    var ai = ["emailLink", "password", "phone"],
        bi = {
      "facebook.com": "FacebookAuthProvider",
      "github.com": "GithubAuthProvider",
      "google.com": "GoogleAuthProvider",
      password: "EmailAuthProvider",
      "twitter.com": "TwitterAuthProvider",
      phone: "PhoneAuthProvider"
    };

    function ci() {
      (this || _global).a = new Ih();
      G((this || _global).a, "acUiConfig");
      G((this || _global).a, "autoUpgradeAnonymousUsers");
      G((this || _global).a, "callbacks");
      G((this || _global).a, "credentialHelper", di);
      G((this || _global).a, "immediateFederatedRedirect", !1);
      G((this || _global).a, "popupMode", !1);
      G((this || _global).a, "privacyPolicyUrl");
      G((this || _global).a, "queryParameterForSignInSuccessUrl", "signInSuccessUrl");
      G((this || _global).a, "queryParameterForWidgetMode", "mode");
      G((this || _global).a, "signInFlow");
      G((this || _global).a, "signInOptions");
      G((this || _global).a, "signInSuccessUrl");
      G((this || _global).a, "siteName");
      G((this || _global).a, "tosUrl");
      G((this || _global).a, "widgetUrl");
    }

    function ei(a) {
      var b = !!a.a.get("autoUpgradeAnonymousUsers");
      b && !fi(a) && pg("Missing \"signInFailure\" callback: \"signInFailure\" callback needs to be provided when \"autoUpgradeAnonymousUsers\" is set to true.", void 0);
      return b;
    }

    function gi(a) {
      a = a.a.get("signInOptions") || [];

      for (var b = [], c = 0; c < a.length; c++) {
        var d = a[c];
        d = ta(d) ? d : {
          provider: d
        };
        d.provider && b.push(d);
      }

      return b;
    }

    function hi(a, b) {
      a = gi(a);

      for (var c = 0; c < a.length; c++) if (a[c].provider === b) return a[c];

      return null;
    }

    function ii(a) {
      return Ka(gi(a), function (b) {
        return b.provider;
      });
    }

    function ji(a, b) {
      a = ki(a);

      for (var c = 0; c < a.length; c++) if (a[c].providerId === b) return a[c];

      return null;
    }

    function ki(a) {
      return Ka(gi(a), function (b) {
        if (bi[b.provider] || Ma(li, b.provider)) {
          b = {
            providerId: b.provider,
            S: b.providerName || null,
            V: b.fullLabel || null,
            ta: b.buttonColor || null,
            za: b.iconUrl ? Ac(Cc(b.iconUrl)).toString() : null
          };

          for (var c in b) null === b[c] && delete b[c];

          return b;
        }

        return {
          providerId: b.provider,
          S: b.providerName || null,
          V: b.fullLabel || null,
          ta: b.buttonColor || null,
          za: b.iconUrl ? Ac(Cc(b.iconUrl)).toString() : null,
          Ob: b.loginHintKey || null
        };
      });
    }

    function mi(a) {
      var b = hi(a, firebase.auth.GoogleAuthProvider.PROVIDER_ID);
      return b && b.clientId && ni(a) === oi ? b.clientId || null : null;
    }

    function pi(a) {
      a = hi(a, firebase.auth.EmailAuthProvider.PROVIDER_ID);
      return !!(a && a.disableSignUp && a.disableSignUp.status);
    }

    function qi(a) {
      var b = null;
      Ha(gi(a), function (d) {
        d.provider == firebase.auth.PhoneAuthProvider.PROVIDER_ID && ta(d.recaptchaParameters) && !Array.isArray(d.recaptchaParameters) && (b = fb(d.recaptchaParameters));
      });

      if (b) {
        var c = [];
        Ha(ri, function (d) {
          "undefined" !== typeof b[d] && (c.push(d), delete b[d]);
        });
        c.length && ug("The following provided \"recaptchaParameters\" keys are not allowed: " + c.join(", "));
      }

      return b;
    }

    function si(a) {
      return (a = hi(a, firebase.auth.EmailAuthProvider.PROVIDER_ID)) && a.disableSignUp && a.disableSignUp.adminEmail || null;
    }

    function ti(a) {
      if ((a = hi(a, firebase.auth.EmailAuthProvider.PROVIDER_ID)) && a.disableSignUp) {
        var b = a.disableSignUp.helpLink || null;
        if (b && "string" === typeof b) return function () {
          sf(b);
        };
      }

      return null;
    }

    function ui(a, b) {
      a = (a = hi(a, b)) && a.scopes;
      return Array.isArray(a) ? a : [];
    }

    function vi(a, b) {
      a = (a = hi(a, b)) && a.customParameters;
      return ta(a) ? (a = fb(a), b === firebase.auth.GoogleAuthProvider.PROVIDER_ID && delete a.login_hint, b === firebase.auth.GithubAuthProvider.PROVIDER_ID && delete a.login, a) : null;
    }

    function wi(a) {
      a = hi(a, firebase.auth.PhoneAuthProvider.PROVIDER_ID);
      var b = null;
      a && "string" === typeof a.loginHint && (b = Yh(a.loginHint));
      return a && a.defaultNationalNumber || b && b.Aa || null;
    }

    function xi(a) {
      var b = (a = hi(a, firebase.auth.PhoneAuthProvider.PROVIDER_ID)) && a.defaultCountry || null;
      b = b && Th(b);
      var c = null;
      a && "string" === typeof a.loginHint && (c = Yh(a.loginHint));
      return b && b[0] || c && Rh(c.a) || null;
    }

    function yi(a) {
      a = hi(a, firebase.auth.PhoneAuthProvider.PROVIDER_ID);
      if (!a) return null;
      var b = a.whitelistedCountries,
          c = a.blacklistedCountries;
      if ("undefined" !== typeof b && (!Array.isArray(b) || 0 == b.length)) throw Error("WhitelistedCountries must be a non-empty array.");
      if ("undefined" !== typeof c && !Array.isArray(c)) throw Error("BlacklistedCountries must be an array.");
      if (b && c) throw Error("Both whitelistedCountries and blacklistedCountries are provided.");
      if (!b && !c) return Sh;
      a = [];

      if (b) {
        c = {};

        for (var d = 0; d < b.length; d++) {
          var e = Uh(b[d]);

          for (var f = 0; f < e.length; f++) c[e[f].c] = e[f];
        }

        for (var g in c) c.hasOwnProperty(g) && a.push(c[g]);
      } else {
        g = {};

        for (b = 0; b < c.length; b++) for (e = Uh(c[b]), d = 0; d < e.length; d++) g[e[d].c] = e[d];

        for (e = 0; e < Sh.length; e++) null !== g && Sh[e].c in g || a.push(Sh[e]);
      }

      return a;
    }

    function zi(a) {
      return Kh(a.a, "queryParameterForWidgetMode");
    }

    function H(a) {
      var b = a.a.get("tosUrl") || null;
      a = a.a.get("privacyPolicyUrl") || null;
      b && !a && ug("Privacy Policy URL is missing, the link will not be displayed.");

      if (b && a) {
        if ("function" === typeof b) return b;
        if ("string" === typeof b) return function () {
          sf(b);
        };
      }

      return null;
    }

    function J(a) {
      var b = a.a.get("tosUrl") || null,
          c = a.a.get("privacyPolicyUrl") || null;
      c && !b && ug("Term of Service URL is missing, the link will not be displayed.");

      if (b && c) {
        if ("function" === typeof c) return c;
        if ("string" === typeof c) return function () {
          sf(c);
        };
      }

      return null;
    }

    function Ai(a) {
      return (a = hi(a, firebase.auth.EmailAuthProvider.PROVIDER_ID)) && "undefined" !== typeof a.requireDisplayName ? !!a.requireDisplayName : !0;
    }

    function Bi(a) {
      a = hi(a, firebase.auth.EmailAuthProvider.PROVIDER_ID);
      return !(!a || a.signInMethod !== firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD);
    }

    function Ci(a) {
      a = hi(a, firebase.auth.EmailAuthProvider.PROVIDER_ID);
      return !(!a || !a.forceSameDevice);
    }

    function Di(a) {
      if (Bi(a)) {
        var b = {
          url: uf(),
          handleCodeInApp: !0
        };
        (a = hi(a, firebase.auth.EmailAuthProvider.PROVIDER_ID)) && "function" === typeof a.emailLinkSignIn && hb(b, a.emailLinkSignIn());
        a = b.url;
        var c = uf();
        c instanceof wb || (c = Kb(c));
        a instanceof wb || (a = Kb(a));
        var d = c;
        c = new wb(d);
        var e = !!a.j;
        e ? xb(c, a.j) : e = !!a.A;
        e ? c.A = a.A : e = !!a.h;
        e ? c.h = a.h : e = null != a.C;
        var f = a.g;
        if (e) yb(c, a.C);else if (e = !!a.g) if ("/" != f.charAt(0) && (d.h && !d.g ? f = "/" + f : (d = c.g.lastIndexOf("/"), -1 != d && (f = c.g.substr(0, d + 1) + f))), ".." == f || "." == f) f = "";else if (-1 != f.indexOf("./") || -1 != f.indexOf("/.")) {
          d = 0 == f.lastIndexOf("/", 0);
          f = f.split("/");

          for (var g = [], h = 0; h < f.length;) {
            var k = f[h++];
            "." == k ? d && h == f.length && g.push("") : ".." == k ? ((1 < g.length || 1 == g.length && "" != g[0]) && g.pop(), d && h == f.length && g.push("")) : (g.push(k), d = !0);
          }

          f = g.join("/");
        }
        e ? c.g = f : e = "" !== a.a.toString();
        e ? zb(c, Ab(a.a)) : e = !!a.s;
        e && (c.s = a.s);
        b.url = c.toString();
        return b;
      }

      return null;
    }

    function Ei(a) {
      var b = !!a.a.get("immediateFederatedRedirect"),
          c = ii(a);
      a = Fi(a);
      return b && 1 == c.length && !Ma(ai, c[0]) && a == Gi;
    }

    function Fi(a) {
      a = a.a.get("signInFlow");

      for (var b in Hi) if (Hi[b] == a) return Hi[b];

      return Gi;
    }

    function Ii(a) {
      return Ji(a).signInSuccess || null;
    }

    function Ki(a) {
      return Ji(a).signInSuccessWithAuthResult || null;
    }

    function fi(a) {
      return Ji(a).signInFailure || null;
    }

    function Ji(a) {
      return a.a.get("callbacks") || {};
    }

    function ni(a) {
      if ("http:" !== (window.location && window.location.protocol) && "https:" !== (window.location && window.location.protocol)) return di;
      a = a.a.get("credentialHelper");
      if (a === Li) return di;

      for (var b in Mi) if (Mi[b] === a) return Mi[b];

      return di;
    }

    var Li = "accountchooser.com",
        oi = "googleyolo",
        di = "none",
        Mi = {
      kc: Li,
      nc: oi,
      NONE: di
    },
        Gi = "redirect",
        Hi = {
      qc: "popup",
      rc: Gi
    },
        Ni = {
      mc: "callback",
      RECOVER_EMAIL: "recoverEmail",
      sc: "resetPassword",
      REVERT_SECOND_FACTOR_ADDITION: "revertSecondFactorAddition",
      tc: "select",
      uc: "signIn",
      VERIFY_AND_CHANGE_EMAIL: "verifyAndChangeEmail",
      VERIFY_EMAIL: "verifyEmail"
    },
        li = ["anonymous"],
        ri = ["sitekey", "tabindex", "callback", "expired-callback"];
    var Oi,
        Pi,
        Qi,
        Ri,
        K = {};

    function L(a, b, c, d) {
      K[a].apply(null, Array.prototype.slice.call(arguments, 1));
    }

    function Si(a) {
      if (a.classList) return a.classList;
      a = a.className;
      return q(a) && a.match(/\S+/g) || [];
    }

    function Ti(a, b) {
      return a.classList ? a.classList.contains(b) : Ma(Si(a), b);
    }

    function Ui(a, b) {
      a.classList ? a.classList.add(b) : Ti(a, b) || (a.className += 0 < a.className.length ? " " + b : b);
    }

    function Vi(a, b) {
      a.classList ? a.classList.remove(b) : Ti(a, b) && (a.className = Ja(Si(a), function (c) {
        return c != b;
      }).join(" "));
    }

    function Wi(a) {
      var b = a.type;

      switch (q(b) && b.toLowerCase()) {
        case "checkbox":
        case "radio":
          return a.checked ? a.value : null;

        case "select-one":
          return b = a.selectedIndex, 0 <= b ? a.options[b].value : null;

        case "select-multiple":
          b = [];

          for (var c, d = 0; c = a.options[d]; d++) c.selected && b.push(c.value);

          return b.length ? b : null;

        default:
          return null != a.value ? a.value : null;
      }
    }

    function Xi(a, b) {
      var c = a.type;

      switch (q(c) && c.toLowerCase()) {
        case "checkbox":
        case "radio":
          a.checked = b;
          break;

        case "select-one":
          a.selectedIndex = -1;
          if (q(b)) for (var d = 0; c = a.options[d]; d++) if (c.value == b) {
            c.selected = !0;
            break;
          }
          break;

        case "select-multiple":
          q(b) && (b = [b]);

          for (d = 0; c = a.options[d]; d++) if (c.selected = !1, b) for (var e, f = 0; e = b[f]; f++) c.value == e && (c.selected = !0);

          break;

        default:
          a.value = null != b ? b : "";
      }
    }

    function Yi(a) {
      if (a.altKey && !a.ctrlKey || a.metaKey || 112 <= a.keyCode && 123 >= a.keyCode) return !1;
      if (Zi(a.keyCode)) return !0;

      switch (a.keyCode) {
        case 18:
        case 20:
        case 93:
        case 17:
        case 40:
        case 35:
        case 27:
        case 36:
        case 45:
        case 37:
        case 224:
        case 91:
        case 144:
        case 12:
        case 34:
        case 33:
        case 19:
        case 255:
        case 44:
        case 39:
        case 145:
        case 16:
        case 38:
        case 252:
        case 224:
        case 92:
          return !1;

        case 0:
          return !ec;

        default:
          return 166 > a.keyCode || 183 < a.keyCode;
      }
    }

    function $i(a, b, c, d, e, f) {
      if (fc && !nc("525")) return !0;
      if (hc && e) return Zi(a);
      if (e && !d) return !1;

      if (!ec) {
        "number" == typeof b && (b = aj(b));
        var g = 17 == b || 18 == b || hc && 91 == b;
        if ((!c || hc) && g || hc && 16 == b && (d || f)) return !1;
      }

      if ((fc || cc) && d && c) switch (a) {
        case 220:
        case 219:
        case 221:
        case 192:
        case 186:
        case 189:
        case 187:
        case 188:
        case 190:
        case 191:
        case 192:
        case 222:
          return !1;
      }
      if (z && d && b == a) return !1;

      switch (a) {
        case 13:
          return ec ? f || e ? !1 : !(c && d) : !0;

        case 27:
          return !(fc || cc || ec);
      }

      return ec && (d || e || f) ? !1 : Zi(a);
    }

    function Zi(a) {
      if (48 <= a && 57 >= a || 96 <= a && 106 >= a || 65 <= a && 90 >= a || (fc || cc) && 0 == a) return !0;

      switch (a) {
        case 32:
        case 43:
        case 63:
        case 64:
        case 107:
        case 109:
        case 110:
        case 111:
        case 186:
        case 59:
        case 189:
        case 187:
        case 61:
        case 188:
        case 190:
        case 191:
        case 192:
        case 222:
        case 219:
        case 220:
        case 221:
        case 163:
          return !0;

        case 173:
          return ec;

        default:
          return !1;
      }
    }

    function aj(a) {
      if (ec) a = bj(a);else if (hc && fc) switch (a) {
        case 93:
          a = 91;
      }
      return a;
    }

    function bj(a) {
      switch (a) {
        case 61:
          return 187;

        case 59:
          return 186;

        case 173:
          return 189;

        case 224:
          return 91;

        case 0:
          return 224;

        default:
          return a;
      }
    }

    function cj(a) {
      E.call(this || _global);
      (this || _global).a = a;
      le(a, "keydown", (this || _global).g, !1, this || _global);
      le(a, "click", (this || _global).h, !1, this || _global);
    }

    w(cj, E);

    cj.prototype.g = function (a) {
      (13 == a.keyCode || fc && 3 == a.keyCode) && dj(this || _global, a);
    };

    cj.prototype.h = function (a) {
      dj(this || _global, a);
    };

    function dj(a, b) {
      var c = new ej(b);

      if (ye(a, c)) {
        c = new fj(b);

        try {
          ye(a, c);
        } finally {
          b.stopPropagation();
        }
      }
    }

    cj.prototype.m = function () {
      cj.K.m.call(this || _global);
      te((this || _global).a, "keydown", (this || _global).g, !1, this || _global);
      te((this || _global).a, "click", (this || _global).h, !1, this || _global);
      delete (this || _global).a;
    };

    function fj(a) {
      $d.call(this || _global, a.a);
      (this || _global).type = "action";
    }

    w(fj, $d);

    function ej(a) {
      $d.call(this || _global, a.a);
      (this || _global).type = "beforeaction";
    }

    w(ej, $d);

    function gj(a) {
      E.call(this || _global);
      (this || _global).a = a;
      a = z ? "focusout" : "blur";
      (this || _global).g = le((this || _global).a, z ? "focusin" : "focus", this || _global, !z);
      (this || _global).h = le((this || _global).a, a, this || _global, !z);
    }

    w(gj, E);

    gj.prototype.handleEvent = function (a) {
      var b = new $d(a.a);
      b.type = "focusin" == a.type || "focus" == a.type ? "focusin" : "focusout";
      ye(this || _global, b);
    };

    gj.prototype.m = function () {
      gj.K.m.call(this || _global);
      ue((this || _global).g);
      ue((this || _global).h);
      delete (this || _global).a;
    };

    function hj(a, b) {
      E.call(this || _global);
      (this || _global).g = a || 1;
      (this || _global).a = b || n;
      (this || _global).h = t((this || _global).gc, this || _global);
      (this || _global).j = Aa();
    }

    w(hj, E);
    l = hj.prototype;
    l.Ka = !1;
    l.aa = null;

    l.gc = function () {
      if ((this || _global).Ka) {
        var a = Aa() - (this || _global).j;

        0 < a && a < 0.8 * (this || _global).g ? (this || _global).aa = (this || _global).a.setTimeout((this || _global).h, (this || _global).g - a) : ((this || _global).aa && ((this || _global).a.clearTimeout((this || _global).aa), (this || _global).aa = null), ye(this || _global, "tick"), (this || _global).Ka && (ij(this || _global), this.start()));
      }
    };

    l.start = function () {
      (this || _global).Ka = !0;
      (this || _global).aa || ((this || _global).aa = (this || _global).a.setTimeout((this || _global).h, (this || _global).g), (this || _global).j = Aa());
    };

    function ij(a) {
      a.Ka = !1;
      a.aa && (a.a.clearTimeout(a.aa), a.aa = null);
    }

    l.m = function () {
      hj.K.m.call(this || _global);
      ij(this || _global);
      delete (this || _global).a;
    };

    function jj(a, b) {
      if (sa(a)) b && (a = t(a, b));else if (a && "function" == typeof a.handleEvent) a = t(a.handleEvent, a);else throw Error("Invalid listener argument");
      return 2147483647 < Number(0) ? -1 : n.setTimeout(a, 0);
    }

    function kj(a) {
      Qd.call(this || _global);
      (this || _global).g = a;
      (this || _global).a = {};
    }

    w(kj, Qd);
    var lj = [];

    function mj(a, b, c, d) {
      qa(c) || (c && (lj[0] = c.toString()), c = lj);

      for (var e = 0; e < c.length; e++) {
        var f = le(b, c[e], d || a.handleEvent, !1, a.g || a);
        if (!f) break;
        a.a[f.key] = f;
      }
    }

    function nj(a) {
      eb(a.a, function (b, c) {
        (this || _global).a.hasOwnProperty(c) && ue(b);
      }, a);
      a.a = {};
    }

    kj.prototype.m = function () {
      kj.K.m.call(this || _global);
      nj(this || _global);
    };

    kj.prototype.handleEvent = function () {
      throw Error("EventHandler.handleEvent not implemented");
    };

    function oj(a) {
      E.call(this || _global);
      (this || _global).a = null;
      (this || _global).g = a;
      a = z || cc || fc && !nc("531") && "TEXTAREA" == a.tagName;
      (this || _global).h = new kj(this || _global);
      mj((this || _global).h, (this || _global).g, a ? ["keydown", "paste", "cut", "drop", "input"] : "input", this || _global);
    }

    w(oj, E);

    oj.prototype.handleEvent = function (a) {
      if ("input" == a.type) z && nc(10) && 0 == a.keyCode && 0 == a.j || (pj(this || _global), ye(this || _global, qj(a)));else if ("keydown" != a.type || Yi(a)) {
        var b = "keydown" == a.type ? (this || _global).g.value : null;
        z && 229 == a.keyCode && (b = null);
        var c = qj(a);
        pj(this || _global);
        (this || _global).a = jj(function () {
          (this || _global).a = null;
          (this || _global).g.value != b && ye(this || _global, c);
        }, this || _global);
      }
    };

    function pj(a) {
      null != a.a && (n.clearTimeout(a.a), a.a = null);
    }

    function qj(a) {
      a = new $d(a.a);
      a.type = "input";
      return a;
    }

    oj.prototype.m = function () {
      oj.K.m.call(this || _global);

      (this || _global).h.o();

      pj(this || _global);
      delete (this || _global).g;
    };

    function rj(a, b) {
      E.call(this || _global);
      a && ((this || _global).Oa && sj(this || _global), (this || _global).qa = a, (this || _global).Na = le((this || _global).qa, "keypress", this || _global, b), (this || _global).Ya = le((this || _global).qa, "keydown", (this || _global).Jb, b, this || _global), (this || _global).Oa = le((this || _global).qa, "keyup", (this || _global).Kb, b, this || _global));
    }

    w(rj, E);
    l = rj.prototype;
    l.qa = null;
    l.Na = null;
    l.Ya = null;
    l.Oa = null;
    l.R = -1;
    l.X = -1;
    l.Ua = !1;
    var tj = {
      3: 13,
      12: 144,
      63232: 38,
      63233: 40,
      63234: 37,
      63235: 39,
      63236: 112,
      63237: 113,
      63238: 114,
      63239: 115,
      63240: 116,
      63241: 117,
      63242: 118,
      63243: 119,
      63244: 120,
      63245: 121,
      63246: 122,
      63247: 123,
      63248: 44,
      63272: 46,
      63273: 36,
      63275: 35,
      63276: 33,
      63277: 34,
      63289: 144,
      63302: 45
    },
        uj = {
      Up: 38,
      Down: 40,
      Left: 37,
      Right: 39,
      Enter: 13,
      F1: 112,
      F2: 113,
      F3: 114,
      F4: 115,
      F5: 116,
      F6: 117,
      F7: 118,
      F8: 119,
      F9: 120,
      F10: 121,
      F11: 122,
      F12: 123,
      "U+007F": 46,
      Home: 36,
      End: 35,
      PageUp: 33,
      PageDown: 34,
      Insert: 45
    },
        vj = !fc || nc("525"),
        wj = hc && ec;
    l = rj.prototype;

    l.Jb = function (a) {
      if (fc || cc) if (17 == (this || _global).R && !a.ctrlKey || 18 == (this || _global).R && !a.altKey || hc && 91 == (this || _global).R && !a.metaKey) (this || _global).X = (this || _global).R = -1;
      -1 == (this || _global).R && (a.ctrlKey && 17 != a.keyCode ? (this || _global).R = 17 : a.altKey && 18 != a.keyCode ? (this || _global).R = 18 : a.metaKey && 91 != a.keyCode && ((this || _global).R = 91));
      vj && !$i(a.keyCode, (this || _global).R, a.shiftKey, a.ctrlKey, a.altKey, a.metaKey) ? this.handleEvent(a) : ((this || _global).X = aj(a.keyCode), wj && ((this || _global).Ua = a.altKey));
    };

    l.Kb = function (a) {
      (this || _global).X = (this || _global).R = -1;
      (this || _global).Ua = a.altKey;
    };

    l.handleEvent = function (a) {
      var b = a.a,
          c = b.altKey;

      if (z && "keypress" == a.type) {
        var d = (this || _global).X;
        var e = 13 != d && 27 != d ? b.keyCode : 0;
      } else (fc || cc) && "keypress" == a.type ? (d = (this || _global).X, e = 0 <= b.charCode && 63232 > b.charCode && Zi(d) ? b.charCode : 0) : bc && !fc ? (d = (this || _global).X, e = Zi(d) ? b.keyCode : 0) : ("keypress" == a.type ? (wj && (c = (this || _global).Ua), b.keyCode == b.charCode ? 32 > b.keyCode ? (d = b.keyCode, e = 0) : (d = (this || _global).X, e = b.charCode) : (d = b.keyCode || (this || _global).X, e = b.charCode || 0)) : (d = b.keyCode || (this || _global).X, e = b.charCode || 0), hc && 63 == e && 224 == d && (d = 191));

      var f = d = aj(d);
      d ? 63232 <= d && d in tj ? f = tj[d] : 25 == d && a.shiftKey && (f = 9) : b.keyIdentifier && b.keyIdentifier in uj && (f = uj[b.keyIdentifier]);
      ec && vj && "keypress" == a.type && !$i(f, (this || _global).R, a.shiftKey, a.ctrlKey, c, a.metaKey) || (a = f == (this || _global).R, (this || _global).R = f, b = new xj(f, e, a, b), b.altKey = c, ye(this || _global, b));
    };

    l.N = function () {
      return (this || _global).qa;
    };

    function sj(a) {
      a.Na && (ue(a.Na), ue(a.Ya), ue(a.Oa), a.Na = null, a.Ya = null, a.Oa = null);
      a.qa = null;
      a.R = -1;
      a.X = -1;
    }

    l.m = function () {
      rj.K.m.call(this || _global);
      sj(this || _global);
    };

    function xj(a, b, c, d) {
      $d.call(this || _global, d);
      (this || _global).type = "key";
      (this || _global).keyCode = a;
      (this || _global).j = b;
      (this || _global).repeat = c;
    }

    w(xj, $d);

    function yj(a, b, c, d) {
      (this || _global).top = a;
      (this || _global).right = b;
      (this || _global).bottom = c;
      (this || _global).left = d;
    }

    yj.prototype.toString = function () {
      return "(" + (this || _global).top + "t, " + (this || _global).right + "r, " + (this || _global).bottom + "b, " + (this || _global).left + "l)";
    };

    yj.prototype.ceil = function () {
      (this || _global).top = Math.ceil((this || _global).top);
      (this || _global).right = Math.ceil((this || _global).right);
      (this || _global).bottom = Math.ceil((this || _global).bottom);
      (this || _global).left = Math.ceil((this || _global).left);
      return this || _global;
    };

    yj.prototype.floor = function () {
      (this || _global).top = Math.floor((this || _global).top);
      (this || _global).right = Math.floor((this || _global).right);
      (this || _global).bottom = Math.floor((this || _global).bottom);
      (this || _global).left = Math.floor((this || _global).left);
      return this || _global;
    };

    yj.prototype.round = function () {
      (this || _global).top = Math.round((this || _global).top);
      (this || _global).right = Math.round((this || _global).right);
      (this || _global).bottom = Math.round((this || _global).bottom);
      (this || _global).left = Math.round((this || _global).left);
      return this || _global;
    };

    function zj(a, b) {
      var c = Tc(a);
      return c.defaultView && c.defaultView.getComputedStyle && (a = c.defaultView.getComputedStyle(a, null)) ? a[b] || a.getPropertyValue(b) || "" : "";
    }

    function Aj(a) {
      try {
        var b = a.getBoundingClientRect();
      } catch (c) {
        return {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0
        };
      }

      z && a.ownerDocument.body && (a = a.ownerDocument, b.left -= a.documentElement.clientLeft + a.body.clientLeft, b.top -= a.documentElement.clientTop + a.body.clientTop);
      return b;
    }

    function Bj(a, b) {
      b = b || Zc(document);
      var c = b || Zc(document);
      var d = Cj(a),
          e = Cj(c);

      if (!z || 9 <= Number(oc)) {
        g = zj(c, "borderLeftWidth");
        var f = zj(c, "borderRightWidth");
        h = zj(c, "borderTopWidth");
        k = zj(c, "borderBottomWidth");
        f = new yj(parseFloat(h), parseFloat(f), parseFloat(k), parseFloat(g));
      } else {
        var g = Dj(c, "borderLeft");
        f = Dj(c, "borderRight");
        var h = Dj(c, "borderTop"),
            k = Dj(c, "borderBottom");
        f = new yj(h, f, k, g);
      }

      c == Zc(document) ? (g = d.a - c.scrollLeft, d = d.g - c.scrollTop, !z || 10 <= Number(oc) || (g += f.left, d += f.top)) : (g = d.a - e.a - f.left, d = d.g - e.g - f.top);
      e = a.offsetWidth;
      f = a.offsetHeight;
      h = fc && !e && !f;
      ka(e) && !h || !a.getBoundingClientRect ? a = new Qc(e, f) : (a = Aj(a), a = new Qc(a.right - a.left, a.bottom - a.top));
      e = c.clientHeight - a.height;
      f = c.scrollLeft;
      h = c.scrollTop;
      f += Math.min(g, Math.max(g - (c.clientWidth - a.width), 0));
      h += Math.min(d, Math.max(d - e, 0));
      c = new Pc(f, h);
      b.scrollLeft = c.a;
      b.scrollTop = c.g;
    }

    function Cj(a) {
      var b = Tc(a),
          c = new Pc(0, 0);
      var d = b ? Tc(b) : document;
      d = !z || 9 <= Number(oc) || "CSS1Compat" == Rc(d).a.compatMode ? d.documentElement : d.body;
      if (a == d) return c;
      a = Aj(a);
      d = Rc(b).a;
      b = Zc(d);
      d = d.parentWindow || d.defaultView;
      b = z && nc("10") && d.pageYOffset != b.scrollTop ? new Pc(b.scrollLeft, b.scrollTop) : new Pc(d.pageXOffset || b.scrollLeft, d.pageYOffset || b.scrollTop);
      c.a = a.left + b.a;
      c.g = a.top + b.g;
      return c;
    }

    var Ej = {
      thin: 2,
      medium: 4,
      thick: 6
    };

    function Dj(a, b) {
      if ("none" == (a.currentStyle ? a.currentStyle[b + "Style"] : null)) return 0;
      var c = a.currentStyle ? a.currentStyle[b + "Width"] : null;
      if (c in Ej) a = Ej[c];else if (/^\d+px?$/.test(c)) a = parseInt(c, 10);else {
        b = a.style.left;
        var d = a.runtimeStyle.left;
        a.runtimeStyle.left = a.currentStyle.left;
        a.style.left = c;
        c = a.style.pixelLeft;
        a.style.left = b;
        a.runtimeStyle.left = d;
        a = +c;
      }
      return a;
    }

    function Fj() {}

    oa(Fj);
    Fj.prototype.a = 0;

    function Gj(a) {
      E.call(this || _global);
      (this || _global).s = a || Rc();
      (this || _global).cb = null;
      (this || _global).na = !1;
      (this || _global).g = null;
      (this || _global).L = void 0;
      (this || _global).oa = (this || _global).Ea = (this || _global).Y = null;
    }

    w(Gj, E);
    l = Gj.prototype;
    l.Lb = Fj.Xa();

    l.N = function () {
      return (this || _global).g;
    };

    function M(a, b) {
      return a.g ? Wc(b, a.g || a.s.a) : null;
    }

    function Hj(a) {
      a.L || (a.L = new kj(a));
      return a.L;
    }

    l.Za = function (a) {
      if ((this || _global).Y && (this || _global).Y != a) throw Error("Method not supported");
      Gj.K.Za.call(this || _global, a);
    };

    l.kb = function () {
      (this || _global).g = (this || _global).s.a.createElement("DIV");
    };

    l.render = function (a) {
      if ((this || _global).na) throw Error("Component already rendered");
      (this || _global).g || this.kb();
      a ? a.insertBefore((this || _global).g, null) : (this || _global).s.a.body.appendChild((this || _global).g);
      (this || _global).Y && !(this || _global).Y.na || this.v();
    };

    l.v = function () {
      (this || _global).na = !0;
      Ij(this || _global, function (a) {
        !a.na && a.N() && a.v();
      });
    };

    l.ya = function () {
      Ij(this || _global, function (a) {
        a.na && a.ya();
      });
      (this || _global).L && nj((this || _global).L);
      (this || _global).na = !1;
    };

    l.m = function () {
      (this || _global).na && this.ya();
      (this || _global).L && ((this || _global).L.o(), delete (this || _global).L);
      Ij(this || _global, function (a) {
        a.o();
      });
      (this || _global).g && $c((this || _global).g);
      (this || _global).Y = (this || _global).g = (this || _global).oa = (this || _global).Ea = null;
      Gj.K.m.call(this || _global);
    };

    function Ij(a, b) {
      a.Ea && Ha(a.Ea, b, void 0);
    }

    l.removeChild = function (a, b) {
      if (a) {
        var c = q(a) ? a : a.cb || (a.cb = ":" + (a.Lb.a++).toString(36));
        (this || _global).oa && c ? (a = (this || _global).oa, a = (null !== a && c in a ? a[c] : void 0) || null) : a = null;

        if (c && a) {
          var d = (this || _global).oa;
          c in d && delete d[c];
          Na((this || _global).Ea, a);
          b && (a.ya(), a.g && $c(a.g));
          b = a;
          if (null == b) throw Error("Unable to set parent component");
          b.Y = null;
          Gj.K.Za.call(b, null);
        }
      }

      if (!a) throw Error("Child is not in parent component");
      return a;
    };

    function N(a, b) {
      var c = bd(a, "firebaseui-textfield");
      b ? (Vi(a, "firebaseui-input-invalid"), Ui(a, "firebaseui-input"), c && Vi(c, "firebaseui-textfield-invalid")) : (Vi(a, "firebaseui-input"), Ui(a, "firebaseui-input-invalid"), c && Ui(c, "firebaseui-textfield-invalid"));
    }

    function Jj(a, b, c) {
      b = new oj(b);
      Td(a, za(Ud, b));
      mj(Hj(a), b, "input", c);
    }

    function Kj(a, b, c) {
      b = new rj(b);
      Td(a, za(Ud, b));
      mj(Hj(a), b, "key", function (d) {
        13 == d.keyCode && (d.stopPropagation(), d.preventDefault(), c(d));
      });
    }

    function Lj(a, b, c) {
      b = new gj(b);
      Td(a, za(Ud, b));
      mj(Hj(a), b, "focusin", c);
    }

    function Mj(a, b, c) {
      b = new gj(b);
      Td(a, za(Ud, b));
      mj(Hj(a), b, "focusout", c);
    }

    function O(a, b, c) {
      b = new cj(b);
      Td(a, za(Ud, b));
      mj(Hj(a), b, "action", function (d) {
        d.stopPropagation();
        d.preventDefault();
        c(d);
      });
    }

    function Nj(a) {
      Ui(a, "firebaseui-hidden");
    }

    function Oj(a, b) {
      b && ad(a, b);
      Vi(a, "firebaseui-hidden");
    }

    function Pj(a) {
      return !Ti(a, "firebaseui-hidden") && "none" != a.style.display;
    }

    function Qj(a) {
      a = a || {};
      var b = a.email,
          c = a.disabled,
          d = "<div class=\"firebaseui-textfield mdl-textfield mdl-js-textfield mdl-textfield--floating-label\"><label class=\"mdl-textfield__label firebaseui-label\" for=\"ui-sign-in-email-input\">";
      d = a.wc ? d + "Enter new email address" : d + "Email";
      d += "</label><input type=\"email\" name=\"email\" id=\"ui-sign-in-email-input\" autocomplete=\"username\" class=\"mdl-textfield__input firebaseui-input firebaseui-id-email\" value=\"" + vd(null != b ? b : "") + "\"" + (c ? "disabled" : "") + "></div><div class=\"firebaseui-error-wrapper\"><p class=\"firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-id-email-error\"></p></div>";
      return B(d);
    }

    function Rj(a) {
      a = a || {};
      a = a.label;
      var b = "<button type=\"submit\" class=\"firebaseui-id-submit firebaseui-button mdl-button mdl-js-button mdl-button--raised mdl-button--colored\">";
      b = a ? b + A(a) : b + "Next";
      return B(b + "</button>");
    }

    function Sj() {
      var a = "" + Rj({
        label: D("Sign In")
      });
      return B(a);
    }

    function Tj() {
      var a = "" + Rj({
        label: D("Save")
      });
      return B(a);
    }

    function Uj() {
      var a = "" + Rj({
        label: D("Continue")
      });
      return B(a);
    }

    function Vj(a) {
      a = a || {};
      a = a.label;
      var b = "<div class=\"firebaseui-new-password-component\"><div class=\"firebaseui-textfield mdl-textfield mdl-js-textfield mdl-textfield--floating-label\"><label class=\"mdl-textfield__label firebaseui-label\" for=\"ui-sign-in-new-password-input\">";
      b = a ? b + A(a) : b + "Choose password";
      return B(b + "</label><input type=\"password\" name=\"newPassword\" id=\"ui-sign-in-new-password-input\" autocomplete=\"new-password\" class=\"mdl-textfield__input firebaseui-input firebaseui-id-new-password\"></div><a href=\"javascript:void(0)\" class=\"firebaseui-input-floating-button firebaseui-id-password-toggle firebaseui-input-toggle-on firebaseui-input-toggle-blur\"></a><div class=\"firebaseui-error-wrapper\"><p class=\"firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-id-new-password-error\"></p></div></div>");
    }

    function Wj() {
      var a = {};
      var b = "<div class=\"firebaseui-textfield mdl-textfield mdl-js-textfield mdl-textfield--floating-label\"><label class=\"mdl-textfield__label firebaseui-label\" for=\"ui-sign-in-password-input\">";
      b = a.current ? b + "Current password" : b + "Password";
      return B(b + "</label><input type=\"password\" name=\"password\" id=\"ui-sign-in-password-input\" autocomplete=\"current-password\" class=\"mdl-textfield__input firebaseui-input firebaseui-id-password\"></div><div class=\"firebaseui-error-wrapper\"><p class=\"firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-id-password-error\"></p></div>");
    }

    function Xj() {
      return B("<a class=\"firebaseui-link firebaseui-id-secondary-link\" href=\"javascript:void(0)\">Trouble signing in?</a>");
    }

    function Yj(a) {
      a = a || {};
      a = a.label;
      var b = "<button class=\"firebaseui-id-secondary-link firebaseui-button mdl-button mdl-js-button mdl-button--primary\">";
      b = a ? b + A(a) : b + "Cancel";
      return B(b + "</button>");
    }

    function Zj(a) {
      var b = "";
      a.F && a.D && (b += "<ul class=\"firebaseui-tos-list firebaseui-tos\"><li class=\"firebaseui-inline-list-item\"><a href=\"javascript:void(0)\" class=\"firebaseui-link firebaseui-tos-link\" target=\"_blank\">Terms of Service</a></li><li class=\"firebaseui-inline-list-item\"><a href=\"javascript:void(0)\" class=\"firebaseui-link firebaseui-pp-link\" target=\"_blank\">Privacy Policy</a></li></ul>");
      return B(b);
    }

    function ak(a) {
      var b = "";
      a.F && a.D && (b += "<p class=\"firebaseui-tos firebaseui-tospp-full-message\">By continuing, you are indicating that you accept our <a href=\"javascript:void(0)\" class=\"firebaseui-link firebaseui-tos-link\" target=\"_blank\">Terms of Service</a> and <a href=\"javascript:void(0)\" class=\"firebaseui-link firebaseui-pp-link\" target=\"_blank\">Privacy Policy</a>.</p>");
      return B(b);
    }

    function bk(a) {
      a = "<div class=\"firebaseui-info-bar firebaseui-id-info-bar\"><p class=\"firebaseui-info-bar-message\">" + A(a.message) + "&nbsp;&nbsp;<a href=\"javascript:void(0)\" class=\"firebaseui-link firebaseui-id-dismiss-info-bar\">Dismiss</a></p></div>";
      return B(a);
    }

    bk.a = "firebaseui.auth.soy2.element.infoBar";

    function ck(a) {
      var b = a.content;
      a = a.Ab;
      return B("<dialog class=\"mdl-dialog firebaseui-dialog firebaseui-id-dialog" + (a ? " " + vd(a) : "") + "\">" + A(b) + "</dialog>");
    }

    function dk(a) {
      var b = a.message;
      return B(ck({
        content: ud("<div class=\"firebaseui-dialog-icon-wrapper\"><div class=\"" + vd(a.Ma) + " firebaseui-dialog-icon\"></div></div><div class=\"firebaseui-progress-dialog-message\">" + A(b) + "</div>")
      }));
    }

    dk.a = "firebaseui.auth.soy2.element.progressDialog";

    function ek(a) {
      var b = "<div class=\"firebaseui-list-box-actions\">";
      a = a.items;

      for (var c = a.length, d = 0; d < c; d++) {
        var e = a[d];
        b += "<button type=\"button\" data-listboxid=\"" + vd(e.id) + "\" class=\"mdl-button firebaseui-id-list-box-dialog-button firebaseui-list-box-dialog-button\">" + (e.Ma ? "<div class=\"firebaseui-list-box-icon-wrapper\"><div class=\"firebaseui-list-box-icon " + vd(e.Ma) + "\"></div></div>" : "") + "<div class=\"firebaseui-list-box-label-wrapper\">" + A(e.label) + "</div></button>";
      }

      b = "" + ck({
        Ab: D("firebaseui-list-box-dialog"),
        content: ud(b + "</div>")
      });
      return B(b);
    }

    ek.a = "firebaseui.auth.soy2.element.listBoxDialog";

    function fk(a) {
      a = a || {};
      return B(a.tb ? "<div class=\"mdl-spinner mdl-spinner--single-color mdl-js-spinner is-active firebaseui-busy-indicator firebaseui-id-busy-indicator\"></div>" : "<div class=\"mdl-progress mdl-js-progress mdl-progress__indeterminate firebaseui-busy-indicator firebaseui-id-busy-indicator\"></div>");
    }

    fk.a = "firebaseui.auth.soy2.element.busyIndicator";

    function gk(a, b) {
      a = a || {};
      a = a.ga;
      return C(a.S ? a.S : b.hb[a.providerId] ? "" + b.hb[a.providerId] : a.providerId && 0 == a.providerId.indexOf("saml.") ? a.providerId.substring(5) : a.providerId && 0 == a.providerId.indexOf("oidc.") ? a.providerId.substring(5) : "" + a.providerId);
    }

    function hk(a) {
      ik(a, "upgradeElement");
    }

    function jk(a) {
      ik(a, "downgradeElements");
    }

    var kk = ["mdl-js-textfield", "mdl-js-progress", "mdl-js-spinner", "mdl-js-button"];

    function ik(a, b) {
      a && window.componentHandler && window.componentHandler[b] && Ha(kk, function (c) {
        if (Ti(a, c)) window.componentHandler[b](a);
        Ha(Uc(c, a), function (d) {
          window.componentHandler[b](d);
        });
      });
    }

    function lk(a, b, c) {
      mk.call(this || _global);
      document.body.appendChild(a);
      a.showModal || window.dialogPolyfill.registerDialog(a);
      a.showModal();
      hk(a);
      b && O(this || _global, a, function (f) {
        var g = a.getBoundingClientRect();
        (f.clientX < g.left || g.left + g.width < f.clientX || f.clientY < g.top || g.top + g.height < f.clientY) && mk.call(this || _global);
      });

      if (!c) {
        var d = this.N().parentElement || this.N().parentNode;

        if (d) {
          var e = this || _global;

          (this || _global).da = function () {
            if (a.open) {
              var f = a.getBoundingClientRect().height,
                  g = d.getBoundingClientRect().height,
                  h = d.getBoundingClientRect().top - document.body.getBoundingClientRect().top,
                  k = d.getBoundingClientRect().left - document.body.getBoundingClientRect().left,
                  p = a.getBoundingClientRect().width,
                  r = d.getBoundingClientRect().width;
              a.style.top = (h + (g - f) / 2).toString() + "px";
              f = k + (r - p) / 2;
              a.style.left = f.toString() + "px";
              a.style.right = (document.body.getBoundingClientRect().width - f - p).toString() + "px";
            } else window.removeEventListener("resize", e.da);
          };

          this.da();
          window.addEventListener("resize", (this || _global).da, !1);
        }
      }
    }

    function mk() {
      var a = nk.call(this || _global);
      a && (jk(a), a.open && a.close(), $c(a), (this || _global).da && window.removeEventListener("resize", (this || _global).da));
    }

    function nk() {
      return Wc("firebaseui-id-dialog");
    }

    function ok() {
      $c(pk.call(this || _global));
    }

    function pk() {
      return M(this || _global, "firebaseui-id-info-bar");
    }

    function qk() {
      return M(this || _global, "firebaseui-id-dismiss-info-bar");
    }

    var rk = {
      xa: {
        "google.com": "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg",
        "github.com": "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/github.svg",
        "facebook.com": "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg",
        "twitter.com": "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/twitter.svg",
        password: "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/mail.svg",
        phone: "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/phone.svg",
        anonymous: "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/anonymous.png",
        "microsoft.com": "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg",
        "yahoo.com": "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/yahoo.svg",
        "apple.com": "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/apple.png",
        saml: "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/saml.svg",
        oidc: "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/oidc.svg"
      },
      wa: {
        "google.com": "#ffffff",
        "github.com": "#333333",
        "facebook.com": "#3b5998",
        "twitter.com": "#55acee",
        password: "#db4437",
        phone: "#02bd7e",
        anonymous: "#f4b400",
        "microsoft.com": "#2F2F2F",
        "yahoo.com": "#720E9E",
        "apple.com": "#000000",
        saml: "#007bff",
        oidc: "#007bff"
      },
      hb: {
        "google.com": "Google",
        "github.com": "GitHub",
        "facebook.com": "Facebook",
        "twitter.com": "Twitter",
        password: "Password",
        phone: "Phone",
        anonymous: "Guest",
        "microsoft.com": "Microsoft",
        "yahoo.com": "Yahoo",
        "apple.com": "Apple"
      }
    };

    function sk(a, b, c) {
      Zd.call(this || _global, a, b);

      for (var d in c) (this || _global)[d] = c[d];
    }

    w(sk, Zd);

    function P(a, b, c, d, e) {
      Gj.call(this || _global, c);
      (this || _global).fb = a;
      (this || _global).eb = b;
      (this || _global).Fa = !1;
      (this || _global).Ga = d || null;
      (this || _global).A = (this || _global).ca = null;
      (this || _global).Z = fb(rk);
      hb((this || _global).Z, e || {});
    }

    w(P, Gj);
    l = P.prototype;

    l.kb = function () {
      var a = id((this || _global).fb, (this || _global).eb, (this || _global).Z, (this || _global).s);
      hk(a);
      (this || _global).g = a;
    };

    l.v = function () {
      P.K.v.call(this || _global);
      Ce(Q(this || _global), new sk("pageEnter", Q(this || _global), {
        pageId: (this || _global).Ga
      }));

      if (this.bb() && (this || _global).Z.F) {
        var a = (this || _global).Z.F;
        O(this || _global, this.bb(), function () {
          a();
        });
      }

      if (this.ab() && (this || _global).Z.D) {
        var b = (this || _global).Z.D;
        O(this || _global, this.ab(), function () {
          b();
        });
      }
    };

    l.ya = function () {
      Ce(Q(this || _global), new sk("pageExit", Q(this || _global), {
        pageId: (this || _global).Ga
      }));
      P.K.ya.call(this || _global);
    };

    l.m = function () {
      window.clearTimeout((this || _global).ca);
      (this || _global).eb = (this || _global).fb = (this || _global).ca = null;
      (this || _global).Fa = !1;
      (this || _global).A = null;
      jk(this.N());
      P.K.m.call(this || _global);
    };

    function tk(a) {
      a.Fa = !0;
      var b = Ti(a.N(), "firebaseui-use-spinner");
      a.ca = window.setTimeout(function () {
        a.N() && null === a.A && (a.A = id(fk, {
          tb: b
        }, null, a.s), a.N().appendChild(a.A), hk(a.A));
      }, 500);
    }

    l.I = function (a, b, c, d) {
      function e() {
        if (f.T) return null;
        f.Fa = !1;
        window.clearTimeout(f.ca);
        f.ca = null;
        f.A && (jk(f.A), $c(f.A), f.A = null);
      }

      var f = this || _global;
      if (f.Fa) return null;
      tk(f);
      return a.apply(null, b).then(c, d).then(e, e);
    };

    function Q(a) {
      return a.N().parentElement || a.N().parentNode;
    }

    function uk(a, b, c) {
      Kj(a, b, function () {
        c.focus();
      });
    }

    function vk(a, b, c) {
      Kj(a, b, function () {
        c();
      });
    }

    u(P.prototype, {
      a: function (a) {
        ok.call(this || _global);
        var b = id(bk, {
          message: a
        }, null, (this || _global).s);
        this.N().appendChild(b);
        O(this || _global, qk.call(this || _global), function () {
          $c(b);
        });
      },
      yc: ok,
      Ac: pk,
      zc: qk,
      $: function (a, b) {
        a = id(dk, {
          Ma: a,
          message: b
        }, null, (this || _global).s);
        lk.call(this || _global, a);
      },
      h: mk,
      Cb: nk,
      Cc: function () {
        return M(this || _global, "firebaseui-tos");
      },
      bb: function () {
        return M(this || _global, "firebaseui-tos-link");
      },
      ab: function () {
        return M(this || _global, "firebaseui-pp-link");
      },
      Dc: function () {
        return M(this || _global, "firebaseui-tos-list");
      }
    });

    function wk(a, b, c) {
      a = a || {};
      b = a.Va;
      var d = a.ia;
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-sign-in\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Sign in with email</h1></div><div class=\"firebaseui-card-content\"><div class=\"firebaseui-relative-wrapper\">" + Qj(a) + "</div></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + (b ? Yj(null) : "") + Rj(null) + "</div></div><div class=\"firebaseui-card-footer\">" + (d ? ak(c) : Zj(c)) + "</div></form></div>";
      return B(a);
    }

    wk.a = "firebaseui.auth.soy2.page.signIn";

    function xk(a, b, c) {
      a = a || {};
      b = a.ia;
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-sign-in\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Sign in</h1></div><div class=\"firebaseui-card-content\">" + Qj(a) + Wj() + "</div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-links\">" + Xj() + "</div><div class=\"firebaseui-form-actions\">" + Sj() + "</div></div><div class=\"firebaseui-card-footer\">" + (b ? ak(c) : Zj(c)) + "</div></form></div>";
      return B(a);
    }

    xk.a = "firebaseui.auth.soy2.page.passwordSignIn";

    function yk(a, b, c) {
      a = a || {};
      var d = a.Tb;
      b = a.Ta;
      var e = a.ia,
          f = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-sign-up\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Create account</h1></div><div class=\"firebaseui-card-content\">" + Qj(a);
      d ? (a = a || {}, a = a.name, a = "<div class=\"firebaseui-textfield mdl-textfield mdl-js-textfield mdl-textfield--floating-label\"><label class=\"mdl-textfield__label firebaseui-label\" for=\"ui-sign-in-name-input\">First &amp; last name</label><input type=\"text\" name=\"name\" id=\"ui-sign-in-name-input\" autocomplete=\"name\" class=\"mdl-textfield__input firebaseui-input firebaseui-id-name\" value=\"" + vd(null != a ? a : "") + "\"></div><div class=\"firebaseui-error-wrapper\"><p class=\"firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-id-name-error\"></p></div>", a = B(a)) : a = "";
      c = f + a + Vj(null) + "</div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + (b ? Yj(null) : "") + Tj() + "</div></div><div class=\"firebaseui-card-footer\">" + (e ? ak(c) : Zj(c)) + "</div></form></div>";
      return B(c);
    }

    yk.a = "firebaseui.auth.soy2.page.passwordSignUp";

    function zk(a, b, c) {
      a = a || {};
      b = a.Ta;
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-recovery\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Recover password</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">Get instructions sent to this email that explain how to reset your password</p>" + Qj(a) + "</div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + (b ? Yj(null) : "") + Rj({
        label: D("Send")
      }) + "</div></div><div class=\"firebaseui-card-footer\">" + Zj(c) + "</div></form></div>";
      return B(a);
    }

    zk.a = "firebaseui.auth.soy2.page.passwordRecovery";

    function Ak(a, b, c) {
      b = a.G;
      var d = "";
      a = "Follow the instructions sent to <strong>" + (A(a.email) + "</strong> to recover your password");
      d += "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-recovery-email-sent\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Check your email</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">" + a + "</p></div><div class=\"firebaseui-card-actions\">";
      b && (d += "<div class=\"firebaseui-form-actions\">" + Rj({
        label: D("Done")
      }) + "</div>");
      d += "</div><div class=\"firebaseui-card-footer\">" + Zj(c) + "</div></div>";
      return B(d);
    }

    Ak.a = "firebaseui.auth.soy2.page.passwordRecoveryEmailSent";

    function Bk(a, b, c) {
      return B("<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-callback\"><div class=\"firebaseui-callback-indicator-container\">" + fk(null, null, c) + "</div></div>");
    }

    Bk.a = "firebaseui.auth.soy2.page.callback";

    function Ck(a, b, c) {
      return B("<div class=\"firebaseui-container firebaseui-id-page-spinner\">" + fk({
        tb: !0
      }, null, c) + "</div>");
    }

    Ck.a = "firebaseui.auth.soy2.page.spinner";

    function Dk() {
      return B("<div class=\"firebaseui-container firebaseui-id-page-blank firebaseui-use-spinner\"></div>");
    }

    Dk.a = "firebaseui.auth.soy2.page.blank";

    function Ek(a, b, c) {
      b = "";
      a = "A sign-in email with additional instructions was sent to <strong>" + (A(a.email) + "</strong>. Check your email to complete sign-in.");
      var d = B("<a class=\"firebaseui-link firebaseui-id-trouble-getting-email-link\" href=\"javascript:void(0)\">Trouble getting email?</a>");
      b += "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-link-sign-in-sent\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Sign-in email sent</h1></div><div class=\"firebaseui-card-content\"><div class=\"firebaseui-email-sent\"></div><p class=\"firebaseui-text\">" + a + "</p></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-links\">" + d + "</div><div class=\"firebaseui-form-actions\">" + Yj({
        label: D("Back")
      }) + "</div></div><div class=\"firebaseui-card-footer\">" + Zj(c) + "</div></form></div>";
      return B(b);
    }

    Ek.a = "firebaseui.auth.soy2.page.emailLinkSignInSent";

    function Fk(a, b, c) {
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-not-received\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Trouble getting email?</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">Try these common fixes:<ul><li>Check if the email was marked as spam or filtered.</li><li>Check your internet connection.</li><li>Check that you did not misspell your email.</li><li>Check that your inbox space is not running out or other inbox settings related issues.</li></ul></p><p class=\"firebaseui-text\">If the steps above didn't work, you can resend the email. Note that this will deactivate the link in the older email.</p></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-links\">" + B("<a class=\"firebaseui-link firebaseui-id-resend-email-link\" href=\"javascript:void(0)\">Resend</a>") + "</div><div class=\"firebaseui-form-actions\">" + Yj({
        label: D("Back")
      }) + "</div></div><div class=\"firebaseui-card-footer\">" + Zj(c) + "</div></form></div>";
      return B(a);
    }

    Fk.a = "firebaseui.auth.soy2.page.emailNotReceived";

    function Gk(a, b, c) {
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-link-sign-in-confirmation\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Confirm email</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">Confirm your email to complete sign in</p><div class=\"firebaseui-relative-wrapper\">" + Qj(a) + "</div></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + Yj(null) + Rj(null) + "</div></div><div class=\"firebaseui-card-footer\">" + Zj(c) + "</div></form></div>";
      return B(a);
    }

    Gk.a = "firebaseui.auth.soy2.page.emailLinkSignInConfirmation";

    function Hk() {
      var a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-different-device-error\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">New device or browser detected</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">Try opening the link using the same device or browser where you started the sign-in process.</p></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + Yj({
        label: D("Dismiss")
      }) + "</div></div></div>";
      return B(a);
    }

    Hk.a = "firebaseui.auth.soy2.page.differentDeviceError";

    function Ik() {
      var a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-anonymous-user-mismatch\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Session ended</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">The session associated with this sign-in request has either expired or was cleared.</p></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + Yj({
        label: D("Dismiss")
      }) + "</div></div></div>";
      return B(a);
    }

    Ik.a = "firebaseui.auth.soy2.page.anonymousUserMismatch";

    function Jk(a, b, c) {
      b = "";
      a = "You\u2019ve already used <strong>" + (A(a.email) + "</strong> to sign in. Enter your password for that account.");
      b += "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-linking\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Sign in</h1></div><div class=\"firebaseui-card-content\"><h2 class=\"firebaseui-subtitle\">You already have an account</h2><p class=\"firebaseui-text\">" + a + "</p>" + Wj() + "</div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-links\">" + Xj() + "</div><div class=\"firebaseui-form-actions\">" + Sj() + "</div></div><div class=\"firebaseui-card-footer\">" + Zj(c) + "</div></form></div>";
      return B(b);
    }

    Jk.a = "firebaseui.auth.soy2.page.passwordLinking";

    function Kk(a, b, c) {
      var d = a.email;
      b = "";
      a = "" + gk(a, c);
      a = D(a);
      d = "You\u2019ve already used <strong>" + (A(d) + ("</strong>. You can connect your <strong>" + (A(a) + ("</strong> account with <strong>" + (A(d) + "</strong> by signing in with email link below.")))));
      a = "For this flow to successfully connect your " + (A(a) + " account with this email, you have to open the link on the same device or browser.");
      b += "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-link-sign-in-linking\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Sign in</h1></div><div class=\"firebaseui-card-content\"><h2 class=\"firebaseui-subtitle\">You already have an account</h2><p class=\"firebaseui-text firebaseui-text-justify\">" + d + "<p class=\"firebaseui-text firebaseui-text-justify\">" + a + "</p></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + Sj() + "</div></div><div class=\"firebaseui-card-footer\">" + Zj(c) + "</div></form></div>";
      return B(b);
    }

    Kk.a = "firebaseui.auth.soy2.page.emailLinkSignInLinking";

    function Lk(a, b, c) {
      b = "";
      var d = "" + gk(a, c);
      d = D(d);
      a = "You originally intended to connect <strong>" + (A(d) + "</strong> to your email account but have opened the link on a different device where you are not signed in.");
      d = "If you still want to connect your <strong>" + (A(d) + "</strong> account, open the link on the same device where you started sign-in. Otherwise, tap Continue to sign-in on this device.");
      b += "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-link-sign-in-linking-different-device\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Sign in</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text firebaseui-text-justify\">" + a + "</p><p class=\"firebaseui-text firebaseui-text-justify\">" + d + "</p></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + Uj() + "</div></div><div class=\"firebaseui-card-footer\">" + Zj(c) + "</div></form></div>";
      return B(b);
    }

    Lk.a = "firebaseui.auth.soy2.page.emailLinkSignInLinkingDifferentDevice";

    function Mk(a, b, c) {
      var d = a.email;
      b = "";
      a = "" + gk(a, c);
      a = D(a);
      d = "You\u2019ve already used <strong>" + (A(d) + ("</strong>. Sign in with " + (A(a) + " to continue.")));
      b += "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-federated-linking\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Sign in</h1></div><div class=\"firebaseui-card-content\"><h2 class=\"firebaseui-subtitle\">You already have an account</h2><p class=\"firebaseui-text\">" + d + "</p></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + Rj({
        label: D("Sign in with " + a)
      }) + "</div></div><div class=\"firebaseui-card-footer\">" + Zj(c) + "</div></form></div>";
      return B(b);
    }

    Mk.a = "firebaseui.auth.soy2.page.federatedLinking";

    function Nk(a, b, c) {
      a = a || {};
      var d = a.email;
      b = a.yb;
      a = a.Eb;
      var e = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-unauthorized-user\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Not Authorized</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">";
      d ? (d = "<strong>" + (A(d) + "</strong> is not authorized to view the requested page."), e += d) : e += "User is not authorized to view the requested page.";
      e += "</p>";
      b && (b = "Please contact <strong>" + (A(b) + "</strong> for authorization."), e += "<p class=\"firebaseui-text firebaseui-id-unauthorized-user-admin-email\">" + b + "</p>");
      e += "</div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-links\">";
      a && (e += "<a class=\"firebaseui-link firebaseui-id-unauthorized-user-help-link\" href=\"javascript:void(0)\" target=\"_blank\">Learn More</a>");
      e += "</div><div class=\"firebaseui-form-actions\">" + Yj({
        label: D("Back")
      }) + "</div></div><div class=\"firebaseui-card-footer\">" + Zj(c) + "</div></form></div>";
      return B(e);
    }

    Nk.a = "firebaseui.auth.soy2.page.unauthorizedUser";

    function Ok(a, b, c) {
      b = "";
      a = "To continue sign in with <strong>" + (A(a.email) + "</strong> on this device, you have to recover the password.");
      b += "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-unsupported-provider\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Sign in</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">" + a + "</p></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + Yj(null) + Rj({
        label: D("Recover password")
      }) + "</div></div><div class=\"firebaseui-card-footer\">" + Zj(c) + "</div></form></div>";
      return B(b);
    }

    Ok.a = "firebaseui.auth.soy2.page.unsupportedProvider";

    function Pk(a) {
      var b = "",
          c = "<p class=\"firebaseui-text\">for <strong>" + (A(a.email) + "</strong></p>");
      b += "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-reset\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Reset your password</h1></div><div class=\"firebaseui-card-content\">" + c + Vj(td(a)) + "</div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + Tj() + "</div></div></form></div>";
      return B(b);
    }

    Pk.a = "firebaseui.auth.soy2.page.passwordReset";

    function Qk(a) {
      a = a || {};
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-reset-success\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Password changed</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">You can now sign in with your new password</p></div><div class=\"firebaseui-card-actions\">" + (a.G ? "<div class=\"firebaseui-form-actions\">" + Uj() + "</div>" : "") + "</div></div>";
      return B(a);
    }

    Qk.a = "firebaseui.auth.soy2.page.passwordResetSuccess";

    function Rk(a) {
      a = a || {};
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-password-reset-failure\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Try resetting your password again</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">Your request to reset your password has expired or the link has already been used</p></div><div class=\"firebaseui-card-actions\">" + (a.G ? "<div class=\"firebaseui-form-actions\">" + Uj() + "</div>" : "") + "</div></div>";
      return B(a);
    }

    Rk.a = "firebaseui.auth.soy2.page.passwordResetFailure";

    function Sk(a) {
      var b = a.G,
          c = "";
      a = "Your sign-in email address has been changed back to <strong>" + (A(a.email) + "</strong>.");
      c += "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-change-revoke-success\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Updated email address</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">" + a + "</p><p class=\"firebaseui-text\">If you didn\u2019t ask to change your sign-in email, it\u2019s possible someone is trying to access your account and you should <a class=\"firebaseui-link firebaseui-id-reset-password-link\" href=\"javascript:void(0)\">change your password right away</a>.</p></div><div class=\"firebaseui-card-actions\">" + (b ? "<div class=\"firebaseui-form-actions\">" + Uj() + "</div>" : "") + "</div></form></div>";
      return B(c);
    }

    Sk.a = "firebaseui.auth.soy2.page.emailChangeRevokeSuccess";

    function Tk(a) {
      a = a || {};
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-change-revoke-failure\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Unable to update your email address</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">There was a problem changing your sign-in email back.</p><p class=\"firebaseui-text\">If you try again and still can\u2019t reset your email, try asking your administrator for help.</p></div><div class=\"firebaseui-card-actions\">" + (a.G ? "<div class=\"firebaseui-form-actions\">" + Uj() + "</div>" : "") + "</div></div>";
      return B(a);
    }

    Tk.a = "firebaseui.auth.soy2.page.emailChangeRevokeFailure";

    function Uk(a) {
      a = a || {};
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-verification-success\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Your email has been verified</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">You can now sign in with your new account</p></div><div class=\"firebaseui-card-actions\">" + (a.G ? "<div class=\"firebaseui-form-actions\">" + Uj() + "</div>" : "") + "</div></div>";
      return B(a);
    }

    Uk.a = "firebaseui.auth.soy2.page.emailVerificationSuccess";

    function Vk(a) {
      a = a || {};
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-verification-failure\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Try verifying your email again</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">Your request to verify your email has expired or the link has already been used</p></div><div class=\"firebaseui-card-actions\">" + (a.G ? "<div class=\"firebaseui-form-actions\">" + Uj() + "</div>" : "") + "</div></div>";
      return B(a);
    }

    Vk.a = "firebaseui.auth.soy2.page.emailVerificationFailure";

    function Wk(a) {
      var b = a.G,
          c = "";
      a = "You can now sign in with your new email <strong>" + (A(a.email) + "</strong>.");
      c += "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-verify-and-change-email-success\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Your email has been verified and changed</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">" + a + "</p></div><div class=\"firebaseui-card-actions\">" + (b ? "<div class=\"firebaseui-form-actions\">" + Uj() + "</div>" : "") + "</div></div>";
      return B(c);
    }

    Wk.a = "firebaseui.auth.soy2.page.verifyAndChangeEmailSuccess";

    function Xk(a) {
      a = a || {};
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-verify-and-change-email-failure\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Try updating your email again</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">Your request to verify and update your email has expired or the link has already been used.</p></div><div class=\"firebaseui-card-actions\">" + (a.G ? "<div class=\"firebaseui-form-actions\">" + Uj() + "</div>" : "") + "</div></div>";
      return B(a);
    }

    Xk.a = "firebaseui.auth.soy2.page.verifyAndChangeEmailFailure";

    function Zk(a) {
      var b = a.factorId,
          c = a.phoneNumber;
      a = a.G;
      var d = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-revert-second-factor-addition-success\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Removed second factor</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">";

      switch (b) {
        case "phone":
          b = "The <strong>" + (A(b) + (" " + (A(c) + "</strong> was removed as a second authentication step.")));
          d += b;
          break;

        default:
          d += "The device or app was removed as a second authentication step.";
      }

      d += "</p><p class=\"firebaseui-text\">If you don't recognize this device, someone might be trying to access your account. Consider <a class=\"firebaseui-link firebaseui-id-reset-password-link\" href=\"javascript:void(0)\">changing your password right away</a>.</p></div><div class=\"firebaseui-card-actions\">" + (a ? "<div class=\"firebaseui-form-actions\">" + Uj() + "</div>" : "") + "</div></form></div>";
      return B(d);
    }

    Zk.a = "firebaseui.auth.soy2.page.revertSecondFactorAdditionSuccess";

    function $k(a) {
      a = a || {};
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-revert-second-factor-addition-failure\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Couldn't remove your second factor</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">Something went wrong removing your second factor.</p><p class=\"firebaseui-text\">Try removing it again. If that doesn't work, contact support for assistance.</p></div><div class=\"firebaseui-card-actions\">" + (a.G ? "<div class=\"firebaseui-form-actions\">" + Uj() + "</div>" : "") + "</div></div>";
      return B(a);
    }

    $k.a = "firebaseui.auth.soy2.page.revertSecondFactorAdditionFailure";

    function al(a) {
      var b = a.zb;
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-recoverable-error\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Error encountered</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">" + A(a.errorMessage) + "</p></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">";
      b && (a += Rj({
        label: D("Retry")
      }));
      return B(a + "</div></div></div>");
    }

    al.a = "firebaseui.auth.soy2.page.recoverableError";

    function bl(a) {
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-unrecoverable-error\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Error encountered</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">" + A(a.errorMessage) + "</p></div></div>";
      return B(a);
    }

    bl.a = "firebaseui.auth.soy2.page.unrecoverableError";

    function cl(a, b, c) {
      var d = a.Qb;
      b = "";
      a = "Continue with " + (A(a.jc) + "?");
      d = "You originally wanted to sign in with " + A(d);
      b += "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-email-mismatch\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Sign in</h1></div><div class=\"firebaseui-card-content\"><h2 class=\"firebaseui-subtitle\">" + a + "</h2><p class=\"firebaseui-text\">" + d + "</p></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + Yj(null) + Rj({
        label: D("Continue")
      }) + "</div></div><div class=\"firebaseui-card-footer\">" + Zj(c) + "</div></form></div>";
      return B(b);
    }

    cl.a = "firebaseui.auth.soy2.page.emailMismatch";

    function dl(a, b, c) {
      var d = "<div class=\"firebaseui-container firebaseui-page-provider-sign-in firebaseui-id-page-provider-sign-in firebaseui-use-spinner\"><div class=\"firebaseui-card-content\"><form onsubmit=\"return false;\"><ul class=\"firebaseui-idp-list\">";
      a = a.Sb;
      b = a.length;

      for (var e = 0; e < b; e++) {
        var f = {
          ga: a[e]
        },
            g = c;
        f = f || {};
        var h = f.ga;
        var k = f;
        k = k || {};
        var p = "";

        switch (k.ga.providerId) {
          case "google.com":
            p += "firebaseui-idp-google";
            break;

          case "github.com":
            p += "firebaseui-idp-github";
            break;

          case "facebook.com":
            p += "firebaseui-idp-facebook";
            break;

          case "twitter.com":
            p += "firebaseui-idp-twitter";
            break;

          case "phone":
            p += "firebaseui-idp-phone";
            break;

          case "anonymous":
            p += "firebaseui-idp-anonymous";
            break;

          case "password":
            p += "firebaseui-idp-password";
            break;

          default:
            p += "firebaseui-idp-generic";
        }

        k = "<button class=\"firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised " + vd(C(p)) + " firebaseui-id-idp-button\" data-provider-id=\"" + vd(h.providerId) + "\" style=\"background-color:";
        p = (p = f) || {};
        p = p.ga;
        k = k + vd(Ed(C(p.ta ? p.ta : g.wa[p.providerId] ? "" + g.wa[p.providerId] : 0 == p.providerId.indexOf("saml.") ? "" + g.wa.saml : 0 == p.providerId.indexOf("oidc.") ? "" + g.wa.oidc : "" + g.wa.password))) + "\"><span class=\"firebaseui-idp-icon-wrapper\"><img class=\"firebaseui-idp-icon\" alt=\"\" src=\"";
        var r = f;
        p = g;
        r = r || {};
        r = r.ga;
        p = sd(r.za ? Ad(r.za) : p.xa[r.providerId] ? Ad(p.xa[r.providerId]) : 0 == r.providerId.indexOf("saml.") ? Ad(p.xa.saml) : 0 == r.providerId.indexOf("oidc.") ? Ad(p.xa.oidc) : Ad(p.xa.password));
        k = k + vd(Ad(p)) + "\"></span>";
        "password" == h.providerId ? (k += "<span class=\"firebaseui-idp-text firebaseui-idp-text-long\">", h.V ? k += A(h.V) : h.S ? (f = "Sign in with " + A(gk(f, g)), k += f) : k += "Sign in with email", k += "</span><span class=\"firebaseui-idp-text firebaseui-idp-text-short\">", k = h.S ? k + A(h.S) : k + "Email", k += "</span>") : "phone" == h.providerId ? (k += "<span class=\"firebaseui-idp-text firebaseui-idp-text-long\">", h.V ? k += A(h.V) : h.S ? (f = "Sign in with " + A(gk(f, g)), k += f) : k += "Sign in with phone", k += "</span><span class=\"firebaseui-idp-text firebaseui-idp-text-short\">", k = h.S ? k + A(h.S) : k + "Phone", k += "</span>") : "anonymous" == h.providerId ? (k += "<span class=\"firebaseui-idp-text firebaseui-idp-text-long\">", h.V ? k += A(h.V) : h.S ? (f = "Sign in with " + A(gk(f, g)), k += f) : k += "Continue as guest", k += "</span><span class=\"firebaseui-idp-text firebaseui-idp-text-short\">", k = h.S ? k + A(h.S) : k + "Guest", k += "</span>") : (k += "<span class=\"firebaseui-idp-text firebaseui-idp-text-long\">", h.V ? k += A(h.V) : (p = "Sign in with " + A(gk(f, g)), k += p), k += "</span><span class=\"firebaseui-idp-text firebaseui-idp-text-short\">" + (h.S ? A(h.S) : A(gk(f, g))) + "</span>");
        h = B(k + "</button>");
        d += "<li class=\"firebaseui-list-item\">" + h + "</li>";
      }

      d += "</ul></form></div><div class=\"firebaseui-card-footer firebaseui-provider-sign-in-footer\">" + ak(c) + "</div></div>";
      return B(d);
    }

    dl.a = "firebaseui.auth.soy2.page.providerSignIn";

    function el(a, b, c) {
      a = a || {};
      var d = a.Gb,
          e = a.Va;
      b = a.ia;
      a = a || {};
      a = a.Aa;
      a = "<div class=\"firebaseui-phone-number\"><button class=\"firebaseui-id-country-selector firebaseui-country-selector mdl-button mdl-js-button\"><span class=\"firebaseui-flag firebaseui-country-selector-flag firebaseui-id-country-selector-flag\"></span><span class=\"firebaseui-id-country-selector-code\"></span></button><div class=\"mdl-textfield mdl-js-textfield mdl-textfield--floating-label firebaseui-textfield firebaseui-phone-input-wrapper\"><label class=\"mdl-textfield__label firebaseui-label\" for=\"ui-sign-in-phone-number-input\">Phone number</label><input type=\"tel\" name=\"phoneNumber\" id=\"ui-sign-in-phone-number-input\" class=\"mdl-textfield__input firebaseui-input firebaseui-id-phone-number\" value=\"" + vd(null != a ? a : "") + "\"></div></div><div class=\"firebaseui-error-wrapper\"><p class=\"firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-phone-number-error firebaseui-id-phone-number-error\"></p></div>";
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-phone-sign-in-start\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Enter your phone number</h1></div><div class=\"firebaseui-card-content\"><div class=\"firebaseui-relative-wrapper\">" + B(a);
      var f;
      d ? f = B("<div class=\"firebaseui-recaptcha-wrapper\"><div class=\"firebaseui-recaptcha-container\"></div><div class=\"firebaseui-error-wrapper firebaseui-recaptcha-error-wrapper\"><p class=\"firebaseui-error firebaseui-hidden firebaseui-id-recaptcha-error\"></p></div></div>") : f = "";
      f = a + f + "</div></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + (e ? Yj(null) : "") + Rj({
        label: D("Verify")
      }) + "</div></div><div class=\"firebaseui-card-footer\">";
      b ? (b = "<p class=\"firebaseui-tos firebaseui-phone-tos\">", b = c.F && c.D ? b + "By tapping Verify, you are indicating that you accept our <a href=\"javascript:void(0)\" class=\"firebaseui-link firebaseui-tos-link\" target=\"_blank\">Terms of Service</a> and <a href=\"javascript:void(0)\" class=\"firebaseui-link firebaseui-pp-link\" target=\"_blank\">Privacy Policy</a>. An SMS may be sent. Message &amp; data rates may apply." : b + "By tapping Verify, an SMS may be sent. Message &amp; data rates may apply.", c = B(b + "</p>")) : c = B("<p class=\"firebaseui-tos firebaseui-phone-sms-notice\">By tapping Verify, an SMS may be sent. Message &amp; data rates may apply.</p>") + Zj(c);
      return B(f + c + "</div></form></div>");
    }

    el.a = "firebaseui.auth.soy2.page.phoneSignInStart";

    function fl(a, b, c) {
      a = a || {};
      b = a.phoneNumber;
      var d = "";
      a = "Enter the 6-digit code we sent to <a class=\"firebaseui-link firebaseui-change-phone-number-link firebaseui-id-change-phone-number-link\" href=\"javascript:void(0)\">&lrm;" + (A(b) + "</a>");
      A(b);
      b = d;
      d = B("<div class=\"firebaseui-textfield mdl-textfield mdl-js-textfield mdl-textfield--floating-label\"><label class=\"mdl-textfield__label firebaseui-label\" for=\"ui-sign-in-phone-confirmation-code-input\">6-digit code</label><input type=\"number\" name=\"phoneConfirmationCode\" id=\"ui-sign-in-phone-confirmation-code-input\" class=\"mdl-textfield__input firebaseui-input firebaseui-id-phone-confirmation-code\"></div><div class=\"firebaseui-error-wrapper\"><p class=\"firebaseui-error firebaseui-text-input-error firebaseui-hidden firebaseui-id-phone-confirmation-code-error\"></p></div>");
      c = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-phone-sign-in-finish\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Verify your phone number</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">" + a + "</p>" + d + "</div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + Yj(null) + Rj({
        label: D("Continue")
      }) + "</div></div><div class=\"firebaseui-card-footer\">" + Zj(c) + "</div></form>";
      a = B("<div class=\"firebaseui-resend-container\"><span class=\"firebaseui-id-resend-countdown\"></span><a href=\"javascript:void(0)\" class=\"firebaseui-id-resend-link firebaseui-hidden firebaseui-link\">Resend</a></div>");
      return B(b + (c + a + "</div>"));
    }

    fl.a = "firebaseui.auth.soy2.page.phoneSignInFinish";

    function gl() {
      return B("<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-sign-out\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Sign Out</h1></div><div class=\"firebaseui-card-content\"><p class=\"firebaseui-text\">You are now successfully signed out.</p></div></div>");
    }

    gl.a = "firebaseui.auth.soy2.page.signOut";

    function hl(a, b, c) {
      var d = "<div class=\"firebaseui-container firebaseui-page-select-tenant firebaseui-id-page-select-tenant\"><div class=\"firebaseui-card-content\"><form onsubmit=\"return false;\"><ul class=\"firebaseui-tenant-list\">";
      a = a.ec;
      b = a.length;

      for (var e = 0; e < b; e++) {
        var f = a[e];
        var g = "";
        var h = A(f.displayName),
            k = f.tenantId ? f.tenantId : "top-level-project";
        k = D(k);
        g += "<button class=\"firebaseui-tenant-button mdl-button mdl-js-button mdl-button--raised firebaseui-tenant-selection-" + vd(k) + " firebaseui-id-tenant-selection-button\"" + (f.tenantId ? "data-tenant-id=\"" + vd(f.tenantId) + "\"" : "") + "style=\"background-color:" + vd(Ed(f.ta)) + "\"><span class=\"firebaseui-idp-icon-wrapper\"><img class=\"firebaseui-idp-icon\" alt=\"\" src=\"" + vd(Ad(f.za)) + "\"></span><span class=\"firebaseui-idp-text firebaseui-idp-text-long\">";
        f.V ? g += A(f.V) : (f = "Sign in to " + A(f.displayName), g += f);
        g = B(g + ("</span><span class=\"firebaseui-idp-text firebaseui-idp-text-short\">" + h + "</span></button>"));
        d += "<li class=\"firebaseui-list-item\">" + g + "</li>";
      }

      d += "</ul></form></div><div class=\"firebaseui-card-footer firebaseui-provider-sign-in-footer\">" + ak(c) + "</div></div>";
      return B(d);
    }

    hl.a = "firebaseui.auth.soy2.page.selectTenant";

    function il(a, b, c) {
      a = "<div class=\"mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-provider-match-by-email\"><form onsubmit=\"return false;\"><div class=\"firebaseui-card-header\"><h1 class=\"firebaseui-title\">Sign in</h1></div><div class=\"firebaseui-card-content\"><div class=\"firebaseui-relative-wrapper\">" + Qj(null) + "</div></div><div class=\"firebaseui-card-actions\"><div class=\"firebaseui-form-actions\">" + Rj(null) + "</div></div><div class=\"firebaseui-card-footer\">" + ak(c) + "</div></form></div>";
      return B(a);
    }

    il.a = "firebaseui.auth.soy2.page.providerMatchByEmail";

    function jl() {
      return M(this || _global, "firebaseui-id-submit");
    }

    function kl() {
      return M(this || _global, "firebaseui-id-secondary-link");
    }

    function ll(a, b) {
      O(this || _global, jl.call(this || _global), function (d) {
        a(d);
      });
      var c = kl.call(this || _global);
      c && b && O(this || _global, c, function (d) {
        b(d);
      });
    }

    function ml() {
      return M(this || _global, "firebaseui-id-password");
    }

    function nl() {
      return M(this || _global, "firebaseui-id-password-error");
    }

    function ol() {
      var a = ml.call(this || _global),
          b = nl.call(this || _global);
      Jj(this || _global, a, function () {
        Pj(b) && (N(a, !0), Nj(b));
      });
    }

    function pl() {
      var a = ml.call(this || _global);
      var b = nl.call(this || _global);
      Wi(a) ? (N(a, !0), Nj(b), b = !0) : (N(a, !1), Oj(b, C("Enter your password").toString()), b = !1);
      return b ? Wi(a) : null;
    }

    function ql(a, b, c, d, e, f) {
      P.call(this || _global, Jk, {
        email: a
      }, f, "passwordLinking", {
        F: d,
        D: e
      });
      (this || _global).w = b;
      (this || _global).H = c;
    }

    m(ql, P);

    ql.prototype.v = function () {
      this.P();
      this.M((this || _global).w, (this || _global).H);
      vk(this || _global, this.i(), (this || _global).w);
      this.i().focus();
      P.prototype.v.call(this || _global);
    };

    ql.prototype.m = function () {
      (this || _global).w = null;
      P.prototype.m.call(this || _global);
    };

    ql.prototype.j = function () {
      return Wi(M(this || _global, "firebaseui-id-email"));
    };

    u(ql.prototype, {
      i: ml,
      B: nl,
      P: ol,
      u: pl,
      ea: jl,
      ba: kl,
      M: ll
    });
    var rl = /^[+a-zA-Z0-9_.!#$%&'*\/=?^`{|}~-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9]{2,63}$/;

    function sl() {
      return M(this || _global, "firebaseui-id-email");
    }

    function tl() {
      return M(this || _global, "firebaseui-id-email-error");
    }

    function ul(a) {
      var b = sl.call(this || _global),
          c = tl.call(this || _global);
      Jj(this || _global, b, function () {
        Pj(c) && (N(b, !0), Nj(c));
      });
      a && Kj(this || _global, b, function () {
        a();
      });
    }

    function vl() {
      return Va(Wi(sl.call(this || _global)) || "");
    }

    function wl() {
      var a = sl.call(this || _global);
      var b = tl.call(this || _global);
      var c = Wi(a) || "";
      c ? rl.test(c) ? (N(a, !0), Nj(b), b = !0) : (N(a, !1), Oj(b, C("That email address isn't correct").toString()), b = !1) : (N(a, !1), Oj(b, C("Enter your email address to continue").toString()), b = !1);
      return b ? Va(Wi(a)) : null;
    }

    function xl(a, b, c, d, e, f, g) {
      P.call(this || _global, xk, {
        email: c,
        ia: !!f
      }, g, "passwordSignIn", {
        F: d,
        D: e
      });
      (this || _global).w = a;
      (this || _global).H = b;
    }

    m(xl, P);

    xl.prototype.v = function () {
      this.P();
      this.ea();
      this.ba((this || _global).w, (this || _global).H);
      uk(this || _global, this.l(), this.i());
      vk(this || _global, this.i(), (this || _global).w);
      Wi(this.l()) ? this.i().focus() : this.l().focus();
      P.prototype.v.call(this || _global);
    };

    xl.prototype.m = function () {
      (this || _global).H = (this || _global).w = null;
      P.prototype.m.call(this || _global);
    };

    u(xl.prototype, {
      l: sl,
      U: tl,
      P: ul,
      M: vl,
      j: wl,
      i: ml,
      B: nl,
      ea: ol,
      u: pl,
      ua: jl,
      pa: kl,
      ba: ll
    });

    function R(a, b, c, d, e, f) {
      P.call(this || _global, a, b, d, e || "notice", f);
      (this || _global).i = c || null;
    }

    w(R, P);

    R.prototype.v = function () {
      (this || _global).i && (this.u((this || _global).i), this.l().focus());
      R.K.v.call(this || _global);
    };

    R.prototype.m = function () {
      (this || _global).i = null;
      R.K.m.call(this || _global);
    };

    u(R.prototype, {
      l: jl,
      w: kl,
      u: ll
    });

    function yl(a, b, c, d, e) {
      R.call(this || _global, Ak, {
        email: a,
        G: !!b
      }, b, e, "passwordRecoveryEmailSent", {
        F: c,
        D: d
      });
    }

    w(yl, R);

    function zl(a, b) {
      R.call(this || _global, Uk, {
        G: !!a
      }, a, b, "emailVerificationSuccess");
    }

    w(zl, R);

    function Al(a, b) {
      R.call(this || _global, Vk, {
        G: !!a
      }, a, b, "emailVerificationFailure");
    }

    w(Al, R);

    function Bl(a, b, c) {
      R.call(this || _global, Wk, {
        email: a,
        G: !!b
      }, b, c, "verifyAndChangeEmailSuccess");
    }

    w(Bl, R);

    function Cl(a, b) {
      R.call(this || _global, Xk, {
        G: !!a
      }, a, b, "verifyAndChangeEmailFailure");
    }

    w(Cl, R);

    function Dl(a, b) {
      R.call(this || _global, $k, {
        G: !!a
      }, a, b, "revertSecondFactorAdditionFailure");
    }

    w(Dl, R);

    function El(a) {
      R.call(this || _global, gl, void 0, void 0, a, "signOut");
    }

    w(El, R);

    function Fl(a, b) {
      R.call(this || _global, Qk, {
        G: !!a
      }, a, b, "passwordResetSuccess");
    }

    w(Fl, R);

    function Gl(a, b) {
      R.call(this || _global, Rk, {
        G: !!a
      }, a, b, "passwordResetFailure");
    }

    w(Gl, R);

    function Hl(a, b) {
      R.call(this || _global, Tk, {
        G: !!a
      }, a, b, "emailChangeRevokeFailure");
    }

    w(Hl, R);

    function Il(a, b, c) {
      R.call(this || _global, al, {
        errorMessage: a,
        zb: !!b
      }, b, c, "recoverableError");
    }

    w(Il, R);

    function Jl(a, b) {
      R.call(this || _global, bl, {
        errorMessage: a
      }, void 0, b, "unrecoverableError");
    }

    w(Jl, R);

    function Kl(a) {
      if ("auth/invalid-credential" === a.code && a.message && -1 !== a.message.indexOf("error=consent_required")) return {
        code: "auth/user-cancelled"
      };

      if (a.message && -1 !== a.message.indexOf("HTTP Cloud Function returned an error:")) {
        var b = JSON.parse(a.message.substring(a.message.indexOf("{"), a.message.lastIndexOf("}") + 1));
        return {
          code: a.code,
          message: b && b.error && b.error.message || a.message
        };
      }

      return a;
    }

    function Ll(a, b, c, d) {
      function e(g) {
        if (!g.name || "cancel" != g.name) {
          a: {
            var h = g.message;

            try {
              var k = ((JSON.parse(h).error || {}).message || "").toLowerCase().match(/invalid.+(access|id)_token/);

              if (k && k.length) {
                var p = !0;
                break a;
              }
            } catch (r) {}

            p = !1;
          }

          if (p) g = Q(b), b.o(), S(a, g, void 0, C("Your sign-in session has expired. Please try again.").toString());else {
            p = g && g.message || "";

            if (g.code) {
              if ("auth/email-already-in-use" == g.code || "auth/credential-already-in-use" == g.code) return;
              p = T(g);
            }

            b.a(p);
          }
        }
      }

      Ml(a);
      if (d) return Nl(a, c), F();
      if (!c.credential) throw Error("No credential found!");
      if (!U(a).currentUser && !c.user) throw Error("User not logged in.");

      try {
        var f = Ol(a, c);
      } catch (g) {
        return pg(g.code || g.message, g), b.a(g.code || g.message), F();
      }

      c = f.then(function (g) {
        Nl(a, g);
      }, e).then(void 0, e);
      V(a, f);
      return F(c);
    }

    function Nl(a, b) {
      if (!b.user) throw Error("No user found");
      var c = Ki(W(a));
      Ii(W(a)) && c && ug("Both signInSuccess and signInSuccessWithAuthResult callbacks are provided. Only signInSuccessWithAuthResult callback will be invoked.");

      if (c) {
        c = Ki(W(a));
        var d = zh(X(a)) || void 0;
        xh(th, X(a));
        var e = !1;

        if (rf()) {
          if (!c || c(b, d)) e = !0, Oc(window.opener.location, Pl(a, d));
          c || window.close();
        } else if (!c || c(b, d)) e = !0, Oc(window.location, Pl(a, d));

        e || a.reset();
      } else {
        c = b.user;
        b = b.credential;
        d = Ii(W(a));
        e = zh(X(a)) || void 0;
        xh(th, X(a));
        var f = !1;

        if (rf()) {
          if (!d || d(c, b, e)) f = !0, Oc(window.opener.location, Pl(a, e));
          d || window.close();
        } else if (!d || d(c, b, e)) f = !0, Oc(window.location, Pl(a, e));

        f || a.reset();
      }
    }

    function Pl(a, b) {
      a = b || W(a).a.get("signInSuccessUrl");
      if (!a) throw Error("No redirect URL has been found. You must either specify a signInSuccessUrl in the configuration, pass in a redirect URL to the widget URL, or return false from the callback.");
      return a;
    }

    function T(a) {
      var b = {
        code: a.code
      };
      b = b || {};
      var c = "";

      switch (b.code) {
        case "auth/email-already-in-use":
          c += "The email address is already used by another account";
          break;

        case "auth/requires-recent-login":
          c += Nd();
          break;

        case "auth/too-many-requests":
          c += "You have entered an incorrect password too many times. Please try again in a few minutes.";
          break;

        case "auth/user-cancelled":
          c += "Please authorize the required permissions to sign in to the application";
          break;

        case "auth/user-not-found":
          c += "That email address doesn't match an existing account";
          break;

        case "auth/user-token-expired":
          c += Nd();
          break;

        case "auth/weak-password":
          c += "Strong passwords have at least 6 characters and a mix of letters and numbers";
          break;

        case "auth/wrong-password":
          c += "The email and password you entered don't match";
          break;

        case "auth/network-request-failed":
          c += "A network error has occurred";
          break;

        case "auth/invalid-phone-number":
          c += Id();
          break;

        case "auth/invalid-verification-code":
          c += C("Wrong code. Try again.");
          break;

        case "auth/code-expired":
          c += "This code is no longer valid";
          break;

        case "auth/expired-action-code":
          c += "This code has expired.";
          break;

        case "auth/invalid-action-code":
          c += "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.";
      }

      if (b = C(c).toString()) return b;

      try {
        return JSON.parse(a.message), pg("Internal error: " + a.message, void 0), Kd().toString();
      } catch (d) {
        return a.message;
      }
    }

    function Ql(a, b, c) {
      var d = bi[b] && firebase.auth[bi[b]] ? new firebase.auth[bi[b]]() : 0 == b.indexOf("saml.") ? new firebase.auth.SAMLAuthProvider(b) : new firebase.auth.OAuthProvider(b);
      if (!d) throw Error("Invalid Firebase Auth provider!");
      var e = ui(W(a), b);
      if (d.addScope) for (var f = 0; f < e.length; f++) d.addScope(e[f]);
      e = vi(W(a), b) || {};
      c && (b == firebase.auth.GoogleAuthProvider.PROVIDER_ID ? a = "login_hint" : b == firebase.auth.GithubAuthProvider.PROVIDER_ID ? a = "login" : a = (a = ji(W(a), b)) && a.Ob, a && (e[a] = c));
      d.setCustomParameters && d.setCustomParameters(e);
      return d;
    }

    function Rl(a, b, c, d) {
      function e() {
        Eh(new Eg(a.h.tenantId || null), X(a));
        V(a, b.I(t(a.dc, a), [k], function () {
          if ("file:" === (window.location && window.location.protocol)) return V(a, Sl(a).then(function (p) {
            b.o();
            xh(sh, X(a));
            L("callback", a, h, F(p));
          }, f));
        }, g));
      }

      function f(p) {
        xh(sh, X(a));
        if (!p.name || "cancel" != p.name) switch (p = Kl(p), p.code) {
          case "auth/popup-blocked":
            e();
            break;

          case "auth/popup-closed-by-user":
          case "auth/cancelled-popup-request":
            break;

          case "auth/credential-already-in-use":
            break;

          case "auth/network-request-failed":
          case "auth/too-many-requests":
          case "auth/user-cancelled":
            b.a(T(p));
            break;

          default:
            b.o(), L("callback", a, h, ef(p));
        }
      }

      function g(p) {
        xh(sh, X(a));
        p.name && "cancel" == p.name || (pg("signInWithRedirect: " + p.code, void 0), p = T(p), "blank" == b.Ga && Ei(W(a)) ? (b.o(), L("providerSignIn", a, h, p)) : b.a(p));
      }

      var h = Q(b),
          k = Ql(a, c, d);
      Fi(W(a)) == Gi ? e() : V(a, Tl(a, k).then(function (p) {
        b.o();
        L("callback", a, h, F(p));
      }, f));
    }

    function Ul(a, b) {
      V(a, b.I(t(a.$b, a), [], function (c) {
        b.o();
        return Ll(a, b, c, !0);
      }, function (c) {
        c.name && "cancel" == c.name || (pg("ContinueAsGuest: " + c.code, void 0), c = T(c), b.a(c));
      }));
    }

    function Vl(a, b, c) {
      function d(f) {
        var g = !1;
        f = b.I(t(a.ac, a), [f], function (h) {
          var k = Q(b);
          b.o();
          L("callback", a, k, F(h));
          g = !0;
        }, function (h) {
          if (!h.name || "cancel" != h.name) if (!h || "auth/credential-already-in-use" != h.code) if (h && "auth/email-already-in-use" == h.code && h.email && h.credential) {
            var k = Q(b);
            b.o();
            L("callback", a, k, ef(h));
          } else h = T(h), b.a(h);
        });
        V(a, f);
        return f.then(function () {
          return g;
        }, function () {
          return !1;
        });
      }

      if (c && c.credential && c.clientId === mi(W(a))) {
        if (ui(W(a), firebase.auth.GoogleAuthProvider.PROVIDER_ID).length) {
          try {
            var e = JSON.parse(atob(c.credential.split(".")[1])).email;
          } catch (f) {}

          Rl(a, b, firebase.auth.GoogleAuthProvider.PROVIDER_ID, e);
          return F(!0);
        }

        return d(firebase.auth.GoogleAuthProvider.credential(c.credential));
      }

      c && b.a(C("The selected credential for the authentication provider is not supported!").toString());
      return F(!1);
    }

    function Wl(a, b) {
      var c = b.j(),
          d = b.u();
      if (c) {
        if (d) {
          var e = firebase.auth.EmailAuthProvider.credential(c, d);
          V(a, b.I(t(a.bc, a), [c, d], function (f) {
            return Ll(a, b, {
              user: f.user,
              credential: e,
              operationType: f.operationType,
              additionalUserInfo: f.additionalUserInfo
            });
          }, function (f) {
            if (!f.name || "cancel" != f.name) switch (f.code) {
              case "auth/email-already-in-use":
                break;

              case "auth/email-exists":
                N(b.l(), !1);
                Oj(b.U(), T(f));
                break;

              case "auth/too-many-requests":
              case "auth/wrong-password":
                N(b.i(), !1);
                Oj(b.B(), T(f));
                break;

              default:
                pg("verifyPassword: " + f.message, void 0), b.a(T(f));
            }
          }));
        } else b.i().focus();
      } else b.l().focus();
    }

    function Xl(a) {
      a = ii(W(a));
      return 1 == a.length && a[0] == firebase.auth.EmailAuthProvider.PROVIDER_ID;
    }

    function Yl(a) {
      a = ii(W(a));
      return 1 == a.length && a[0] == firebase.auth.PhoneAuthProvider.PROVIDER_ID;
    }

    function S(a, b, c, d) {
      Xl(a) ? d ? L("signIn", a, b, c, d) : Zl(a, b, c) : a && Yl(a) && !d ? L("phoneSignInStart", a, b) : a && Ei(W(a)) && !d ? L("federatedRedirect", a, b, c) : L("providerSignIn", a, b, d, c);
    }

    function $l(a, b, c, d) {
      var e = Q(b);
      V(a, b.I(t(U(a).fetchSignInMethodsForEmail, U(a)), [c], function (f) {
        b.o();
        am(a, e, f, c, d);
      }, function (f) {
        f = T(f);
        b.a(f);
      }));
    }

    function am(a, b, c, d, e, f) {
      c.length || Bi(W(a)) && !Bi(W(a)) ? Ma(c, firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) ? L("passwordSignIn", a, b, d, f) : 1 == c.length && c[0] === firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD ? Bi(W(a)) ? L("sendEmailLinkForSignIn", a, b, d, function () {
        L("signIn", a, b);
      }) : L("unsupportedProvider", a, b, d) : (c = $h(c, ii(W(a)))) ? (Ch(new Bg(d), X(a)), L("federatedSignIn", a, b, d, c, e)) : L("unsupportedProvider", a, b, d) : pi(W(a)) ? L("handleUnauthorizedUser", a, b, d, firebase.auth.EmailAuthProvider.PROVIDER_ID) : Bi(W(a)) ? L("sendEmailLinkForSignIn", a, b, d, function () {
        L("signIn", a, b);
      }) : L("passwordSignUp", a, b, d, void 0, void 0, f);
    }

    function bm(a, b, c, d, e, f) {
      var g = Q(b);
      V(a, b.I(t(a.Ib, a), [c, f], function () {
        b.o();
        L("emailLinkSignInSent", a, g, c, d, f);
      }, e));
    }

    function Zl(a, b, c) {
      c ? L("prefilledEmailSignIn", a, b, c) : L("signIn", a, b);
    }

    function cm() {
      return ub(uf(), "oobCode");
    }

    function dm() {
      var a = ub(uf(), "continueUrl");
      return a ? function () {
        Oc(window.location, a);
      } : null;
    }

    function em(a, b) {
      P.call(this || _global, Ik, void 0, b, "anonymousUserMismatch");
      (this || _global).i = a;
    }

    m(em, P);

    em.prototype.v = function () {
      var a = this || _global;
      O(this || _global, this.l(), function () {
        a.i();
      });
      this.l().focus();
      P.prototype.v.call(this || _global);
    };

    em.prototype.m = function () {
      (this || _global).i = null;
      P.prototype.m.call(this || _global);
    };

    u(em.prototype, {
      l: kl
    });

    K.anonymousUserMismatch = function (a, b) {
      var c = new em(function () {
        c.o();
        S(a, b);
      });
      c.render(b);
      Y(a, c);
    };

    function fm(a) {
      P.call(this || _global, Bk, void 0, a, "callback");
    }

    m(fm, P);

    fm.prototype.I = function (a, b, c, d) {
      return a.apply(null, b).then(c, d);
    };

    function gm(a, b, c) {
      if (c.user) {
        var d = {
          user: c.user,
          credential: c.credential,
          operationType: c.operationType,
          additionalUserInfo: c.additionalUserInfo
        },
            e = Ah(X(a)),
            f = e && e.g;
        if (f && !hm(c.user, f)) im(a, b, d);else {
          var g = e && e.a;
          g ? V(a, c.user.linkWithCredential(g).then(function (h) {
            d = {
              user: h.user,
              credential: g,
              operationType: h.operationType,
              additionalUserInfo: h.additionalUserInfo
            };
            jm(a, b, d);
          }, function (h) {
            km(a, b, h);
          })) : jm(a, b, d);
        }
      } else c = Q(b), b.o(), Bh(X(a)), S(a, c);
    }

    function jm(a, b, c) {
      Bh(X(a));
      Ll(a, b, c);
    }

    function km(a, b, c) {
      var d = Q(b);
      Bh(X(a));
      c = T(c);
      b.o();
      S(a, d, void 0, c);
    }

    function lm(a, b, c, d) {
      var e = Q(b);
      V(a, U(a).fetchSignInMethodsForEmail(c).then(function (f) {
        b.o();
        f.length ? Ma(f, firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) ? L("passwordLinking", a, e, c) : 1 == f.length && f[0] === firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD ? L("emailLinkSignInLinking", a, e, c) : (f = $h(f, ii(W(a)))) ? L("federatedLinking", a, e, c, f, d) : (Bh(X(a)), L("unsupportedProvider", a, e, c)) : (Bh(X(a)), L("passwordRecovery", a, e, c, !1, Ld().toString()));
      }, function (f) {
        km(a, b, f);
      }));
    }

    function im(a, b, c) {
      var d = Q(b);
      V(a, mm(a).then(function () {
        b.o();
        L("emailMismatch", a, d, c);
      }, function (e) {
        e.name && "cancel" == e.name || (e = T(e.code), b.a(e));
      }));
    }

    function hm(a, b) {
      if (b == a.email) return !0;
      if (a.providerData) for (var c = 0; c < a.providerData.length; c++) if (b == a.providerData[c].email) return !0;
      return !1;
    }

    K.callback = function (a, b, c) {
      var d = new fm();
      d.render(b);
      Y(a, d);
      b = c || Sl(a);
      V(a, b.then(function (e) {
        gm(a, d, e);
      }, function (e) {
        if ((e = Kl(e)) && ("auth/account-exists-with-different-credential" == e.code || "auth/email-already-in-use" == e.code) && e.email && e.credential) Ch(new Bg(e.email, e.credential), X(a)), lm(a, d, e.email);else if (e && "auth/user-cancelled" == e.code) {
          var f = Ah(X(a)),
              g = T(e);
          f && f.a ? lm(a, d, f.g, g) : f ? $l(a, d, f.g, g) : km(a, d, e);
        } else e && "auth/credential-already-in-use" == e.code || (e && "auth/operation-not-supported-in-this-environment" == e.code && Xl(a) ? gm(a, d, {
          user: null,
          credential: null
        }) : km(a, d, e));
      }));
    };

    function nm(a, b) {
      P.call(this || _global, Hk, void 0, b, "differentDeviceError");
      (this || _global).i = a;
    }

    m(nm, P);

    nm.prototype.v = function () {
      var a = this || _global;
      O(this || _global, this.l(), function () {
        a.i();
      });
      this.l().focus();
      P.prototype.v.call(this || _global);
    };

    nm.prototype.m = function () {
      (this || _global).i = null;
      P.prototype.m.call(this || _global);
    };

    u(nm.prototype, {
      l: kl
    });

    K.differentDeviceError = function (a, b) {
      var c = new nm(function () {
        c.o();
        S(a, b);
      });
      c.render(b);
      Y(a, c);
    };

    function om(a, b, c, d) {
      P.call(this || _global, Sk, {
        email: a,
        G: !!c
      }, d, "emailChangeRevoke");
      (this || _global).l = b;
      (this || _global).i = c || null;
    }

    m(om, P);

    om.prototype.v = function () {
      var a = this || _global;
      O(this || _global, M(this || _global, "firebaseui-id-reset-password-link"), function () {
        a.l();
      });
      (this || _global).i && (this.w((this || _global).i), this.u().focus());
      P.prototype.v.call(this || _global);
    };

    om.prototype.m = function () {
      (this || _global).l = (this || _global).i = null;
      P.prototype.m.call(this || _global);
    };

    u(om.prototype, {
      u: jl,
      B: kl,
      w: ll
    });

    function pm() {
      return M(this || _global, "firebaseui-id-new-password");
    }

    function qm() {
      return M(this || _global, "firebaseui-id-password-toggle");
    }

    function rm() {
      (this || _global).Ra = !(this || _global).Ra;
      var a = qm.call(this || _global),
          b = pm.call(this || _global);
      (this || _global).Ra ? (b.type = "text", Ui(a, "firebaseui-input-toggle-off"), Vi(a, "firebaseui-input-toggle-on")) : (b.type = "password", Ui(a, "firebaseui-input-toggle-on"), Vi(a, "firebaseui-input-toggle-off"));
      b.focus();
    }

    function sm() {
      return M(this || _global, "firebaseui-id-new-password-error");
    }

    function tm() {
      (this || _global).Ra = !1;
      var a = pm.call(this || _global);
      a.type = "password";
      var b = sm.call(this || _global);
      Jj(this || _global, a, function () {
        Pj(b) && (N(a, !0), Nj(b));
      });
      var c = qm.call(this || _global);
      Ui(c, "firebaseui-input-toggle-on");
      Vi(c, "firebaseui-input-toggle-off");
      Lj(this || _global, a, function () {
        Ui(c, "firebaseui-input-toggle-focus");
        Vi(c, "firebaseui-input-toggle-blur");
      });
      Mj(this || _global, a, function () {
        Ui(c, "firebaseui-input-toggle-blur");
        Vi(c, "firebaseui-input-toggle-focus");
      });
      O(this || _global, c, t(rm, this || _global));
    }

    function um() {
      var a = pm.call(this || _global);
      var b = sm.call(this || _global);
      Wi(a) ? (N(a, !0), Nj(b), b = !0) : (N(a, !1), Oj(b, C("Enter your password").toString()), b = !1);
      return b ? Wi(a) : null;
    }

    function vm(a, b, c) {
      P.call(this || _global, Pk, {
        email: a
      }, c, "passwordReset");
      (this || _global).l = b;
    }

    m(vm, P);

    vm.prototype.v = function () {
      this.H();
      this.B((this || _global).l);
      vk(this || _global, this.i(), (this || _global).l);
      this.i().focus();
      P.prototype.v.call(this || _global);
    };

    vm.prototype.m = function () {
      (this || _global).l = null;
      P.prototype.m.call(this || _global);
    };

    u(vm.prototype, {
      i: pm,
      w: sm,
      M: qm,
      H: tm,
      u: um,
      U: jl,
      P: kl,
      B: ll
    });

    function wm(a, b, c, d, e) {
      P.call(this || _global, Zk, {
        factorId: a,
        phoneNumber: c || null,
        G: !!d
      }, e, "revertSecondFactorAdditionSuccess");
      (this || _global).l = b;
      (this || _global).i = d || null;
    }

    m(wm, P);

    wm.prototype.v = function () {
      var a = this || _global;
      O(this || _global, M(this || _global, "firebaseui-id-reset-password-link"), function () {
        a.l();
      });
      (this || _global).i && (this.w((this || _global).i), this.u().focus());
      P.prototype.v.call(this || _global);
    };

    wm.prototype.m = function () {
      (this || _global).l = (this || _global).i = null;
      P.prototype.m.call(this || _global);
    };

    u(wm.prototype, {
      u: jl,
      B: kl,
      w: ll
    });

    function xm(a, b, c, d, e) {
      var f = c.u();
      f && V(a, c.I(t(U(a).confirmPasswordReset, U(a)), [d, f], function () {
        c.o();
        var g = new Fl(e);
        g.render(b);
        Y(a, g);
      }, function (g) {
        ym(a, b, c, g);
      }));
    }

    function ym(a, b, c, d) {
      "auth/weak-password" == (d && d.code) ? (a = T(d), N(c.i(), !1), Oj(c.w(), a), c.i().focus()) : (c && c.o(), c = new Gl(), c.render(b), Y(a, c));
    }

    function zm(a, b, c) {
      var d = new om(c, function () {
        V(a, d.I(t(U(a).sendPasswordResetEmail, U(a)), [c], function () {
          d.o();
          d = new yl(c, void 0, H(W(a)), J(W(a)));
          d.render(b);
          Y(a, d);
        }, function () {
          d.a(Jd().toString());
        }));
      });
      d.render(b);
      Y(a, d);
    }

    function Am(a, b, c, d) {
      var e = new wm(d.factorId, function () {
        e.I(t(U(a).sendPasswordResetEmail, U(a)), [c], function () {
          e.o();
          e = new yl(c, void 0, H(W(a)), J(W(a)));
          e.render(b);
          Y(a, e);
        }, function () {
          e.a(Jd().toString());
        });
      }, d.phoneNumber);
      e.render(b);
      Y(a, e);
    }

    K.passwordReset = function (a, b, c, d) {
      V(a, U(a).verifyPasswordResetCode(c).then(function (e) {
        var f = new vm(e, function () {
          xm(a, b, f, c, d);
        });
        f.render(b);
        Y(a, f);
      }, function () {
        ym(a, b);
      }));
    };

    K.emailChangeRevocation = function (a, b, c) {
      var d = null;
      V(a, U(a).checkActionCode(c).then(function (e) {
        d = e.data.email;
        return U(a).applyActionCode(c);
      }).then(function () {
        zm(a, b, d);
      }, function () {
        var e = new Hl();
        e.render(b);
        Y(a, e);
      }));
    };

    K.emailVerification = function (a, b, c, d) {
      V(a, U(a).applyActionCode(c).then(function () {
        var e = new zl(d);
        e.render(b);
        Y(a, e);
      }, function () {
        var e = new Al();
        e.render(b);
        Y(a, e);
      }));
    };

    K.revertSecondFactorAddition = function (a, b, c) {
      var d = null,
          e = null;
      V(a, U(a).checkActionCode(c).then(function (f) {
        d = f.data.email;
        e = f.data.multiFactorInfo;
        return U(a).applyActionCode(c);
      }).then(function () {
        Am(a, b, d, e);
      }, function () {
        var f = new Dl();
        f.render(b);
        Y(a, f);
      }));
    };

    K.verifyAndChangeEmail = function (a, b, c, d) {
      var e = null;
      V(a, U(a).checkActionCode(c).then(function (f) {
        e = f.data.email;
        return U(a).applyActionCode(c);
      }).then(function () {
        var f = new Bl(e, d);
        f.render(b);
        Y(a, f);
      }, function () {
        var f = new Cl();
        f.render(b);
        Y(a, f);
      }));
    };

    function Bm(a, b) {
      try {
        var c = "number" == typeof a.selectionStart;
      } catch (d) {
        c = !1;
      }

      c ? (a.selectionStart = b, a.selectionEnd = b) : z && !nc("9") && ("textarea" == a.type && (b = a.value.substring(0, b).replace(/(\r\n|\r|\n)/g, "\n").length), a = a.createTextRange(), a.collapse(!0), a.move("character", b), a.select());
    }

    function Cm(a, b, c, d, e, f) {
      P.call(this || _global, Gk, {
        email: c
      }, f, "emailLinkSignInConfirmation", {
        F: d,
        D: e
      });
      (this || _global).l = a;
      (this || _global).u = b;
    }

    m(Cm, P);

    Cm.prototype.v = function () {
      this.w((this || _global).l);
      this.B((this || _global).l, (this || _global).u);
      this.i().focus();
      Bm(this.i(), (this.i().value || "").length);
      P.prototype.v.call(this || _global);
    };

    Cm.prototype.m = function () {
      (this || _global).u = (this || _global).l = null;
      P.prototype.m.call(this || _global);
    };

    u(Cm.prototype, {
      i: sl,
      M: tl,
      w: ul,
      H: vl,
      j: wl,
      U: jl,
      P: kl,
      B: ll
    });

    K.emailLinkConfirmation = function (a, b, c, d, e, f) {
      var g = new Cm(function () {
        var h = g.j();
        h ? (g.o(), d(a, b, h, c)) : g.i().focus();
      }, function () {
        g.o();
        S(a, b, e || void 0);
      }, e || void 0, H(W(a)), J(W(a)));
      g.render(b);
      Y(a, g);
      f && g.a(f);
    };

    function Dm(a, b, c, d, e) {
      P.call(this || _global, Lk, {
        ga: a
      }, e, "emailLinkSignInLinkingDifferentDevice", {
        F: c,
        D: d
      });
      (this || _global).i = b;
    }

    m(Dm, P);

    Dm.prototype.v = function () {
      this.u((this || _global).i);
      this.l().focus();
      P.prototype.v.call(this || _global);
    };

    Dm.prototype.m = function () {
      (this || _global).i = null;
      P.prototype.m.call(this || _global);
    };

    u(Dm.prototype, {
      l: jl,
      u: ll
    });

    K.emailLinkNewDeviceLinking = function (a, b, c, d) {
      var e = new Qb(c);
      c = e.a.a.get(x.PROVIDER_ID) || null;
      Ub(e, null);

      if (c) {
        var f = new Dm(ji(W(a), c), function () {
          f.o();
          d(a, b, e.toString());
        }, H(W(a)), J(W(a)));
        f.render(b);
        Y(a, f);
      } else S(a, b);
    };

    function Em(a) {
      P.call(this || _global, Dk, void 0, a, "blank");
    }

    m(Em, P);

    function Fm(a, b, c, d, e) {
      var f = new Em(),
          g = new Qb(c),
          h = g.a.a.get(x.$a) || "",
          k = g.a.a.get(x.Sa) || "",
          p = "1" === g.a.a.get(x.Qa),
          r = Tb(g),
          I = g.a.a.get(x.PROVIDER_ID) || null;
      g = g.a.a.get(x.vb) || null;
      Gm(a, g);
      var Ca = !wh(uh, X(a)),
          Yk = d || Fh(k, X(a)),
          ld = (d = Gh(k, X(a))) && d.a;
      I && ld && ld.providerId !== I && (ld = null);
      f.render(b);
      Y(a, f);
      V(a, f.I(function () {
        var ya = F(null);
        ya = r && Ca || Ca && p ? ef(Error("anonymous-user-not-found")) : Hm(a, c).then(function (xg) {
          if (I && !ld) throw Error("pending-credential-not-found");
          return xg;
        });
        var md = null;
        return ya.then(function (xg) {
          md = xg;
          return e ? null : U(a).checkActionCode(h);
        }).then(function () {
          return md;
        });
      }, [], function (ya) {
        Yk ? Im(a, f, Yk, c, ld, ya) : p ? (f.o(), L("differentDeviceError", a, b)) : (f.o(), L("emailLinkConfirmation", a, b, c, Jm));
      }, function (ya) {
        var md = void 0;
        if (!ya || !ya.name || "cancel" != ya.name) switch (f.o(), ya && ya.message) {
          case "anonymous-user-not-found":
            L("differentDeviceError", a, b);
            break;

          case "anonymous-user-mismatch":
            L("anonymousUserMismatch", a, b);
            break;

          case "pending-credential-not-found":
            L("emailLinkNewDeviceLinking", a, b, c, Km);
            break;

          default:
            ya && (md = T(ya)), S(a, b, void 0, md);
        }
      }));
    }

    function Jm(a, b, c, d) {
      Fm(a, b, d, c, !0);
    }

    function Km(a, b, c) {
      Fm(a, b, c);
    }

    function Im(a, b, c, d, e, f) {
      var g = Q(b);
      b.$("mdl-spinner mdl-spinner--single-color mdl-js-spinner is-active firebaseui-progress-dialog-loading-icon", C("Signing in...").toString());
      var h = null;
      e = (f ? Lm(a, f, c, d, e) : Mm(a, c, d, e)).then(function (k) {
        xh(vh, X(a));
        xh(uh, X(a));
        b.h();
        b.$("firebaseui-icon-done", C("Signed in!").toString());
        h = setTimeout(function () {
          b.h();
          Ll(a, b, k, !0);
        }, 1000);
        V(a, function () {
          b && (b.h(), b.o());
          clearTimeout(h);
        });
      }, function (k) {
        b.h();
        b.o();

        if (!k.name || "cancel" != k.name) {
          k = Kl(k);
          var p = T(k);
          "auth/email-already-in-use" == k.code || "auth/credential-already-in-use" == k.code ? (xh(vh, X(a)), xh(uh, X(a))) : "auth/invalid-email" == k.code ? (p = C("The email provided does not match the current sign-in session.").toString(), L("emailLinkConfirmation", a, g, d, Jm, null, p)) : S(a, g, c, p);
        }
      });
      V(a, e);
    }

    K.emailLinkSignInCallback = Fm;

    function Nm(a, b, c, d, e, f) {
      P.call(this || _global, Kk, {
        email: a,
        ga: b
      }, f, "emailLinkSignInLinking", {
        F: d,
        D: e
      });
      (this || _global).i = c;
    }

    m(Nm, P);

    Nm.prototype.v = function () {
      this.u((this || _global).i);
      this.l().focus();
      P.prototype.v.call(this || _global);
    };

    Nm.prototype.m = function () {
      (this || _global).i = null;
      P.prototype.m.call(this || _global);
    };

    u(Nm.prototype, {
      l: jl,
      u: ll
    });

    function Om(a, b, c, d) {
      var e = Q(b);
      bm(a, b, c, function () {
        S(a, e, c);
      }, function (f) {
        if (!f.name || "cancel" != f.name) {
          var g = T(f);
          f && "auth/network-request-failed" == f.code ? b.a(g) : (b.o(), S(a, e, c, g));
        }
      }, d);
    }

    K.emailLinkSignInLinking = function (a, b, c) {
      var d = Ah(X(a));
      Bh(X(a));

      if (d) {
        var e = d.a.providerId,
            f = new Nm(c, ji(W(a), e), function () {
          Om(a, f, c, d);
        }, H(W(a)), J(W(a)));
        f.render(b);
        Y(a, f);
      } else S(a, b);
    };

    function Pm(a, b, c, d, e, f) {
      P.call(this || _global, Ek, {
        email: a
      }, f, "emailLinkSignInSent", {
        F: d,
        D: e
      });
      (this || _global).u = b;
      (this || _global).i = c;
    }

    m(Pm, P);

    Pm.prototype.v = function () {
      var a = this || _global;
      O(this || _global, this.l(), function () {
        a.i();
      });
      O(this || _global, M(this || _global, "firebaseui-id-trouble-getting-email-link"), function () {
        a.u();
      });
      this.l().focus();
      P.prototype.v.call(this || _global);
    };

    Pm.prototype.m = function () {
      (this || _global).i = (this || _global).u = null;
      P.prototype.m.call(this || _global);
    };

    u(Pm.prototype, {
      l: kl
    });

    K.emailLinkSignInSent = function (a, b, c, d, e) {
      var f = new Pm(c, function () {
        f.o();
        L("emailNotReceived", a, b, c, d, e);
      }, function () {
        f.o();
        d();
      }, H(W(a)), J(W(a)));
      f.render(b);
      Y(a, f);
    };

    function Qm(a, b, c, d, e, f, g) {
      P.call(this || _global, cl, {
        jc: a,
        Qb: b
      }, g, "emailMismatch", {
        F: e,
        D: f
      });
      (this || _global).l = c;
      (this || _global).i = d;
    }

    m(Qm, P);

    Qm.prototype.v = function () {
      this.w((this || _global).l, (this || _global).i);
      this.u().focus();
      P.prototype.v.call(this || _global);
    };

    Qm.prototype.m = function () {
      (this || _global).i = null;
      P.prototype.m.call(this || _global);
    };

    u(Qm.prototype, {
      u: jl,
      B: kl,
      w: ll
    });

    K.emailMismatch = function (a, b, c) {
      var d = Ah(X(a));

      if (d) {
        var e = new Qm(c.user.email, d.g, function () {
          var f = e;
          Bh(X(a));
          Ll(a, f, c);
        }, function () {
          var f = c.credential.providerId,
              g = Q(e);
          e.o();
          d.a ? L("federatedLinking", a, g, d.g, f) : L("federatedSignIn", a, g, d.g, f);
        }, H(W(a)), J(W(a)));
        e.render(b);
        Y(a, e);
      } else S(a, b);
    };

    function Rm(a, b, c, d, e) {
      P.call(this || _global, Fk, void 0, e, "emailNotReceived", {
        F: c,
        D: d
      });
      (this || _global).l = a;
      (this || _global).i = b;
    }

    m(Rm, P);

    Rm.prototype.v = function () {
      var a = this || _global;
      O(this || _global, this.u(), function () {
        a.i();
      });
      O(this || _global, this.Da(), function () {
        a.l();
      });
      this.u().focus();
      P.prototype.v.call(this || _global);
    };

    Rm.prototype.Da = function () {
      return M(this || _global, "firebaseui-id-resend-email-link");
    };

    Rm.prototype.m = function () {
      (this || _global).i = (this || _global).l = null;
      P.prototype.m.call(this || _global);
    };

    u(Rm.prototype, {
      u: kl
    });

    K.emailNotReceived = function (a, b, c, d, e) {
      var f = new Rm(function () {
        bm(a, f, c, d, function (g) {
          g = T(g);
          f.a(g);
        }, e);
      }, function () {
        f.o();
        S(a, b, c);
      }, H(W(a)), J(W(a)));
      f.render(b);
      Y(a, f);
    };

    function Sm(a, b, c, d, e, f) {
      P.call(this || _global, Mk, {
        email: a,
        ga: b
      }, f, "federatedLinking", {
        F: d,
        D: e
      });
      (this || _global).i = c;
    }

    m(Sm, P);

    Sm.prototype.v = function () {
      this.u((this || _global).i);
      this.l().focus();
      P.prototype.v.call(this || _global);
    };

    Sm.prototype.m = function () {
      (this || _global).i = null;
      P.prototype.m.call(this || _global);
    };

    u(Sm.prototype, {
      l: jl,
      u: ll
    });

    K.federatedLinking = function (a, b, c, d, e) {
      var f = Ah(X(a));

      if (f && f.a) {
        var g = new Sm(c, ji(W(a), d), function () {
          Rl(a, g, d, c);
        }, H(W(a)), J(W(a)));
        g.render(b);
        Y(a, g);
        e && g.a(e);
      } else S(a, b);
    };

    K.federatedRedirect = function (a, b, c) {
      var d = new Em();
      d.render(b);
      Y(a, d);
      b = ii(W(a))[0];
      Rl(a, d, b, c);
    };

    K.federatedSignIn = function (a, b, c, d, e) {
      var f = new Sm(c, ji(W(a), d), function () {
        Rl(a, f, d, c);
      }, H(W(a)), J(W(a)));
      f.render(b);
      Y(a, f);
      e && f.a(e);
    };

    function Tm(a, b, c, d) {
      var e = b.u();
      e ? V(a, b.I(t(a.Xb, a), [c, e], function (f) {
        f = f.user.linkWithCredential(d).then(function (g) {
          return Ll(a, b, {
            user: g.user,
            credential: d,
            operationType: g.operationType,
            additionalUserInfo: g.additionalUserInfo
          });
        });
        V(a, f);
        return f;
      }, function (f) {
        if (!f.name || "cancel" != f.name) switch (f.code) {
          case "auth/wrong-password":
            N(b.i(), !1);
            Oj(b.B(), T(f));
            break;

          case "auth/too-many-requests":
            b.a(T(f));
            break;

          default:
            pg("signInWithEmailAndPassword: " + f.message, void 0), b.a(T(f));
        }
      })) : b.i().focus();
    }

    K.passwordLinking = function (a, b, c) {
      var d = Ah(X(a));
      Bh(X(a));
      var e = d && d.a;

      if (e) {
        var f = new ql(c, function () {
          Tm(a, f, c, e);
        }, function () {
          f.o();
          L("passwordRecovery", a, b, c);
        }, H(W(a)), J(W(a)));
        f.render(b);
        Y(a, f);
      } else S(a, b);
    };

    function Um(a, b, c, d, e, f) {
      P.call(this || _global, zk, {
        email: c,
        Ta: !!b
      }, f, "passwordRecovery", {
        F: d,
        D: e
      });
      (this || _global).l = a;
      (this || _global).u = b;
    }

    m(Um, P);

    Um.prototype.v = function () {
      this.B();
      this.H((this || _global).l, (this || _global).u);
      Wi(this.i()) || this.i().focus();
      vk(this || _global, this.i(), (this || _global).l);
      P.prototype.v.call(this || _global);
    };

    Um.prototype.m = function () {
      (this || _global).u = (this || _global).l = null;
      P.prototype.m.call(this || _global);
    };

    u(Um.prototype, {
      i: sl,
      w: tl,
      B: ul,
      M: vl,
      j: wl,
      U: jl,
      P: kl,
      H: ll
    });

    function Vm(a, b) {
      var c = b.j();

      if (c) {
        var d = Q(b);
        V(a, b.I(t(U(a).sendPasswordResetEmail, U(a)), [c], function () {
          b.o();
          var e = new yl(c, function () {
            e.o();
            S(a, d);
          }, H(W(a)), J(W(a)));
          e.render(d);
          Y(a, e);
        }, function (e) {
          N(b.i(), !1);
          Oj(b.w(), T(e));
        }));
      } else b.i().focus();
    }

    K.passwordRecovery = function (a, b, c, d, e) {
      var f = new Um(function () {
        Vm(a, f);
      }, d ? void 0 : function () {
        f.o();
        S(a, b);
      }, c, H(W(a)), J(W(a)));
      f.render(b);
      Y(a, f);
      e && f.a(e);
    };

    K.passwordSignIn = function (a, b, c, d) {
      var e = new xl(function () {
        Wl(a, e);
      }, function () {
        var f = e.M();
        e.o();
        L("passwordRecovery", a, b, f);
      }, c, H(W(a)), J(W(a)), d);
      e.render(b);
      Y(a, e);
    };

    function Wm() {
      return M(this || _global, "firebaseui-id-name");
    }

    function Xm() {
      return M(this || _global, "firebaseui-id-name-error");
    }

    function Ym(a, b, c, d, e, f, g, h, k) {
      P.call(this || _global, yk, {
        email: d,
        Tb: a,
        name: e,
        Ta: !!c,
        ia: !!h
      }, k, "passwordSignUp", {
        F: f,
        D: g
      });
      (this || _global).w = b;
      (this || _global).H = c;
      (this || _global).B = a;
    }

    m(Ym, P);

    Ym.prototype.v = function () {
      this.ea();
      (this || _global).B && this.Ja();
      this.ua();
      this.pa((this || _global).w, (this || _global).H);
      (this || _global).B ? (uk(this || _global, this.i(), this.u()), uk(this || _global, this.u(), this.l())) : uk(this || _global, this.i(), this.l());
      (this || _global).w && vk(this || _global, this.l(), (this || _global).w);
      Wi(this.i()) ? (this || _global).B && !Wi(this.u()) ? this.u().focus() : this.l().focus() : this.i().focus();
      P.prototype.v.call(this || _global);
    };

    Ym.prototype.m = function () {
      (this || _global).H = (this || _global).w = null;
      P.prototype.m.call(this || _global);
    };

    u(Ym.prototype, {
      i: sl,
      U: tl,
      ea: ul,
      jb: vl,
      j: wl,
      u: Wm,
      Bc: Xm,
      Ja: function () {
        var a = Wm.call(this || _global),
            b = Xm.call(this || _global);
        Jj(this || _global, a, function () {
          Pj(b) && (N(a, !0), Nj(b));
        });
      },
      M: function () {
        var a = Wm.call(this || _global);
        var b = Xm.call(this || _global);
        var c = Wi(a);
        c = !/^[\s\xa0]*$/.test(null == c ? "" : String(c));
        N(a, c);
        c ? (Nj(b), b = !0) : (Oj(b, C("Enter your account name").toString()), b = !1);
        return b ? Va(Wi(a)) : null;
      },
      l: pm,
      ba: sm,
      lb: qm,
      ua: tm,
      P: um,
      Nb: jl,
      Mb: kl,
      pa: ll
    });

    function Zm(a, b) {
      var c = Ai(W(a)),
          d = b.j(),
          e = null;
      c && (e = b.M());
      var f = b.P();

      if (d) {
        if (c) if (e) e = db(e);else {
          b.u().focus();
          return;
        }

        if (f) {
          var g = firebase.auth.EmailAuthProvider.credential(d, f);
          V(a, b.I(t(a.Yb, a), [d, f], function (h) {
            var k = {
              user: h.user,
              credential: g,
              operationType: h.operationType,
              additionalUserInfo: h.additionalUserInfo
            };
            return c ? (h = h.user.updateProfile({
              displayName: e
            }).then(function () {
              return Ll(a, b, k);
            }), V(a, h), h) : Ll(a, b, k);
          }, function (h) {
            if (!h.name || "cancel" != h.name) {
              var k = Kl(h);
              h = T(k);

              switch (k.code) {
                case "auth/email-already-in-use":
                  return $m(a, b, d, k);

                case "auth/too-many-requests":
                  h = C("Too many account requests are coming from your IP address. Try again in a few minutes.").toString();

                case "auth/operation-not-allowed":
                case "auth/weak-password":
                  N(b.l(), !1);
                  Oj(b.ba(), h);
                  break;

                default:
                  k = "setAccountInfo: " + bh(k), pg(k, void 0), b.a(h);
              }
            }
          }));
        } else b.l().focus();
      } else b.i().focus();
    }

    function $m(a, b, c, d) {
      function e() {
        var g = T(d);
        N(b.i(), !1);
        Oj(b.U(), g);
        b.i().focus();
      }

      var f = U(a).fetchSignInMethodsForEmail(c).then(function (g) {
        g.length ? e() : (g = Q(b), b.o(), L("passwordRecovery", a, g, c, !1, Ld().toString()));
      }, function () {
        e();
      });
      V(a, f);
      return f;
    }

    K.passwordSignUp = function (a, b, c, d, e, f) {
      function g() {
        h.o();
        S(a, b);
      }

      var h = new Ym(Ai(W(a)), function () {
        Zm(a, h);
      }, e ? void 0 : g, c, d, H(W(a)), J(W(a)), f);
      h.render(b);
      Y(a, h);
    };

    function an() {
      return M(this || _global, "firebaseui-id-phone-confirmation-code");
    }

    function bn() {
      return M(this || _global, "firebaseui-id-phone-confirmation-code-error");
    }

    function cn() {
      return M(this || _global, "firebaseui-id-resend-countdown");
    }

    function dn(a, b, c, d, e, f, g, h, k) {
      P.call(this || _global, fl, {
        phoneNumber: e
      }, k, "phoneSignInFinish", {
        F: g,
        D: h
      });
      (this || _global).jb = f;
      (this || _global).i = new hj(1000);
      (this || _global).B = f;
      (this || _global).P = a;
      (this || _global).l = b;
      (this || _global).H = c;
      (this || _global).M = d;
    }

    m(dn, P);

    dn.prototype.v = function () {
      var a = this || _global;
      this.U((this || _global).jb);
      le((this || _global).i, "tick", (this || _global).w, !1, this || _global);

      (this || _global).i.start();

      O(this || _global, M(this || _global, "firebaseui-id-change-phone-number-link"), function () {
        a.P();
      });
      O(this || _global, this.Da(), function () {
        a.M();
      });
      this.Ja((this || _global).l);
      this.ea((this || _global).l, (this || _global).H);
      this.u().focus();
      P.prototype.v.call(this || _global);
    };

    dn.prototype.m = function () {
      (this || _global).M = (this || _global).H = (this || _global).l = (this || _global).P = null;
      ij((this || _global).i);
      te((this || _global).i, "tick", (this || _global).w);
      (this || _global).i = null;
      P.prototype.m.call(this || _global);
    };

    dn.prototype.w = function () {
      --(this || _global).B;
      0 < (this || _global).B ? this.U((this || _global).B) : (ij((this || _global).i), te((this || _global).i, "tick", (this || _global).w), this.ua(), this.lb());
    };

    u(dn.prototype, {
      u: an,
      pa: bn,
      Ja: function (a) {
        var b = an.call(this || _global),
            c = bn.call(this || _global);
        Jj(this || _global, b, function () {
          Pj(c) && (N(b, !0), Nj(c));
        });
        a && Kj(this || _global, b, function () {
          a();
        });
      },
      ba: function () {
        var a = Va(Wi(an.call(this || _global)) || "");
        return /^\d{6}$/.test(a) ? a : null;
      },
      Fb: cn,
      U: function (a) {
        ad(cn.call(this || _global), C("Resend code in " + ((9 < a ? "0:" : "0:0") + a)).toString());
      },
      ua: function () {
        Nj(this.Fb());
      },
      Da: function () {
        return M(this || _global, "firebaseui-id-resend-link");
      },
      lb: function () {
        Oj(this.Da());
      },
      Nb: jl,
      Mb: kl,
      ea: ll
    });

    function en(a, b, c, d) {
      function e(g) {
        b.u().focus();
        N(b.u(), !1);
        Oj(b.pa(), g);
      }

      var f = b.ba();
      f ? (b.$("mdl-spinner mdl-spinner--single-color mdl-js-spinner is-active firebaseui-progress-dialog-loading-icon", C("Verifying...").toString()), V(a, b.I(t(d.confirm, d), [f], function (g) {
        b.h();
        b.$("firebaseui-icon-done", C("Verified!").toString());
        var h = setTimeout(function () {
          b.h();
          b.o();
          var k = {
            user: fn(a).currentUser,
            credential: null,
            operationType: g.operationType,
            additionalUserInfo: g.additionalUserInfo
          };
          Ll(a, b, k, !0);
        }, 1000);
        V(a, function () {
          b && b.h();
          clearTimeout(h);
        });
      }, function (g) {
        if (g.name && "cancel" == g.name) b.h();else {
          var h = Kl(g);
          g = T(h);

          switch (h.code) {
            case "auth/credential-already-in-use":
              b.h();
              break;

            case "auth/code-expired":
              h = Q(b);
              b.h();
              b.o();
              L("phoneSignInStart", a, h, c, g);
              break;

            case "auth/missing-verification-code":
            case "auth/invalid-verification-code":
              b.h();
              e(g);
              break;

            default:
              b.h(), b.a(g);
          }
        }
      }))) : e(C("Wrong code. Try again.").toString());
    }

    K.phoneSignInFinish = function (a, b, c, d, e, f) {
      var g = new dn(function () {
        g.o();
        L("phoneSignInStart", a, b, c);
      }, function () {
        en(a, g, c, e);
      }, function () {
        g.o();
        S(a, b);
      }, function () {
        g.o();
        L("phoneSignInStart", a, b, c);
      }, Zh(c), d, H(W(a)), J(W(a)));
      g.render(b);
      Y(a, g);
      f && g.a(f);
    };

    var gn = !z && !(y("Safari") && !(Yb() || y("Coast") || y("Opera") || y("Edge") || y("Firefox") || y("FxiOS") || y("Silk") || y("Android")));

    function hn(a, b) {
      if (/-[a-z]/.test(b)) return null;

      if (gn && a.dataset) {
        if (!(!y("Android") || Yb() || y("Firefox") || y("FxiOS") || y("Opera") || y("Silk") || b in a.dataset)) return null;
        a = a.dataset[b];
        return void 0 === a ? null : a;
      }

      return a.getAttribute("data-" + String(b).replace(/([A-Z])/g, "-$1").toLowerCase());
    }

    function jn(a, b, c) {
      var d = this || _global;
      a = id(ek, {
        items: a
      }, null, (this || _global).s);
      lk.call(this || _global, a, !0, !0);
      c && (c = kn(a, c)) && (c.focus(), Bj(c, a));
      O(this || _global, a, function (e) {
        if (e = (e = bd(e.target, "firebaseui-id-list-box-dialog-button")) && hn(e, "listboxid")) mk.call(d), b(e);
      });
    }

    function kn(a, b) {
      a = (a || document).getElementsByTagName("BUTTON");

      for (var c = 0; c < a.length; c++) if (hn(a[c], "listboxid") === b) return a[c];

      return null;
    }

    function ln() {
      return M(this || _global, "firebaseui-id-phone-number");
    }

    function mn() {
      return M(this || _global, "firebaseui-id-country-selector");
    }

    function nn() {
      return M(this || _global, "firebaseui-id-phone-number-error");
    }

    function on(a, b) {
      var c = a.a,
          d = pn("1-US-0", c),
          e = null;
      b && pn(b, c) ? e = b : d ? e = "1-US-0" : e = 0 < c.length ? c[0].c : null;
      if (!e) throw Error("No available default country");
      qn.call(this || _global, e, a);
    }

    function pn(a, b) {
      a = Rh(a);
      return !(!a || !Ma(b, a));
    }

    function rn(a) {
      return Ka(a, function (b) {
        return {
          id: b.c,
          Ma: "firebaseui-flag " + sn(b),
          label: b.name + " " + ("\u200E+" + b.b)
        };
      });
    }

    function sn(a) {
      return "firebaseui-flag-" + a.f;
    }

    function tn(a) {
      var b = this || _global;
      jn.call(this || _global, rn(a.a), function (c) {
        qn.call(b, c, a, !0);
        b.O().focus();
      }, (this || _global).Ba);
    }

    function qn(a, b, c) {
      var d = Rh(a);
      d && (c && (c = Va(Wi(ln.call(this || _global)) || ""), b = Qh(b, c), b.length && b[0].b != d.b && (c = "+" + d.b + c.substr(b[0].b.length + 1), Xi(ln.call(this || _global), c))), b = Rh((this || _global).Ba), (this || _global).Ba = a, a = M(this || _global, "firebaseui-id-country-selector-flag"), b && Vi(a, sn(b)), Ui(a, sn(d)), ad(M(this || _global, "firebaseui-id-country-selector-code"), "\u200E+" + d.b));
    }

    function un(a, b, c, d, e, f, g, h, k, p) {
      P.call(this || _global, el, {
        Gb: b,
        Aa: k || null,
        Va: !!c,
        ia: !!f
      }, p, "phoneSignInStart", {
        F: d,
        D: e
      });
      (this || _global).H = h || null;
      (this || _global).M = b;
      (this || _global).l = a;
      (this || _global).w = c || null;
      (this || _global).pa = g || null;
    }

    m(un, P);

    un.prototype.v = function () {
      this.ea((this || _global).pa, (this || _global).H);
      this.P((this || _global).l, (this || _global).w || void 0);
      (this || _global).M || uk(this || _global, this.O(), this.i());
      vk(this || _global, this.i(), (this || _global).l);
      this.O().focus();
      Bm(this.O(), (this.O().value || "").length);
      P.prototype.v.call(this || _global);
    };

    un.prototype.m = function () {
      (this || _global).w = (this || _global).l = null;
      P.prototype.m.call(this || _global);
    };

    u(un.prototype, {
      Cb: nk,
      O: ln,
      B: nn,
      ea: function (a, b, c) {
        var d = this || _global,
            e = ln.call(this || _global),
            f = mn.call(this || _global),
            g = nn.call(this || _global),
            h = a || Wh,
            k = h.a;
        if (0 == k.length) throw Error("No available countries provided.");
        on.call(d, h, b);
        O(this || _global, f, function () {
          tn.call(d, h);
        });
        Jj(this || _global, e, function () {
          Pj(g) && (N(e, !0), Nj(g));
          var p = Va(Wi(e) || ""),
              r = Rh((this || _global).Ba),
              I = Qh(h, p);
          p = pn("1-US-0", k);
          I.length && I[0].b != r.b && (r = I[0], qn.call(d, "1" == r.b && p ? "1-US-0" : r.c, h));
        });
        c && Kj(this || _global, e, function () {
          c();
        });
      },
      U: function (a) {
        var b = Va(Wi(ln.call(this || _global)) || "");
        a = a || Wh;
        var c = a.a,
            d = Qh(Wh, b);
        if (d.length && !Ma(c, d[0])) throw Xi(ln.call(this || _global)), ln.call(this || _global).focus(), Oj(nn.call(this || _global), C("The country code provided is not supported.").toString()), Error("The country code provided is not supported.");
        c = Rh((this || _global).Ba);
        d.length && d[0].b != c.b && qn.call(this || _global, d[0].c, a);
        d.length && (b = b.substr(d[0].b.length + 1));
        return b ? new Xh((this || _global).Ba, b) : null;
      },
      Ja: mn,
      ba: function () {
        return M(this || _global, "firebaseui-recaptcha-container");
      },
      u: function () {
        return M(this || _global, "firebaseui-id-recaptcha-error");
      },
      i: jl,
      ua: kl,
      P: ll
    });

    function vn(a, b, c, d) {
      try {
        var e = b.U(Qi);
      } catch (f) {
        return;
      }

      e ? Oi ? (b.$("mdl-spinner mdl-spinner--single-color mdl-js-spinner is-active firebaseui-progress-dialog-loading-icon", C("Verifying...").toString()), V(a, b.I(t(a.cc, a), [Zh(e), c], function (f) {
        var g = Q(b);
        b.$("firebaseui-icon-done", C("Code sent!").toString());
        var h = setTimeout(function () {
          b.h();
          b.o();
          L("phoneSignInFinish", a, g, e, 15, f);
        }, 1000);
        V(a, function () {
          b && b.h();
          clearTimeout(h);
        });
      }, function (f) {
        b.h();

        if (!f.name || "cancel" != f.name) {
          grecaptcha.reset(Ri);
          Oi = null;
          var g = f && f.message || "";
          if (f.code) switch (f.code) {
            case "auth/too-many-requests":
              g = C("This phone number has been used too many times").toString();
              break;

            case "auth/invalid-phone-number":
            case "auth/missing-phone-number":
              b.O().focus();
              Oj(b.B(), Id().toString());
              return;

            default:
              g = T(f);
          }
          b.a(g);
        }
      }))) : Pi ? Oj(b.u(), C("Solve the reCAPTCHA").toString()) : !Pi && d && b.i().click() : (b.O().focus(), Oj(b.B(), Id().toString()));
    }

    K.phoneSignInStart = function (a, b, c, d) {
      var e = qi(W(a)) || {};
      Oi = null;
      Pi = !(e && "invisible" === e.size);
      var f = Yl(a),
          g = xi(W(a)),
          h = f ? wi(W(a)) : null;
      g = c && c.a || g && g.c || null;
      c = c && c.Aa || h;
      (h = yi(W(a))) && Vh(h);
      Qi = h ? new Ph(yi(W(a))) : Wh;
      var k = new un(function (r) {
        vn(a, k, p, !(!r || !r.keyCode));
      }, Pi, f ? null : function () {
        p.clear();
        k.o();
        S(a, b);
      }, H(W(a)), J(W(a)), f, Qi, g, c);
      k.render(b);
      Y(a, k);
      d && k.a(d);

      e.callback = function (r) {
        k.u() && Nj(k.u());
        Oi = r;
        Pi || vn(a, k, p);
      };

      e["expired-callback"] = function () {
        Oi = null;
      };

      var p = new firebase.auth.RecaptchaVerifier(Pi ? k.ba() : k.i(), e, fn(a).app);
      V(a, k.I(t(p.render, p), [], function (r) {
        Ri = r;
      }, function (r) {
        r.name && "cancel" == r.name || (r = T(r), k.o(), S(a, b, void 0, r));
      }));
    };

    K.prefilledEmailSignIn = function (a, b, c) {
      var d = new Em();
      d.render(b);
      Y(a, d);
      V(a, d.I(t(U(a).fetchSignInMethodsForEmail, U(a)), [c], function (e) {
        d.o();
        var f = !(!Xl(a) || !wn(a));
        am(a, b, e, c, void 0, f);
      }, function (e) {
        e = T(e);
        d.o();
        L("signIn", a, b, c, e);
      }));
    };

    function xn(a, b, c, d, e) {
      P.call(this || _global, dl, {
        Sb: b
      }, e, "providerSignIn", {
        F: c,
        D: d
      });
      (this || _global).i = a;
    }

    m(xn, P);

    xn.prototype.v = function () {
      this.l((this || _global).i);
      P.prototype.v.call(this || _global);
    };

    xn.prototype.m = function () {
      (this || _global).i = null;
      P.prototype.m.call(this || _global);
    };

    u(xn.prototype, {
      l: function (a) {
        function b(g) {
          a(g);
        }

        for (var c = (this || _global).g ? Uc("firebaseui-id-idp-button", (this || _global).g || (this || _global).s.a) : [], d = 0; d < c.length; d++) {
          var e = c[d],
              f = hn(e, "providerId");
          O(this || _global, e, za(b, f));
        }
      }
    });

    K.providerSignIn = function (a, b, c, d) {
      var e = new xn(function (f) {
        f == firebase.auth.EmailAuthProvider.PROVIDER_ID ? (e.o(), Zl(a, b, d)) : f == firebase.auth.PhoneAuthProvider.PROVIDER_ID ? (e.o(), L("phoneSignInStart", a, b)) : "anonymous" == f ? Ul(a, e) : Rl(a, e, f, d);
        Z(a);
        a.l.cancel();
      }, ki(W(a)), H(W(a)), J(W(a)));
      e.render(b);
      Y(a, e);
      c && e.a(c);
      yn(a);
    };

    K.sendEmailLinkForSignIn = function (a, b, c, d) {
      var e = new fm();
      e.render(b);
      Y(a, e);
      bm(a, e, c, d, function (f) {
        e.o();
        f = T(f);
        L("signIn", a, b, c, f);
      });
    };

    function zn(a, b, c, d, e, f, g) {
      P.call(this || _global, wk, {
        email: c,
        Va: !!b,
        ia: !!f
      }, g, "signIn", {
        F: d,
        D: e
      });
      (this || _global).i = a;
      (this || _global).u = b;
    }

    m(zn, P);

    zn.prototype.v = function () {
      this.w((this || _global).i);
      this.B((this || _global).i, (this || _global).u || void 0);
      this.l().focus();
      Bm(this.l(), (this.l().value || "").length);
      P.prototype.v.call(this || _global);
    };

    zn.prototype.m = function () {
      (this || _global).u = (this || _global).i = null;
      P.prototype.m.call(this || _global);
    };

    u(zn.prototype, {
      l: sl,
      M: tl,
      w: ul,
      H: vl,
      j: wl,
      U: jl,
      P: kl,
      B: ll
    });

    K.signIn = function (a, b, c, d) {
      var e = Xl(a),
          f = new zn(function () {
        var g = f,
            h = g.j() || "";
        h && $l(a, g, h);
      }, e ? null : function () {
        f.o();
        S(a, b, c);
      }, c, H(W(a)), J(W(a)), e);
      f.render(b);
      Y(a, f);
      d && f.a(d);
    };

    function An(a, b, c, d, e, f, g) {
      P.call(this || _global, Nk, {
        email: a,
        yb: c,
        Eb: !!d
      }, g, "unauthorizedUser", {
        F: e,
        D: f
      });
      (this || _global).l = b;
      (this || _global).i = d;
    }

    m(An, P);

    An.prototype.v = function () {
      var a = this || _global,
          b = M(this || _global, "firebaseui-id-unauthorized-user-help-link");
      (this || _global).i && b && O(this || _global, b, function () {
        a.i();
      });
      O(this || _global, this.u(), function () {
        a.l();
      });
      this.u().focus();
      P.prototype.v.call(this || _global);
    };

    An.prototype.m = function () {
      (this || _global).i = (this || _global).l = null;
      P.prototype.m.call(this || _global);
    };

    u(An.prototype, {
      u: kl
    });

    K.handleUnauthorizedUser = function (a, b, c, d) {
      function e() {
        S(a, b);
      }

      d === firebase.auth.EmailAuthProvider.PROVIDER_ID && (e = function () {
        Zl(a, b);
      });
      var f = new An(c || null, function () {
        f.o();
        e();
      }, si(W(a)), ti(W(a)), H(W(a)), J(W(a)));
      f.render(b);
      Y(a, f);
    };

    function Bn(a, b, c, d, e, f) {
      P.call(this || _global, Ok, {
        email: a
      }, f, "unsupportedProvider", {
        F: d,
        D: e
      });
      (this || _global).l = b;
      (this || _global).i = c;
    }

    m(Bn, P);

    Bn.prototype.v = function () {
      this.w((this || _global).l, (this || _global).i);
      this.u().focus();
      P.prototype.v.call(this || _global);
    };

    Bn.prototype.m = function () {
      (this || _global).i = (this || _global).l = null;
      P.prototype.m.call(this || _global);
    };

    u(Bn.prototype, {
      u: jl,
      B: kl,
      w: ll
    });

    K.unsupportedProvider = function (a, b, c) {
      var d = new Bn(c, function () {
        d.o();
        L("passwordRecovery", a, b, c);
      }, function () {
        d.o();
        S(a, b, c);
      }, H(W(a)), J(W(a)));
      d.render(b);
      Y(a, d);
    };

    function Cn(a, b) {
      (this || _global).$ = !1;
      var c = Dn(b);
      if (En[c]) throw Error("An AuthUI instance already exists for the key \"" + c + "\"");
      En[c] = this || _global;
      (this || _global).a = a;
      (this || _global).u = null;
      (this || _global).Y = !1;
      Fn((this || _global).a);
      (this || _global).h = firebase.initializeApp({
        apiKey: a.app.options.apiKey,
        authDomain: a.app.options.authDomain
      }, a.app.name + "-firebaseui-temp").auth();
      if (a = a.emulatorConfig) c = a.port, (this || _global).h.useEmulator(a.protocol + "://" + a.host + (null === c ? "" : ":" + c), a.options);
      Fn((this || _global).h);
      (this || _global).h.setPersistence && (this || _global).h.setPersistence(firebase.auth.Auth.Persistence.SESSION);
      (this || _global).oa = b;
      (this || _global).ca = new ci();
      (this || _global).g = (this || _global).T = (this || _global).i = (this || _global).J = (this || _global).O = null;
      (this || _global).s = [];
      (this || _global).Z = !1;
      (this || _global).l = Qf.Xa();
      (this || _global).j = (this || _global).C = null;
      (this || _global).da = (this || _global).A = !1;
    }

    function Fn(a) {
      a && a.INTERNAL && a.INTERNAL.logFramework && a.INTERNAL.logFramework("FirebaseUI-web");
    }

    var En = {};

    function Dn(a) {
      return a || "[DEFAULT]";
    }

    function Sl(a) {
      Z(a);
      a.i || (a.i = Gn(a, function (b) {
        return b && !Ah(X(a)) ? F(fn(a).getRedirectResult().then(function (c) {
          return c;
        }, function (c) {
          if (c && "auth/email-already-in-use" == c.code && c.email && c.credential) throw c;
          return Hn(a, c);
        })) : F(U(a).getRedirectResult().then(function (c) {
          return ei(W(a)) && !c.user && a.j && !a.j.isAnonymous ? fn(a).getRedirectResult() : c;
        }));
      }));
      return a.i;
    }

    function Y(a, b) {
      Z(a);
      a.g = b;
    }

    var In = null;

    function U(a) {
      Z(a);
      return a.h;
    }

    function fn(a) {
      Z(a);
      return a.a;
    }

    function X(a) {
      Z(a);
      return a.oa;
    }

    function wn(a) {
      Z(a);
      return a.O ? a.O.emailHint : void 0;
    }

    l = Cn.prototype;

    l.nb = function () {
      Z(this || _global);
      return !!Dh(X(this || _global)) || Jn(uf());
    };

    function Jn(a) {
      a = new Qb(a);
      return "signIn" === (a.a.a.get(x.ub) || null) && !!a.a.a.get(x.$a);
    }

    l.start = function (a, b) {
      Kn(this || _global, a, b);
    };

    function Kn(a, b, c, d) {
      Z(a);
      "undefined" !== typeof a.a.languageCode && (a.u = a.a.languageCode);
      var e = "en".replace(/_/g, "-");
      a.a.languageCode = e;
      a.h.languageCode = e;
      a.Y = !0;
      "undefined" !== typeof a.a.tenantId && (a.h.tenantId = a.a.tenantId);
      a.ib(c);
      a.O = d || null;
      var f = n.document;
      a.C ? a.C.then(function () {
        "complete" == f.readyState ? Ln(a, b) : me(window, "load", function () {
          Ln(a, b);
        });
      }) : "complete" == f.readyState ? Ln(a, b) : me(window, "load", function () {
        Ln(a, b);
      });
    }

    function Ln(a, b) {
      var c = tf(b, "Could not find the FirebaseUI widget element on the page.");
      c.setAttribute("lang", "en".replace(/_/g, "-"));

      if (In) {
        var d = In;
        Z(d);
        Ah(X(d)) && ug("UI Widget is already rendered on the page and is pending some user interaction. Only one widget instance can be rendered per page. The previous instance has been automatically reset.");
        In.reset();
      }

      In = a;
      a.T = c;
      Mn(a, c);

      if (kh(new lh()) && kh(new mh())) {
        b = tf(b, "Could not find the FirebaseUI widget element on the page.");
        c = uf();
        d = Kh(W(a).a, "queryParameterForSignInSuccessUrl");
        c = (c = ub(c, d)) ? Ac(Cc(c)).toString() : null;

        a: {
          d = uf();
          var e = zi(W(a));
          d = ub(d, e) || "";

          for (f in Ni) if (Ni[f].toLowerCase() == d.toLowerCase()) {
            var f = Ni[f];
            break a;
          }

          f = "callback";
        }

        switch (f) {
          case "callback":
            c && (f = X(a), yh(th, c, f));
            a.nb() ? L("callback", a, b) : S(a, b, wn(a));
            break;

          case "resetPassword":
            L("passwordReset", a, b, cm(), dm());
            break;

          case "recoverEmail":
            L("emailChangeRevocation", a, b, cm());
            break;

          case "revertSecondFactorAddition":
            L("revertSecondFactorAddition", a, b, cm());
            break;

          case "verifyEmail":
            L("emailVerification", a, b, cm(), dm());
            break;

          case "verifyAndChangeEmail":
            L("verifyAndChangeEmail", a, b, cm(), dm());
            break;

          case "signIn":
            L("emailLinkSignInCallback", a, b, uf());
            Nn();
            break;

          case "select":
            c && (f = X(a), yh(th, c, f));
            S(a, b);
            break;

          default:
            throw Error("Unhandled widget operation.");
        }

        b = W(a);
        (b = Ji(b).uiShown || null) && b();
      } else b = tf(b, "Could not find the FirebaseUI widget element on the page."), f = new Jl(C("The browser you are using does not support Web Storage. Please try again in a different browser.").toString()), f.render(b), Y(a, f);

      b = a.g && "blank" == a.g.Ga && Ei(W(a));
      Dh(X(a)) && !b && (b = Dh(X(a)), Gm(a, b.a), xh(sh, X(a)));
    }

    function Gn(a, b) {
      if (a.A) return b(On(a));
      V(a, function () {
        a.A = !1;
      });

      if (ei(W(a))) {
        var c = new We(function (d) {
          V(a, a.a.onAuthStateChanged(function (e) {
            a.j = e;
            a.A || (a.A = !0, d(b(On(a))));
          }));
        });
        V(a, c);
        return c;
      }

      a.A = !0;
      return b(null);
    }

    function On(a) {
      Z(a);
      return ei(W(a)) && a.j && a.j.isAnonymous ? a.j : null;
    }

    function V(a, b) {
      Z(a);

      if (b) {
        a.s.push(b);

        var c = function () {
          Qa(a.s, function (d) {
            return d == b;
          });
        };

        "function" != typeof b && b.then(c, c);
      }
    }

    l.Db = function () {
      Z(this || _global);
      (this || _global).Z = !0;
    };

    function Pn(a) {
      Z(a);
      var b;
      (b = a.Z) || (a = W(a), a = vi(a, firebase.auth.GoogleAuthProvider.PROVIDER_ID), b = !(!a || "select_account" !== a.prompt));
      return b;
    }

    function Ml(a) {
      "undefined" !== typeof a.a.languageCode && a.Y && (a.Y = !1, a.a.languageCode = a.u);
    }

    function Gm(a, b) {
      a.a.tenantId = b;
      a.h.tenantId = b;
    }

    l.reset = function () {
      Z(this || _global);
      var a = this || _global;
      (this || _global).T && (this || _global).T.removeAttribute("lang");
      (this || _global).J && Ee((this || _global).J);
      Ml(this || _global);
      (this || _global).O = null;
      Nn();
      xh(sh, X(this || _global));
      Z(this || _global);

      (this || _global).l.cancel();

      (this || _global).i = F({
        user: null,
        credential: null
      });
      In == (this || _global) && (In = null);
      (this || _global).T = null;

      for (var b = 0; b < (this || _global).s.length; b++) if ("function" == typeof (this || _global).s[b]) (this || _global).s[b]();else (this || _global).s[b].cancel && (this || _global).s[b].cancel();

      (this || _global).s = [];
      Bh(X(this || _global));
      (this || _global).g && ((this || _global).g.o(), (this || _global).g = null);
      (this || _global).L = null;
      (this || _global).h && ((this || _global).C = mm(this || _global).then(function () {
        a.C = null;
      }, function () {
        a.C = null;
      }));
    };

    function Mn(a, b) {
      a.L = null;
      a.J = new Fe(b);
      a.J.register();
      le(a.J, "pageEnter", function (c) {
        c = c && c.pageId;

        if (a.L != c) {
          var d = W(a);
          (d = Ji(d).uiChanged || null) && d(a.L, c);
          a.L = c;
        }
      });
    }

    l.ib = function (a) {
      Z(this || _global);
      var b = (this || _global).ca,
          c;

      for (c in a) try {
        Jh(b.a, c, a[c]);
      } catch (d) {
        pg("Invalid config: \"" + c + "\"", void 0);
      }

      gc && Jh(b.a, "popupMode", !1);
      yi(b);
      !(this || _global).da && Ii(W(this || _global)) && (ug("signInSuccess callback is deprecated. Please use signInSuccessWithAuthResult callback instead."), (this || _global).da = !0);
    };

    function W(a) {
      Z(a);
      return a.ca;
    }

    l.Wb = function () {
      Z(this || _global);
      var a = W(this || _global),
          b = Kh(a.a, "widgetUrl");
      a = zi(a);
      var c = b.search(tb);

      for (var d = 0, e, f = []; 0 <= (e = sb(b, d, a, c));) f.push(b.substring(d, e)), d = Math.min(b.indexOf("&", e) + 1 || c, c);

      f.push(b.substr(d));
      b = f.join("").replace(vb, "$1");
      c = "=" + encodeURIComponent("select");
      (a += c) ? (c = b.indexOf("#"), 0 > c && (c = b.length), d = b.indexOf("?"), 0 > d || d > c ? (d = c, e = "") : e = b.substring(d + 1, c), b = [b.substr(0, d), e, b.substr(c)], c = b[1], b[1] = a ? c ? c + "&" + a : a : c, c = b[0] + (b[1] ? "?" + b[1] : "") + b[2]) : c = b;
      W(this || _global).a.get("popupMode") ? (a = (window.screen.availHeight - 600) / 2, b = (window.screen.availWidth - 500) / 2, c = c || "about:blank", a = {
        width: 500,
        height: 600,
        top: 0 < a ? a : 0,
        left: 0 < b ? b : 0,
        location: !0,
        resizable: !0,
        statusbar: !0,
        toolbar: !1
      }, a.target = a.target || c.target || "google_popup", a.width = a.width || 690, a.height = a.height || 500, (a = qf(c, a)) && a.focus()) : Oc(window.location, c);
    };

    function Z(a) {
      if (a.$) throw Error("AuthUI instance is deleted!");
    }

    l.Wa = function () {
      var a = this || _global;
      Z(this || _global);
      return (this || _global).h.app.delete().then(function () {
        var b = Dn(X(a));
        delete En[b];
        a.reset();
        a.$ = !0;
      });
    };

    function yn(a) {
      Z(a);

      try {
        Rf(a.l, mi(W(a)), Pn(a)).then(function (b) {
          return a.g ? Vl(a, a.g, b) : !1;
        });
      } catch (b) {}
    }

    l.Ib = function (a, b) {
      Z(this || _global);
      var c = this || _global,
          d = wf();
      if (!Bi(W(this || _global))) return ef(Error("Email link sign-in should be enabled to trigger email sending."));
      var e = Di(W(this || _global)),
          f = new Qb(e.url);
      Rb(f, d);
      b && b.a && (Hh(d, b, X(this || _global)), Ub(f, b.a.providerId));
      Sb(f, Ci(W(this || _global)));
      return Gn(this || _global, function (g) {
        g && ((g = g.uid) ? f.a.a.set(x.Pa, g) : Ob(f.a.a, x.Pa));
        e.url = f.toString();
        return U(c).sendSignInLinkToEmail(a, e);
      }).then(function () {
        var g = X(c),
            h = {};
        h.email = a;
        yh(uh, Zg(d, JSON.stringify(h)), g);
      }, function (g) {
        xh(vh, X(c));
        xh(uh, X(c));
        throw g;
      });
    };

    function Hm(a, b) {
      var c = Tb(new Qb(b));
      if (!c) return F(null);
      b = new We(function (d, e) {
        var f = fn(a).onAuthStateChanged(function (g) {
          f();
          g && g.isAnonymous && g.uid === c ? d(g) : g && g.isAnonymous && g.uid !== c ? e(Error("anonymous-user-mismatch")) : e(Error("anonymous-user-not-found"));
        });
        V(a, f);
      });
      V(a, b);
      return b;
    }

    function Lm(a, b, c, d, e) {
      Z(a);
      var f = e || null,
          g = firebase.auth.EmailAuthProvider.credentialWithLink(c, d);
      c = f ? U(a).signInWithEmailLink(c, d).then(function (h) {
        return h.user.linkWithCredential(f);
      }).then(function () {
        return mm(a);
      }).then(function () {
        return Hn(a, {
          code: "auth/email-already-in-use"
        }, f);
      }) : U(a).fetchSignInMethodsForEmail(c).then(function (h) {
        return h.length ? Hn(a, {
          code: "auth/email-already-in-use"
        }, g) : b.linkWithCredential(g);
      });
      V(a, c);
      return c;
    }

    function Mm(a, b, c, d) {
      Z(a);
      var e = d || null,
          f;
      b = U(a).signInWithEmailLink(b, c).then(function (g) {
        f = {
          user: g.user,
          credential: null,
          operationType: g.operationType,
          additionalUserInfo: g.additionalUserInfo
        };
        if (e) return g.user.linkWithCredential(e).then(function (h) {
          f = {
            user: h.user,
            credential: e,
            operationType: f.operationType,
            additionalUserInfo: h.additionalUserInfo
          };
        });
      }).then(function () {
        mm(a);
      }).then(function () {
        return fn(a).updateCurrentUser(f.user);
      }).then(function () {
        f.user = fn(a).currentUser;
        return f;
      });
      V(a, b);
      return b;
    }

    function Nn() {
      var a = uf();

      if (Jn(a)) {
        a = new Qb(a);

        for (var b in x) x.hasOwnProperty(b) && Ob(a.a.a, x[b]);

        b = {
          state: "signIn",
          mode: "emailLink",
          operation: "clear"
        };
        var c = n.document.title;
        n.history && n.history.replaceState && n.history.replaceState(b, c, a.toString());
      }
    }

    l.bc = function (a, b) {
      Z(this || _global);
      var c = this || _global;
      return U(this || _global).signInWithEmailAndPassword(a, b).then(function (d) {
        return Gn(c, function (e) {
          return e ? mm(c).then(function () {
            return Hn(c, {
              code: "auth/email-already-in-use"
            }, firebase.auth.EmailAuthProvider.credential(a, b));
          }) : d;
        });
      });
    };

    l.Yb = function (a, b) {
      Z(this || _global);
      var c = this || _global;
      return Gn(this || _global, function (d) {
        if (d) {
          var e = firebase.auth.EmailAuthProvider.credential(a, b);
          return d.linkWithCredential(e);
        }

        return U(c).createUserWithEmailAndPassword(a, b);
      });
    };

    l.ac = function (a) {
      Z(this || _global);
      var b = this || _global;
      return Gn(this || _global, function (c) {
        return c ? c.linkWithCredential(a).then(function (d) {
          return d;
        }, function (d) {
          if (d && "auth/email-already-in-use" == d.code && d.email && d.credential) throw d;
          return Hn(b, d, a);
        }) : U(b).signInWithCredential(a);
      });
    };

    function Tl(a, b) {
      Z(a);
      return Gn(a, function (c) {
        return c && !Ah(X(a)) ? c.linkWithPopup(b).then(function (d) {
          return d;
        }, function (d) {
          if (d && "auth/email-already-in-use" == d.code && d.email && d.credential) throw d;
          return Hn(a, d);
        }) : U(a).signInWithPopup(b);
      });
    }

    l.dc = function (a) {
      Z(this || _global);
      var b = this || _global,
          c = (this || _global).i;
      (this || _global).i = null;
      return Gn(this || _global, function (d) {
        return d && !Ah(X(b)) ? d.linkWithRedirect(a) : U(b).signInWithRedirect(a);
      }).then(function () {}, function (d) {
        b.i = c;
        throw d;
      });
    };

    l.cc = function (a, b) {
      Z(this || _global);
      var c = this || _global;
      return Gn(this || _global, function (d) {
        return d ? d.linkWithPhoneNumber(a, b).then(function (e) {
          return new Tf(e, function (f) {
            if ("auth/credential-already-in-use" == f.code) return Hn(c, f);
            throw f;
          });
        }) : fn(c).signInWithPhoneNumber(a, b).then(function (e) {
          return new Tf(e);
        });
      });
    };

    l.$b = function () {
      Z(this || _global);
      return fn(this || _global).signInAnonymously();
    };

    function Ol(a, b) {
      Z(a);
      return Gn(a, function (c) {
        if (a.j && !a.j.isAnonymous && ei(W(a)) && !U(a).currentUser) return mm(a).then(function () {
          "password" == b.credential.providerId && (b.credential = null);
          return b;
        });
        if (c) return mm(a).then(function () {
          return c.linkWithCredential(b.credential);
        }).then(function (d) {
          b.user = d.user;
          b.credential = d.credential;
          b.operationType = d.operationType;
          b.additionalUserInfo = d.additionalUserInfo;
          return b;
        }, function (d) {
          if (d && "auth/email-already-in-use" == d.code && d.email && d.credential) throw d;
          return Hn(a, d, b.credential);
        });
        if (!b.user) throw Error("Internal error: An incompatible or outdated version of \"firebase.js\" may be used.");
        return mm(a).then(function () {
          return fn(a).updateCurrentUser(b.user);
        }).then(function () {
          b.user = fn(a).currentUser;
          b.operationType = "signIn";
          b.credential && b.credential.providerId && "password" == b.credential.providerId && (b.credential = null);
          return b;
        });
      });
    }

    l.Xb = function (a, b) {
      Z(this || _global);
      return U(this || _global).signInWithEmailAndPassword(a, b);
    };

    function mm(a) {
      Z(a);
      return U(a).signOut();
    }

    function Hn(a, b, c) {
      Z(a);

      if (b && b.code && ("auth/email-already-in-use" == b.code || "auth/credential-already-in-use" == b.code)) {
        var d = fi(W(a));
        return F().then(function () {
          return d(new Od("anonymous-upgrade-merge-conflict", null, c || b.credential));
        }).then(function () {
          a.g && (a.g.o(), a.g = null);
          throw b;
        });
      }

      return ef(b);
    }

    function Qn(a, b, c, d) {
      P.call(this || _global, il, void 0, d, "providerMatchByEmail", {
        F: b,
        D: c
      });
      (this || _global).i = a;
    }

    m(Qn, P);

    Qn.prototype.v = function () {
      this.u((this || _global).i);
      this.w((this || _global).i);
      this.l().focus();
      Bm(this.l(), (this.l().value || "").length);
      P.prototype.v.call(this || _global);
    };

    Qn.prototype.m = function () {
      (this || _global).i = null;
      P.prototype.m.call(this || _global);
    };

    u(Qn.prototype, {
      l: sl,
      H: tl,
      u: ul,
      B: vl,
      j: wl,
      M: jl,
      w: ll
    });

    function Rn(a, b, c, d, e) {
      P.call(this || _global, hl, {
        ec: b
      }, e, "selectTenant", {
        F: c,
        D: d
      });
      (this || _global).i = a;
    }

    m(Rn, P);

    Rn.prototype.v = function () {
      Sn(this || _global, (this || _global).i);
      P.prototype.v.call(this || _global);
    };

    Rn.prototype.m = function () {
      (this || _global).i = null;
      P.prototype.m.call(this || _global);
    };

    function Sn(a, b) {
      function c(h) {
        b(h);
      }

      for (var d = a.g ? Uc("firebaseui-id-tenant-selection-button", a.g || a.s.a) : [], e = 0; e < d.length; e++) {
        var f = d[e],
            g = hn(f, "tenantId");
        O(a, f, za(c, g));
      }
    }

    function Tn(a) {
      P.call(this || _global, Ck, void 0, a, "spinner");
    }

    m(Tn, P);

    function Un(a) {
      (this || _global).a = new Ih();
      G((this || _global).a, "authDomain");
      G((this || _global).a, "displayMode", Vn);
      G((this || _global).a, "tenants");
      G((this || _global).a, "callbacks");
      G((this || _global).a, "tosUrl");
      G((this || _global).a, "privacyPolicyUrl");

      for (var b in a) if (a.hasOwnProperty(b)) try {
        Jh((this || _global).a, b, a[b]);
      } catch (c) {
        pg("Invalid config: \"" + b + "\"", void 0);
      }
    }

    function Wn(a) {
      a = a.a.get("displayMode");

      for (var b in Xn) if (Xn[b] === a) return Xn[b];

      return Vn;
    }

    function Yn(a) {
      return a.a.get("callbacks") || {};
    }

    function Zn(a) {
      var b = a.a.get("tosUrl") || null;
      a = a.a.get("privacyPolicyUrl") || null;
      b && !a && ug("Privacy Policy URL is missing, the link will not be displayed.");

      if (b && a) {
        if ("function" === typeof b) return b;
        if ("string" === typeof b) return function () {
          sf(b);
        };
      }

      return null;
    }

    function $n(a) {
      var b = a.a.get("tosUrl") || null,
          c = a.a.get("privacyPolicyUrl") || null;
      c && !b && ug("Terms of Service URL is missing, the link will not be displayed.");

      if (b && c) {
        if ("function" === typeof c) return c;
        if ("string" === typeof c) return function () {
          sf(c);
        };
      }

      return null;
    }

    function ao(a, b) {
      a = a.a.get("tenants");
      if (!a || !a.hasOwnProperty(b) && !a.hasOwnProperty(bo)) throw Error("Invalid tenant configuration!");
    }

    function co(a, b, c) {
      a = a.a.get("tenants");
      if (!a) throw Error("Invalid tenant configuration!");
      var d = [];
      a = a[b] || a[bo];
      if (!a) return pg("Invalid tenant configuration: " + (b + " is not configured!"), void 0), d;
      b = a.signInOptions;
      if (!b) throw Error("Invalid tenant configuration: signInOptions are invalid!");
      b.forEach(function (e) {
        if ("string" === typeof e) d.push(e);else if ("string" === typeof e.provider) {
          var f = e.hd;
          f && c ? (f instanceof RegExp ? f : new RegExp("@" + f.replace(".", "\\.") + "$")).test(c) && d.push(e.provider) : d.push(e.provider);
        } else e = "Invalid tenant configuration: signInOption " + (JSON.stringify(e) + " is invalid!"), pg(e, void 0);
      });
      return d;
    }

    function eo(a, b, c) {
      a = fo(a, b);
      (b = a.signInOptions) && c && (b = b.filter(function (d) {
        return "string" === typeof d ? c.includes(d) : c.includes(d.provider);
      }), a.signInOptions = b);
      return a;
    }

    function fo(a, b) {
      var c = go;
      var d = void 0 === d ? {} : d;
      ao(a, b);
      a = a.a.get("tenants");
      return xf(a[b] || a[bo], c, d);
    }

    var go = ["immediateFederatedRedirect", "privacyPolicyUrl", "signInFlow", "signInOptions", "tosUrl"],
        Vn = "optionFirst",
        Xn = {
      pc: Vn,
      oc: "identifierFirst"
    },
        bo = "*";

    function ho(a, b) {
      var c = this || _global;
      (this || _global).s = tf(a);
      (this || _global).a = {};
      Object.keys(b).forEach(function (d) {
        c.a[d] = new Un(b[d]);
      });
      (this || _global).ob = (this || _global).g = (this || _global).A = (this || _global).h = (this || _global).i = (this || _global).j = null;
      Object.defineProperty(this || _global, "languageCode", {
        get: function () {
          return (this || _global).ob;
        },
        set: function (d) {
          (this || _global).ob = d || null;
        },
        enumerable: !1
      });
    }

    l = ho.prototype;

    l.Ub = function (a, b) {
      var c = this || _global;
      io(this || _global);
      var d = a.apiKey;
      return new We(function (e, f) {
        if (c.a.hasOwnProperty(d)) {
          var g = Yn(c.a[d]).selectTenantUiHidden || null;

          if (Wn(c.a[d]) === Vn) {
            var h = [];
            b.forEach(function (r) {
              r = r || "_";
              var I = c.a[d].a.get("tenants");
              if (!I) throw Error("Invalid tenant configuration!");
              (I = I[r] || I[bo]) ? r = {
                tenantId: "_" !== r ? r : null,
                V: I.fullLabel || null,
                displayName: I.displayName,
                za: I.iconUrl,
                ta: I.buttonColor
              } : (pg("Invalid tenant configuration: " + (r + " is not configured!"), void 0), r = null);
              r && h.push(r);
            });

            var k = function (r) {
              r = {
                tenantId: r,
                providerIds: co(c.a[d], r || "_")
              };
              e(r);
            };

            if (1 === h.length) {
              k(h[0].tenantId);
              return;
            }

            c.g = new Rn(function (r) {
              io(c);
              g && g();
              k(r);
            }, h, Zn(c.a[d]), $n(c.a[d]));
          } else c.g = new Qn(function () {
            var r = c.g.j();

            if (r) {
              for (var I = 0; I < b.length; I++) {
                var Ca = co(c.a[d], b[I] || "_", r);

                if (0 !== Ca.length) {
                  r = {
                    tenantId: b[I],
                    providerIds: Ca,
                    email: r
                  };
                  io(c);
                  g && g();
                  e(r);
                  return;
                }
              }

              c.g.a(Md({
                code: "no-matching-tenant-for-email"
              }).toString());
            }
          }, Zn(c.a[d]), $n(c.a[d]));

          c.g.render(c.s);
          (f = Yn(c.a[d]).selectTenantUiShown || null) && f();
        } else {
          var p = Error("Invalid project configuration: API key is invalid!");
          p.code = "invalid-configuration";
          c.pb(p);
          f(p);
        }
      });
    };

    l.Pb = function (a, b) {
      if (!(this || _global).a.hasOwnProperty(a)) throw Error("Invalid project configuration: API key is invalid!");
      var c = b || void 0;
      ao((this || _global).a[a], b || "_");

      try {
        (this || _global).i = firebase.app(c).auth();
      } catch (e) {
        var d = (this || _global).a[a].a.get("authDomain");

        if (!d) throw Error("Invalid project configuration: authDomain is required!");
        a = firebase.initializeApp({
          apiKey: a,
          authDomain: d
        }, c);
        a.auth().tenantId = b;
        (this || _global).i = a.auth();
      }

      return (this || _global).i;
    };

    l.Zb = function (a, b) {
      var c = this || _global;
      return new We(function (d, e) {
        function f(I, Ca) {
          c.j = new Cn(a);
          Kn(c.j, c.s, I, Ca);
        }

        var g = a.app.options.apiKey;
        c.a.hasOwnProperty(g) || e(Error("Invalid project configuration: API key is invalid!"));
        var h = eo(c.a[g], a.tenantId || "_", b && b.providerIds);
        io(c);
        e = {
          signInSuccessWithAuthResult: function (I) {
            d(I);
            return !1;
          }
        };
        var k = Yn(c.a[g]).signInUiShown || null,
            p = !1;

        e.uiChanged = function (I, Ca) {
          null === I && "callback" === Ca ? ((I = Wc("firebaseui-id-page-callback", c.s)) && Nj(I), c.h = new Tn(), c.h.render(c.s)) : p || null === I && "spinner" === Ca || "blank" === Ca || (c.h && (c.h.o(), c.h = null), p = !0, k && k(a.tenantId));
        };

        h.callbacks = e;
        h.credentialHelper = "none";
        var r;
        b && b.email && (r = {
          emailHint: b.email
        });
        c.j ? c.j.Wa().then(function () {
          f(h, r);
        }) : f(h, r);
      });
    };

    l.reset = function () {
      var a = this || _global;
      return F().then(function () {
        a.j && a.j.Wa();
      }).then(function () {
        a.j = null;
        io(a);
      });
    };

    l.Vb = function () {
      var a = this || _global;
      (this || _global).h || (this || _global).A || ((this || _global).A = window.setTimeout(function () {
        io(a);
        a.h = new Tn();
        a.g = a.h;
        a.h.render(a.s);
        a.A = null;
      }, 500));
    };

    l.mb = function () {
      window.clearTimeout((this || _global).A);
      (this || _global).A = null;
      (this || _global).h && ((this || _global).h.o(), (this || _global).h = null);
    };

    l.Bb = function () {
      io(this || _global);
      (this || _global).g = new El();

      (this || _global).g.render((this || _global).s);

      return F();
    };

    function io(a) {
      a.j && a.j.reset();
      a.mb();
      a.g && a.g.o();
    }

    l.pb = function (a) {
      var b = this || _global,
          c = Md({
        code: a.code
      }).toString() || a.message;
      io(this || _global);
      var d;
      a.retry && "function" === typeof a.retry && (d = function () {
        b.reset();
        a.retry();
      });
      (this || _global).g = new Il(c, d);

      (this || _global).g.render((this || _global).s);
    };

    l.Rb = function (a) {
      var b = this || _global;
      return F().then(function () {
        var c = b.i && b.i.app.options.apiKey;
        if (!b.a.hasOwnProperty(c)) throw Error("Invalid project configuration: API key is invalid!");
        ao(b.a[c], a.tenantId || "_");
        if (!b.i.currentUser || b.i.currentUser.uid !== a.uid) throw Error("The user being processed does not match the signed in user!");
        return (c = Yn(b.a[c]).beforeSignInSuccess || null) ? c(a) : a;
      }).then(function (c) {
        if (c.uid !== a.uid) throw Error("User with mismatching UID returned.");
        return c;
      });
    };

    v("firebaseui.auth.FirebaseUiHandler", ho);
    v("firebaseui.auth.FirebaseUiHandler.prototype.selectTenant", ho.prototype.Ub);
    v("firebaseui.auth.FirebaseUiHandler.prototype.getAuth", ho.prototype.Pb);
    v("firebaseui.auth.FirebaseUiHandler.prototype.startSignIn", ho.prototype.Zb);
    v("firebaseui.auth.FirebaseUiHandler.prototype.reset", ho.prototype.reset);
    v("firebaseui.auth.FirebaseUiHandler.prototype.showProgressBar", ho.prototype.Vb);
    v("firebaseui.auth.FirebaseUiHandler.prototype.hideProgressBar", ho.prototype.mb);
    v("firebaseui.auth.FirebaseUiHandler.prototype.completeSignOut", ho.prototype.Bb);
    v("firebaseui.auth.FirebaseUiHandler.prototype.handleError", ho.prototype.pb);
    v("firebaseui.auth.FirebaseUiHandler.prototype.processUser", ho.prototype.Rb);
    v("firebaseui.auth.AuthUI", Cn);
    v("firebaseui.auth.AuthUI.getInstance", function (a) {
      a = Dn(a);
      return En[a] ? En[a] : null;
    });
    v("firebaseui.auth.AuthUI.prototype.disableAutoSignIn", Cn.prototype.Db);
    v("firebaseui.auth.AuthUI.prototype.start", Cn.prototype.start);
    v("firebaseui.auth.AuthUI.prototype.setConfig", Cn.prototype.ib);
    v("firebaseui.auth.AuthUI.prototype.signIn", Cn.prototype.Wb);
    v("firebaseui.auth.AuthUI.prototype.reset", Cn.prototype.reset);
    v("firebaseui.auth.AuthUI.prototype.delete", Cn.prototype.Wa);
    v("firebaseui.auth.AuthUI.prototype.isPendingRedirect", Cn.prototype.nb);
    v("firebaseui.auth.AuthUIError", Od);
    v("firebaseui.auth.AuthUIError.prototype.toJSON", Od.prototype.toJSON);
    v("firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM", Li);
    v("firebaseui.auth.CredentialHelper.GOOGLE_YOLO", oi);
    v("firebaseui.auth.CredentialHelper.NONE", di);
    v("firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID", "anonymous");
    We.prototype["catch"] = We.prototype.Ca;
    We.prototype["finally"] = We.prototype.fc;
  }).apply(typeof _global !== "undefined" ? _global : typeof self !== "undefined" ? self : window);

  if (typeof window !== "undefined") {
    window.dialogPolyfill = _dialogPolyfill;
  }
})();

exports = firebaseui;
export default exports;